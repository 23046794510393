import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <div>
      404! Page not not found
      <Link to="/"> Go to Home </Link>
    </div>
  );
};
