import React, { useState, useEffect } from "react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { useDispatch, useSelector } from "react-redux";
import { months, years } from "../../Utils/arrayItems";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";

const AdminClientSessions = () => {
  const dispatch = useDispatch();
  const clientSessions = useSelector((state) => state.adminReducer.adminSessionsByClient);
  const clientList = useSelector((state) => state.adminReducer.allClients);
  const [clientOptions, setClientOptions] = useState({ value: "All", label: "All" });
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [applyClicked, setApplyClicked] = useState(false);
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" }
  ]);
  const [clientsSelected, setClientsSelected] = useState([{ value: "All", label: "All" }]);
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Admin Sessions by Client" } })
    );
    dispatch(adminAction.loadAllClients({ filters: { is_active: ["Active", "Inactive"] } }));
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      let sr_list_db = clientList.map((client) => {
        return {
          value: `${client.full_name} (${client.client_id})`,
          label: `${client.full_name} (${client.client_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setClientOptions(sr_list_db);
    }
  }, [clientList]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "Active", label: "Active" }]);
        } else {
          setStatusSelected(e);
        }
        break;
      case "Clients":
        if (e.length == 0) {
          setClientsSelected([{ value: "All", label: "All" }]);
        } else {
          setClientsSelected(newVals);
        }
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const clientTypes = [
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ];
  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let client_arr = clientsSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        clients: client_arr,
        start_date: [startDate],
        end_date: [endDate],
        page_name: "Admin Sessions by Client"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminSessionsByClient({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        } else if (key == "clients") {
          setClientsSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminSessionsByClient({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminSessionsByClient({ filters: filters }));
  }, [applyClicked]);

  useEffect(() => {
    if (clientSessions) {
      let numberOfClients = clientSessions.length;
      let athleteSum = clientSessions.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.client_type === "Athlete");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.total_commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let teamSum = clientSessions.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.client_type === "Team");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.total_commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let corporateSum = clientSessions.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.client_type === "Corporate");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.total_commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      setSummaryData({
        headers: [
          "Number of Clients",
          "Total Commission",
          "Sum of Athlete",
          "Sum of Team",
          "Sum of Corporate"
        ],
        data: [
          numberOfClients,
          athleteSum + teamSum + corporateSum,
          athleteSum,
          teamSum,
          corporateSum
        ]
      });
    }
  }, [clientSessions]);

  return (
    <AccordionContainer
      values={clientSessions}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="SESSIONS BY CLIENT"
      type="SESSIONS BY CLIENT"
      headers={["topic", "worksheet", "session_date", "coach_name"]}
      row_id={"session_id"}
      pathName={"session_detail"}
      admin={true}
      handleClick={handleClick}
      summaryData={summaryData}>
      <ReactSelect
        options={clientOptions}
        value={clientsSelected}
        isMulti={true}
        placeholder="Clients"
        handleChange={handleChange}
        id={1}
      />
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
      <ReactSelect
        options={clientTypes}
        value={clientTypesSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
        id={4}
      />
      <ReactSelect
        options={statuses}
        value={statusSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
        id={5}
      />
    </AccordionContainer>
  );
};

export default AdminClientSessions;
