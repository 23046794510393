import React, { useState, useRef, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from 'react-bootstrap';
import Logo from '../../assets/wm-logo.jpg';
import './ForgotPassword.scss';
import routingConstants from '../../Navigation/constants';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [validation, setValidation] = useState({ username: false, password: false });
  //const userNamePattern = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
  const { pathname } = location;
  const toastId = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(username && password)) {
      setValidation({ username: true, password: true });
      return;
    }
    setFormSubmitted(true);
    oktaAuth
      .signInWithCredentials({ username, password })
      .then((res) => {
        const sessionToken = res.sessionToken;
        setSessionToken(sessionToken);
        oktaAuth.signInWithRedirect({ sessionToken });
      })
      .catch((err) => {
        setFormSubmitted(false);
        setAuthError(true);
      });
  };

  useEffect(() => {
    toastId.current = toast.info('Note: This page is not finished - filling out this form will have no effect on user status', {position: "top-center"})
  })

  return (
    <>
    <ToastContainer
      limit={1}
      theme="dark"
    />
    <div className="login">
      <Container className="login-container">
        <Row>
          <Col className="mb-4 logo">
            <Image src={Logo} className="wm-logo" />
          </Col>
        </Row>
        <Row className="form-container">
          <Col>
            <Form className="mt-4 form" onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col className="mb-1">
                    <p>Enter the email address associated with your account to receive a password reset link:</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2">
                    <Form.Group controlId="password">
                      <FloatingLabel label="Email Address" className='floating-label'>
                      <Form.Control
                        type="password"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4 mb-4 form-button">
                    <Button type="submit">SUBMIT</Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4">
                    <p>
                      Return to <Link to={routingConstants.LOGIN}><span>Login</span></Link>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default ForgotPassword;
