import React, { useState, useRef } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { requestOptions } from "../../Utils";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_CONSTANTS from "../../Redux/constants";

import {
    desiredNumberOfWeeklySessionsOptions,
    faithBasedOptions,
    highestLevelCompetedAtOptions,
    preferredClientTypeOptions,
    salesPersonStatusOptions,
    sportOptions,
    stateList,
    timeofDayOptions,
    trainingLevelList,
    tzList,
    weekdayOptions,
   } from "../../Utils/selectConstants";

const CoachForm = ({
                        firstName,
                        lastName,
                        email,
                        phone,
                        address,
                        city,
                        state,
                        dateOfBirth,
                        sport,
                        timezone,
                        weekdays,
                        timeofday,
                        weeklySessions,
                        preferredClientType,
                        comfortableWithFaith,
                        preferredTrainingLevel,
                        areaOfStudy,
                        fullTimeJob,
                        highestLevelCompetedAt,
                        schoolTeamCompetedFor,
                        handleChange
                  }) => {
  return (
    <>
      <Input
        label="First Name"
        type="text"
        value={firstName}
        onChange={(e) => handleChange("First Name", e)}
      />
      <Input
        label="Last Name"
        type="text"
        value={lastName}
        onChange={(e) => handleChange("Last Name", e)}
      />
      <Input
        label="Email"
        type="text"
        value={email}
        onChange={(e) => handleChange("Email", e)}
      />
      <Input
        label="Phone"
        type="tel"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        value={phone}
        onChange={(e) => handleChange("Phone", e)}
      />
      <Input
        label="Street Address"
        type="text"
        value={address}
        onChange={(e) => handleChange("Street Address", e)}
      />
      <Input
        label="City/Town"
        type="text"
        value={city}
        onChange={(e) => handleChange("City/Town", e)}
      />
      <ReactSelect
        type="text"
        placeholder="State"
        options={stateList}
        value={state}
        handleChange={handleChange}
      />
      <Input
          label="Date of Birth"
          type="date"
          value={dateOfBirth}
          onChange={(e) => handleChange("Date of Birth", e)}
      />
      <Input
          label="Area of Study/Background"
          type="text"
          value={areaOfStudy}
          onChange={(e) => handleChange("Area of Study/Background", e)}
      />
      <Input
          label="Full-time Job"
          type="text"
          value={fullTimeJob}
          onChange={(e) => handleChange("Full-time Job", e)}
      />
      <ReactSelect
        options={sportOptions}
        value={sport}
        isMulti
        placeholder="Sport Specialty"
        handleChange={handleChange}
      />
      <ReactSelect
        options={highestLevelCompetedAtOptions}
        value={highestLevelCompetedAt}
        placeholder="Highest level competed at?"
        handleChange={handleChange}
      />
      {(highestLevelCompetedAt.value !== "High School")
        ? <Input
          label="What school/team did you compete for?"
          type="text"
          value={schoolTeamCompetedFor}
          onChange={(e) => handleChange("What school/team did you compete for?", e)}
      /> : <div></div>
      }
      <ReactSelect
        options={tzList}
        value={timezone}
        placeholder="Primary Time Zone"
        handleChange={handleChange}
      />
      <ReactSelect
        options={weekdayOptions}
        value={weekdays}
        isMulti
        placeholder="Available Days"
        handleChange={handleChange}
      />
      <ReactSelect
        options={timeofDayOptions}
        value={timeofday}
        isMulti
        placeholder="Available Time of Day"
        handleChange={handleChange}
      />
      <ReactSelect
        options={desiredNumberOfWeeklySessionsOptions}
        value={weeklySessions}
        placeholder="Desired Number of Weekly Sessions"
        handleChange={handleChange}
      />
      <ReactSelect
        options={preferredClientTypeOptions}
        value={preferredClientType}
        isMulti
        placeholder="Preferred Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={faithBasedOptions}
        value={comfortableWithFaith}
        placeholder="Are you equipped to incorporate the Christian faith into mindset training?"
        handleChange={handleChange}
      />
      <ReactSelect
        options={trainingLevelList}
        value={preferredTrainingLevel}
        isMulti
        placeholder="Preferred Training Level"
        handleChange={handleChange}
      />
    </>
  );
};

export default CoachForm;
