import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";

const Select = ({ id, options, control, label, value, onChange }) => {
  return (
    <>
      <Form.Group className="mt-3">
        <FloatingLabel label={label} className="floating-label">
          <Form.Select id={id} value={value} onChange={onChange}>
            {options.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </Form.Select>
        </FloatingLabel>
      </Form.Group>
    </>
  );
};

export default Select;
