import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import TextArea from "../../Components/Forms/TextArea";
import { Button, Row, Col } from "react-bootstrap";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import ReactSelect from "../../Components/Forms/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './SalesHoursForm.scss';
import { saleAction, adminAction } from "../../Redux/actions";

const CustomFormContainer = ({ children, onClick, title }) => {
    return (
      <div className="main-container mt-5 vh-70">
        <p className="form-title">{title}</p>
        <div className="form-container">
          {children && children.map((child) => {
            return (
              <div key={child && child.props.id} className="item">
                {child}
              </div>
            );
          })}
          <Row>
            <Col>
              <Button id={"reset"} className="add mt-3" onClick={onClick}>
                RESET
              </Button>
            </Col>
            <Col>
              <Button id={"add"} className="add mt-3" onClick={onClick}>
                ADD
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

const SalesHoursForm = (props) => {
  const salesRepList_db = useSelector((state) => state.adminReducer.allSalesReps);
  const [salesRep, setSalesRep] = useState("");
  const [salesRepList, setSalesRepList] = useState([]);
  const userInfo = useSelector((state) => state.authReducer.persona);
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [resetData, setResetData] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(adminAction.loadAllSalesReps({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (salesRepList_db.length > 0) {
      let sr_list_db = salesRepList_db.map((coach) => {
        return {
          value: `${coach.coach_name} (${coach.coach_id})`,
          label: `${coach.coach_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "", label: "" });
      setSalesRepList(sr_list_db);
    }
  }, [salesRepList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Sales Person":
        setSalesRep(e);
        break;
      case "Notes":
        setNotes(e.target.value);
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        totalMinutes: totalMinutes,
        rollup: fullTimeToSubmit,
        coach_id: salesRep !== "" ? salesRep.value : "",
      };
      if (
        totalMinutes > 0
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setNotes("");
    setFullTimeToSubmit([]);
    setTotalHours(0);
    setTotalMinutes(0);
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_SALES_HOURS, data: req_obj }))
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toastId.current = toast.success(
            "You’ve successfully inserted new sales hours",
            { position: "top-center" }
          );
          reset_data();
        } else if (res.data.code === "Duplicate") {
          toastId.current = toast.error(
            "You’ve already inserted a sales hours for that time",
            { position: "top-center" }
          );
        } else {
          toastId.current = toast.error(
            "There was an error inserting sales hours",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting session data", {
          position: "top-center"
        });
      });
  };

  const [dateRangeRow, setDateRangeRow] = useState([new Date(), new Date()]);
  const [fullTimeToSubmit, setFullTimeToSubmit] = useState([]);
  const [currentKey, setCurrentKey] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [minutesPresent, setMinutesPresent] = useState(false);

  const calculateMinutes = (dateRange) => {
    let diff = dateRange[1].getTime() - dateRange[0].getTime();
    let minutes = Math.round(diff / 60000);
    return minutes;
  }

  const handleDateRangeChange = (e) => {
    if (calculateMinutes(e) > 0) {
        setMinutesPresent(true);
    }
    setDateRangeRow(e);
  }

  const handleStagingClick = (e) => {
    setCurrentKey(currentKey + 1);
    let minutes = calculateMinutes(dateRangeRow);
    setTotalMinutes(totalMinutes + minutes);
    if (minutes > 0) {
        setTotalHours(totalHours + (minutes / 60));
    }
    fullTimeToSubmit.push({'key': currentKey, 'daterange': [dateRangeRow[0].toLocaleString(), dateRangeRow[1].toLocaleString()], 'notes': "- " + notes, 'minutes': minutes});
    setDateRangeRow([new Date(), new Date()]);
    setNotes("");
    setMinutesPresent(false);
  }


  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <CustomFormContainer title="LOG SALES HOURS" onClick={handleClick} className="salehourscontainer">
        {(userInfo && userInfo.is_admin === "1") && <ReactSelect
          options={salesRepList}
          value={salesRep}
          label="Sales Person"
          placeholder={"Sales Person"}
          id={"Sales Person"}
          handleChange={handleChange}
        />}
        <div className="header">Date and Time Range of Sales Work:</div>
        <DateTimeRangePicker 
            onChange={handleDateRangeChange}
            value={dateRangeRow}
            disableClock={true}
            className="daterangepicker"
            clearIcon={null}
            calendarClassName="daterangepickercalendar"
            rangeDivider={" to "}
        />
        <TextArea label="Notes" height={'300px'} onChange={(e) => handleChange("Notes", e)} value={notes} />
        <Button 
            className='mt-3 staging-button'
            onClick={handleStagingClick}
            disabled={!minutesPresent}
        >
            ADD TO PENDING TIME
        </Button>
        <div className="header">Pending Time to Submit:</div>
        <div className="previewarea">
            {fullTimeToSubmit.length > 0 ?
                (
                    fullTimeToSubmit.map((item) => {
                        return (
                            <ul key={item.key}>
                                <li>
                                    {item.daterange[0] + " to " + item.daterange[1] + " (" + item.minutes + " minutes)"}
                                </li>
                                <ul>{item.notes}</ul>
                            </ul>
                        )
                    })
                ) :
                (<div>No Data</div>)
            }
        </div>
        <div className="header">Total Time to Submit:</div>
        <div className="previewarea">
            <div>{round(totalHours, 3)} hour(s) or {totalMinutes} minute(s)</div>
        </div>
      </CustomFormContainer>
    </>
  );
};

export default SalesHoursForm;
