import React, {useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";

import {store} from "./Redux/store";
import RouterConfig from './Navigation/RouterConfig';

import './index.scss'

const App = () => {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </Provider>
  )
}

export default App;
