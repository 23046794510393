import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { Button } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction } from "../../Redux/actions";
import { saleAction, adminAction, filterAction } from "../../Redux/actions";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { 
  clientTypeList,
  commissionOptions,
  intitialOrRenewalOptions,
  originsOfSale,
  paymentSourceList,
  sportOptions,
  takeOrPassOptions,
} from "../../Utils/selectConstants"

const SaleDetailForm = ({saleDetail, clicked, setClicked, refresh, handleDetailEditedChange}) => {

  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }
  const [clientName, setClientName] = useState(
    {
      value: saleDetail.client_name + ' (' + saleDetail.client_id + ')',
      label: saleDetail.client_name + ' (' + saleDetail.client_id + ')'
    }
  );
  const [clientId, setClientId] = useState();
  const [paymentDate, setPaymentDate] = useState(saleDetail.payment_date);
  const [activeCommissionStart, setActiveCommissionStart] = useState(saleDetail.active_commission_start);
  const [activeCommissionEnd, setActiveCommissionEnd] = useState(saleDetail.active_commission_end);
  const [clientType, setClientType] = useState({value: saleDetail.client_type, label: saleDetail.client_type});
  const [initial_or_renewal_selected, setInitialOrRenewalSelected] = useState(
    {
      value: saleDetail.initial_sale_or_renewal,
      label: saleDetail.initial_sale_or_renewal
    }
  );
  const [saleOrigin, setSaleOrigin] = useState({value: saleDetail.origin_of_sale, label: saleDetail.origin_of_sale});
  const [eventName, setEventName] = useState(saleDetail.event_name);
  const [programName, setProgramName] = useState(
    {
      value: saleDetail.program_name + ' (' + saleDetail.program_id + ')',
      label: saleDetail.program_name + ' (' + saleDetail.program_id + ')'
    }
  );
  const [salesRep, setSalesRep] = useState(
    {
      value: saleDetail.coach_name + ' (' + saleDetail.coach_id + ')',
      label: saleDetail.coach_name + ' (' + saleDetail.coach_id + ')'
    }
  );
  const [registrationFee, setRegistrationFee] = useState(saleDetail.registration_fee);
  const [commission, setCommission] = useState(0);
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [salesRepList, setSalesRepList] = useState([]);
  const toastId = useRef(null);
  const clientList_db = useSelector((state) => state.adminReducer.allClients);
  const salesRepList_db = useSelector((state) => state.adminReducer.allSalesReps);
  const programList_db = useSelector((state) => state.filterReducer.programList);
  const commission_db = useSelector((state) => state.adminReducer.calculatedCommission);
  const [resetData, setResetData] = useState(false);
  const [programList, setProgramList] = useState([]);
  const [sport, setSport] = useState({value: saleDetail.sport, label: saleDetail.sport});
  const [takeOrPass, setTakeOrPass] = useState({value: saleDetail.take_or_pass, label: saleDetail.take_or_pass});
  const [customCommission, setCustomCommission] = useState(saleDetail.custom_commission);
  const [customPrice, setCustomPrice] = useState(saleDetail.custom_price);
  const [paymentSource, setPaymentSource] = useState({value: saleDetail.payment_source, label: saleDetail.payment_source});
  const [customEndDate, setCustomEndDate] = useState(new Date().toLocaleDateString());
  const [percentage, setPercentage] = useState(
    { 
      value: saleDetail.commission_percentage,
      label: round(saleDetail.commission_percentage * 100, 2) + '%'
    }
  );
  const saleId = useState(saleDetail.sale_id)

  useEffect(() => {
    dispatch(adminAction.loadAllClients({ filters: {"is_active": ["Active"], "clientType": [clientType.value]} }));
    dispatch(adminAction.loadAllSalesReps({ filters: {} }));
    dispatch(adminAction.loadCalculatedCommission(
      { 
        filters: {
          "coach_name": salesRep.value,
          "program_name": programName.value,
          "take_or_pass": takeOrPass.value
        } 
      }
    ));
    dispatch(filterAction.loadProgramList({ filters: {} }));

  }, [dispatch, clientType]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  useEffect(() => {
    if (salesRepList_db.length > 0) {
      let sr_list_db = salesRepList_db.map((coach) => {
        return {
          value: `${coach.coach_name} (${coach.coach_id})`,
          label: `${coach.coach_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "", label: "" });
      setSalesRepList(sr_list_db);
    }
  }, [salesRepList_db]);

  useEffect(() => {
    let p_list_db = programList_db.map((program) => {
      return {
        value: `${program.program_name} (${program.program_id})`,
        label: `${program.program_name} (${program.program_id})`
      }
    });
    p_list_db.push({value: "Payment Owed", label: "Payment Owed"});
    p_list_db.push({value: "", label: ""});
    setProgramList(p_list_db);
  }, [programList_db]);

  useEffect(() => {
    if (programName.value && !programName.value.includes('Custom')) {
      dispatch(adminAction.loadCalculatedCommission(
        { 
          filters: {
            "coach_name": salesRep.value,
            "program_name": programName.value,
            "take_or_pass": takeOrPass.value,
            "clientType": clientType.value
          } 
        }
      ));
    }
  }, [salesRep, programName, takeOrPass])

  useEffect(() => {
    if (commission_db && percentage.value !== 0) {
      setCommission('$' + round(commission_db.program_price * percentage.value, 4));
    }
  }, [commission_db])

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e);
        setClientId(e.value);
        break;
      case "Client Type":
        setClientType(e);
        break;
      case "Payment Date":
        setPaymentDate(e.target.value);
        break;
      case "Initial or Renewal":
        setInitialOrRenewalSelected(e);
        break;
      case "Origin of Sale":
        setSaleOrigin(e);
        break;
      case "Payment Source":
        setPaymentSource(e);
        break;
      case "Event Name":
        setEventName(e.target.value);
        break;
      case "Program Name":
        setProgramName(e);
        break;
      case "Sales Person":
        setSalesRep(e);
        break;
      case "Sport":
        setSport(e);
        break;
      case "Take or Pass":
        setTakeOrPass(e);
        break;
      case "Custom Price":
        setCustomPrice(e.target.value);
        break;
      case "Custom Commission":
        setCustomCommission(e.target.value);
        break;
      case "Commission":
        if (e.target.value[0] !== '$') {
          setCommission('$' + e.target.value);
        }
        else {
          setCommission(e.target.value)
        }
        break;
      case "Commission Percentage":
        setPercentage(e)
        break;
      case "Active Commission Start":
        setActiveCommissionStart(e.target.value);
        break;
      case "Active Commission End":
        setActiveCommissionEnd(e.target.value);
        break;
      case "Registration Fee":
        setRegistrationFee(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
      const req_obj = {
        client_name: clientName.value,
        payment_date: paymentDate,
        commission: commission,
        initial_sale_or_renewal: initial_or_renewal_selected.value,
        origin_of_sale: saleOrigin.value,
        event_name: eventName,
        program_name: programName.value,
        coach_name: salesRep.value,
        client_type: clientType.value,
        custom_price: customPrice,
        custom_commission: customCommission,
        sport: sport.value,
        take_or_pass: takeOrPass.value,
        commission_percentage: percentage.value,
        sale_id: saleId,
        active_commission_start: activeCommissionStart,
        active_commission_end: activeCommissionEnd,
        registration_fee: registrationFee,
        payment_source: paymentSource.value
      };
      if (
        clientType.value !== "" &&
        paymentDate !== "" &&
        clientName.value !== "" &&
        sport.value !== "" &&
        initial_or_renewal_selected.value !== "" &&
        saleOrigin.value !== "" &&
        programName.value !== "" &&
        salesRep.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all required form fields", { position: "top-center" });
      }
  };

  const reset_data = () => {
    setClientName("");
    setPaymentDate("");
    setClientType("");
    setCommission(0);
    setInitialOrRenewalSelected("");
    setSalesRep("");
    setSaleOrigin("");
    setEventName("");
    setProgramName("");
    setCustomPrice(0);
    setCustomCommission(0);
    setTakeOrPass("");
    setSport("");
    setPercentage(0);
    setActiveCommissionStart("");
    setActiveCommissionEnd("");
    setRegistrationFee(0);
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_SALE_DETAIL, data: req_obj }))
      .then((res) => {
        if (res) {
          // console.log("Successfully inserted session data");
          toastId.current = toast.success(
            "You’ve successfully updated the sale for " + req_obj["client_name"],
            { position: "top-center" }
          );
          reset_data();
          handleDetailEditedChange()
        } else {
          toastId.current = toast.error(
            "There was an error updating the sale for " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error updating sale data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
    <ToastContainer limit={1} theme="dark" />
    <div className="mt-2">
      <div className="labels mt-3">Client</div>
      <ReactSelect
        options={clientTypeList}
        value={clientType}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <Input
        type="date"
        label="Payment Date"
        disabled={false}
        value={paymentDate}
        onChange={(e) => handleChange("Payment Date", e)}
      />
      <ReactSelect
        options={clientList}
        value={clientName}
        label="Client Name"
        placeholder={"Client Name"}
        id={"Client Name"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={sportOptions}
        value={sport}
        placeholder={"Sport"}
        id={"Sport"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={intitialOrRenewalOptions}
        value={initial_or_renewal_selected}
        label="Initial or Renewal"
        placeholder={"Initial or Renewal"}
        id={"Initial or Renewal"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={originsOfSale}
        value={saleOrigin}
        label="Origin of Sale"
        placeholder={"Origin of Sale"}
        id={"Origin of Sale"}
        handleChange={handleChange}
      />
      <ReactSelect
          options={paymentSourceList}
          value={paymentSource}
          label="paymentSource"
          placeholder={"Payment Source"}
          id={"Payment Source"}
          handleChange={handleChange}
      />
      <Input
        value={eventName}
        label="Sale Notes"
        id={"Event Name"}
        onChange={(e) => handleChange("Event Name", e)}
      />
      <ReactSelect
        options={programList}
        value={programName}
        label="Program Name"
        placeholder={"Program Name"}
        id={"Program Name"}
        handleChange={handleChange}
      />
      <Input
        type="date"
        label="Active Commission Start"
        disabled={false}
        value={activeCommissionStart}
        onChange={(e) => handleChange("Active Commission Start", e)}
      />
      <Input
        type="date"
        label="Active Commission End"
        disabled={false}
        value={activeCommissionEnd}
        onChange={(e) => handleChange("Active Commission End", e)}
      />
      <ReactSelect
        options={salesRepList}
        value={salesRep}
        label="Sales Person"
        placeholder={"Sales Person"}
        id={"Sales Person"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={takeOrPassOptions}
        value={takeOrPass}
        label="Take or Pass"
        placeholder={"Take or Pass"}
        id={"Take or Pass"}
        handleChange={handleChange}
      />
      <Input
        value={registrationFee}
        label="Registration Fee"
        id={"Registration Fee"}
        onChange={(e) => handleChange("Registration Fee", e)}
      />
      {!(programName.value && programName.value.includes("Custom")) ?
        <Input
          value={commission}
          label="Commission"
          id={"Commission"}
          onChange={(e) => handleChange("Commission", e)}
        /> :
        <Input
          value={customPrice}
          label="Custom Price"
          id={"Custom Price"}
          onChange={(e) => handleChange("Custom Price", e)}
        />
      }
      {!(programName.value && programName.value.includes("Custom")) ?
        <ReactSelect
          options={commissionOptions}
          value={percentage}
          placeholder={"Commission Percentage"}
          label="Commission Percentage"
          id={"Commission Percentage"}
          handleChange={handleChange}
        /> :
        <Input
          value={customCommission}
          label="Custom Commission"
          id={"Custom Commission"}
          onChange={(e) => handleChange("Custom Commission", e)}
        /> 
      }
      <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
    </div>
    </>
  );
};

export default SaleDetailForm;
