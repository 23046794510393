import React, { useState, useRef } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { requestOptions } from "../../Utils";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_CONSTANTS from "../../Redux/constants";

const AdminClientForm = (props) => {
  const toastId = useRef(null);
  const [clientType, setClientType] = useState({value: "", label: ""});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickname] = useState("");
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState({value: "", label: ""});
  const [trainingLevel, setTrainingLevel] = useState({value: "", label: ""});
  const [experience, setExperience] = useState({value: "", label: ""});
  const [personalityType, setPersonalityType] = useState({value: "", label: ""});
  const [date_of_birth, setDateOfBirth] = useState("");
  const [rpName, setRpName] = useState("");
  const [rpPhone, setRpPhone] = useState("");
  const [faithBased, setFaithBased] = useState("");
  const [rpEmail, setRpEmail] = useState("");
  const [rpRelation, setRpRelation] = useState("");
  const [rpInvolvement, setRpInvolvement] = useState({value: "", label: ""});
  const [salesSource, setSalesSource] = useState({value: "", label: ""});
  const [goals, setGoals] = useState("");
  const [aspirations, setAspirations] = useState("");
  const [lessonsImportance, setLessonsImportance] = useState({value: "", label: ""});
  const clientTypeList = [
    { value: "", label: "" },
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ];
  const personalityTypeList  = [ {value: "Introverted", label: "Introverted"},
                                 {value: "Extroverted", label: "Extroverted"} ];
 const trainingLevelList = [ {value: 'Elementary School', label: 'Elementary School'},
                             {value: 'Middle School', label: 'Middle School'},
                             {value: 'High School', label: 'High School'},
                             {value: 'College', label: 'College'},
                             {value: 'Post-College', label: 'Post-College'},
                             {value: 'Semi-Pro', label: 'Semi-Pro'}, 
                             {value: 'Professional', label: 'Professional'}]

 const experienceList = [{value: "Less than a year", label: "Less than a year"},
                         {value: "1-5 year(s)", label: "1-5 year(s)"},
                         {value: "5-10 years(s)", label: "5-10 year(s)"},
                         {value: "10+ years", label: "10+ years"} ]
  const stateList = [{value: "AL",  label: "AL"},
                     {value: "AK",  label: "AK"},
                     {value: "AZ",  label: "AZ"},
                     {value: "AR",  label: "AR"},
                     {value: "CA",  label: "CA"},
                     {value: "CO",  label: "CO"},
                     {value: "CT",  label: "CT"},
                     {value: "DE",  label: "DE"},
                     {value: "FL",  label: "FL"},
                     {value: "GA",  label: "GA"},
                     {value: "HI",  label: "HI"},
                     {value: "ID",  label: "ID"},
                     {value: "IL",  label: "IL"},
                     {value: "IN",  label: "IN"},
                     {value: "IA",  label: "IA"},
                     {value: "KS",  label: "KS"},
                     {value: "KY",  label: "KY"},
                     {value: "LA",  label: "LA"},
                     {value: "ME",  label: "ME"},
                     {value: "MD",  label: "MD"},
                     {value: "MA",  label: "MA"},
                     {value: "MI",  label: "MI"},
                     {value: "MN",  label: "MN"},
                     {value: "MS",  label: "MS"},
                     {value: "MO",  label: "MO"},
                     {value: "MT",  label: "MT"},
                     {value: "NE",  label: "NE"},
                     {value: "NV",  label: "NV"},
                     {value: "NH",  label: "NH"},
                     {value: "NJ",  label: "NJ"},
                     {value: "NM",  label: "NM"},
                     {value: "NY",  label: "NY"},
                     {value: "NC",  label: "NC"},
                     {value: "ND",  label: "ND"},
                     {value: "OH",  label: "OH"},
                     {value: "OK",  label: "OK"},
                     {value: "OR",  label: "OR"},
                     {value: "PA",  label: "PA"},
                     {value: "RI",  label: "RI"},
                     {value: "SC",  label: "SC"},
                     {value: "SD",  label: "SD"},
                     {value: "TN",  label: "TN"},
                     {value: "TX",  label: "TX"},
                     {value: "UT",  label: "UT"},
                     {value: "VT",  label: "VT"},
                     {value: "VA",  label: "VA"},
                     {value: "WA",  label: "WA"},
                     {value: "WV",  label: "WV"},
                     {value: "WI",  label: "WI"},
                     {value: "WY",  label: "WY"}]

  const salesSourceList = [{value: 'Brickwork', label: 'Brickwork'},
                              {value: 'Club Running', label: 'Club Running'},
                              {value: 'Cold Call', label: 'Cold Call'},
                              {value: 'Convention', label: 'Convention'},
                              {value: 'Other', label: 'Other'},
                              {value: 'Referral', label: 'Referral'},
                              {value: 'Renewal', label: 'Renewal'},
                              {value: 'Social Media', label: 'Social Media'},
                              {value: 'Tournament', label: 'Tournament'},
                              {value: 'Mindset Coach', label: 'Mindset Coach'},
                              {value: 'Mindset website', label: 'Mindset website'},
                              {value: 'Wufoo', label: 'Wufoo'}
                              ]

  const faithBasedOptions = [{value: 1, label: 'Yes'},
                             {value: 0, label: 'No'}  ]

  const lessonsImportanceOptions = [{value: 'Very Important', label: 'Very Important'},
                                    {value: 'Somewhat Important', label: 'Somewhat Important'},
                                    {value: 'Not Important', label: 'Not Important'}]

  const rpInvolvementOptions = [{value: "Very Involved", label: "Very Involved"},
                                {value: "Somewhat Involved", label: "Somewhat Involved"},
                                {value: "Not Involved", label: "Not Involved"}]

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        is_active: 1,
        client_type: clientType.value,
        first_name: firstName,
        last_name: lastName,
        team_name: teamName,
        nickname: nickname,
        email: email,
        phone: phone,
        address: address,
        address_state: state.value,
        training_level: trainingLevel.value,
        experience: experience.value,
        personality_type: personalityType.value,
        date_of_birth: date_of_birth,
        responsible_person_name: rpName,
        responsible_person_phone: rpPhone,
        responsible_person_email: rpEmail,
        responsible_person_relationship: rpRelation,
        sales_source: salesSource.value,
        faith_based: faithBased.value,
        goals: goals,
        other_aspirations: aspirations,
        responsible_person_involvement: rpInvolvement.value,
        importance_of_lessons: lessonsImportance.value
      };
      console.log(req_obj)
      if (
        (clientType.value === 'Athlete' && firstName !== "" && lastName !== "")
        ||
        (clientType.value === 'Team' && teamName !== "")
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setClientType({value: "", label: ""});
    setFirstName("");
    setLastName("");
    setTeamName("");
    setNickname("");
    setEmail("");
    setPhone("");
    setAddress("");
    setState({value: "", label: ""});
    setExperience({value: "", label: ""});
    setPersonalityType({value: "", label: ""});
    setFaithBased({value: "", label: ""});
    setGoals("");
    setAspirations("");
    setRpInvolvement({value: "", label: ""});
    setLessonsImportance({value: "", label: ""});
    setDateOfBirth("");
    setRpName("");
    setRpPhone("");
    setRpEmail("");
    setRpRelation("");
    toastId.current = toast.info(
      "The client form has been reset",
      { position: "top-center" }
    );
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_CLIENT, data: req_obj }))
      .then((res) => {
        if (res) {
          // console.log("Successfully inserted session data");
          toastId.current = toast.success(
            "You’ve successfully inserted a new client",
            { position: "top-center" }
          );
          reset_data();
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new client",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting client data", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Client Type":
        setClientType(e)
        break;
      case "First Name":
        setFirstName(e.target.value);
        break;
      case "Last Name":
        setLastName(e.target.value);
        break;
      case "Nickname":
        setNickname(e.target.value);
        break;
      case "Team Name":
        setTeamName(e.target.value);
        break;
      case "Email":
        setEmail(e.target.value);
        break;
      case "Phone":
        setPhone(e.target.value);
        break;
      case "Address":
        setAddress(e.target.value);
        break;
      case "State":
        setState(e);
        break;
      case "Training Level":
        setTrainingLevel(e);
        break;
      case "Experience":
        setExperience(e);
        break;
      case "Faith Based":
        setFaithBased(e);
        break;
      case "Goals":
        setGoals(e.target.value);
        break;
      case "Personality Type":
        setPersonalityType(e);
        break;
      case "Additional Client Information":
        setAspirations(e.target.value);
        break;
      case "Importance of Life Lessons":
        setLessonsImportance(e);
        break;
      case "Date of Birth":
        setDateOfBirth(e.target.value);
        break;
      case "Responsible Person Name":
        setRpName(e.target.value);
        break;
      case "Responsible Person Phone":
        setRpPhone(e.target.value);
        break;
      case "Responsible Person Email":
        setRpEmail(e.target.value);
        break;
      case "Responsible Person Relationship":
        setRpRelation(e.target.value);
        break;
      case "Responsible Person Involvement":
        setRpInvolvement(e);
        break;
      case "Sales Source":
        setSalesSource(e);
        break;
    }
  };

  return (
    <>
    <ToastContainer limit={1} theme="dark" />
    <FormContainer onClick={handleClick} className="active" title="ADD A CLIENT">
      <ReactSelect
        options={clientTypeList}
        value={clientType}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      {clientType.value == "Athlete" ?
      <>
      <Input
        label="First Name"
        type="text"
        value={firstName}
        onChange={(e) => handleChange("First Name", e)}
      />
      <Input
        label="Last Name"
        type="text"
        value={lastName}
        onChange={(e) => handleChange("Last Name", e)}
      />
      <Input
        label="Nickname"
        type="text"
        value={nickname}
        onChange={(e) => handleChange("Nickname", e)}
      />
      </> :
      <Input
        label="Team Name"
        type="text"
        value={teamName}
        onChange={(e) => handleChange("Team Name", e)}
      />
      }
      <Input
        label="Email"
        type="text"
        value={email}
        onChange={(e) => handleChange("Email", e)}
      />
      <Input
        label="Phone"
        type="text"
        value={phone}
        onChange={(e) => handleChange("Phone", e)}
      />
      <Input
        label="Address"
        type="text"
        value={address}
        onChange={(e) => handleChange("Address", e)}
      />
      <ReactSelect
        type="text"
        placeholder="State"
        options={stateList}
        value={state}
        handleChange={handleChange}
      />
      <ReactSelect
        options={trainingLevelList}
        value={trainingLevel}
        placeholder="Training Level"
        handleChange={handleChange}
      />
      <ReactSelect
        options={experienceList}
        value={experience}
        placeholder="Experience"
        handleChange={handleChange}
      />
      <ReactSelect
        options={personalityTypeList}
        value={personalityType}
        placeholder="Personality Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={faithBasedOptions}
        value={faithBased}
        placeholder="Faith Based"
        handleChange={handleChange}
      />
      <Input
        label="Goals"
        type="text"
        value={goals}
        onChange={(e) => handleChange("Goals", e)}
      />
      <Input
        label="Additional Client Information"
        type="text"
        value={aspirations}
        onChange={(e) => handleChange("Additional Client Information", e)}
      />
      <ReactSelect
        options={lessonsImportanceOptions}
        value={lessonsImportance}
        placeholder="Importance of Life Lessons"
        handleChange={handleChange}
      />
      {clientType.value && clientType.value === "Athlete" && <Input
        label="Date of Birth"
        type="date"
        value={date_of_birth}
        onChange={(e) => handleChange("Date of Birth", e)}
      />}
      <Input
        label="Responsible Person Name"
        type="text"
        value={rpName}
        onChange={(e) => handleChange("Responsible Person Name", e)}
      />
      <Input
        label="Responsible Person Phone"
        type="text"
        value={rpPhone}
        onChange={(e) => handleChange("Responsible Person Phone", e)}
      />
      <Input
        label="Responsible Person Email"
        type="text"
        value={rpEmail}
        onChange={(e) => handleChange("Responsible Person Email", e)}
      />
      <Input
        label="Responsible Person Relationship"
        type="text"
        value={rpRelation}
        onChange={(e) => handleChange("Responsible Person Relationship", e)}
      />
      <ReactSelect
        options={rpInvolvementOptions}
        value={rpInvolvement}
        placeholder="Responsible Person Involvement"
        handleChange={handleChange}
      />
      <ReactSelect
        options={salesSourceList}
        value={salesSource}
        placeholder="Sales Source"
        handleChange={handleChange}
      />
    </FormContainer>
    </>
  );
};

export default AdminClientForm;
