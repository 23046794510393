import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import TextArea from "../../Components/Forms/TextArea";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import { 
  lessonsImportanceOptions, 
  clientTypeList, 
  stateList,
  trainingLevelList,
  experienceList,
  personalityTypeList,
  faithBasedOptions,
  sportOptions,
  energyLevelPreferenceOptions,
  desiredCoachExperienceLevelOptions,
  genderOptionsList,
  highestLevelCompetedAtOptions
 } from "../../Utils/selectConstants.js"
import "react-toastify/dist/ReactToastify.css";

const CoachDetailForm = ({ coachDetail, clicked, setClicked, refresh, toastId }) => {
  //const userNamePattern = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
  const [name, setName] = useState(coachDetail.full_name);
  const [email, setEmail] = useState(coachDetail.email);
  const [phone, setPhone] = useState(coachDetail.phone);
  const [isActive, setIsActive] = useState({value: coachDetail.is_active, label: coachDetail.is_active == "1" ? "Yes" : "No"});
  // const [city, setCity] = useState('')
  // const [state, setState] = useState('')
  // const [zipCode, setZipCode] = useState('')
  const [hometown, setHometown] = useState(coachDetail.hometown);
  const [fullTimeJob, setFullTimeJob] = useState(coachDetail.full_time_job);
  const [salesPersonStatus, setSalesPersonStatus] = useState({value: coachDetail.eligible_for_sales, label: coachDetail.eligible_for_sales})
  const [address, setAddress] = useState(coachDetail.address);
  const [addressState, setAddressState] = useState({value: coachDetail.address_state, label: coachDetail.address_state});
  const [sport_specialty, setSportSpecialty] = useState([{value: coachDetail.sport_specialty, label: coachDetail.sport_specialty}]);
  const [area_of_study, setAreaOfStudy] = useState(coachDetail.area_of_study);
  const [timeZone, setTimeZone] = useState({value: coachDetail.primary_time_zone, label: coachDetail.primary_time_zone});
  const [schoolTeamCompetedFor, setSchoolTeamCompetedFor] = useState(coachDetail.school_competed_for);
  const [dayAvailability, setDayAvailability] = useState([{value: coachDetail.dow_availability, label: coachDetail.dow_availability}]);
  const [timeAvailability, setTimeAvailability] = useState([{value: coachDetail.tod_availability, label: coachDetail.tod_availability}]);
  const [preferredSessionCount, setPreferredSessionCount] = useState(coachDetail.desired_weekly_sessions);
  const [preferredClientType, setPreferredClientType] = useState([{value: coachDetail.athlete_team_preference, label: coachDetail.athlete_team_preference}]);
  const [comfortableWithFaith, setComfortableWithFaith] = useState({value: coachDetail.is_religious, label: coachDetail.is_religious == "1" ? "Yes" : "No"});
  const [preferredTrainingLevel, setPreferredTrainingLevel] = useState([{value: coachDetail.client_preference_rank, label: coachDetail.client_preference_rank}]);
  const [highestLevelCompetedAt, setHighestLevelCompetedAt] = useState({value: coachDetail.highest_level_of_competition, label: coachDetail.highest_level_of_competition})
  const [date_of_birth, setDateOfBirth] = useState(coachDetail.date_of_birth);
  const comfortableWithFaithOptions = [{value: 1, label: "Yes"},
                                       {value: 0, label: "No"}  ]

  useEffect(() => {
    let sport_list_db = coachDetail.sport_specialty.map((sport) => {
      return {
        value: `${sport}`,
        label: `${sport}`
      };
    });
    setSportSpecialty(sport_list_db);

    let client_type_preference_list_db = coachDetail.athlete_team_preference.map((preference) => {
      return {
        value: `${preference}`,
        label: `${preference}`
      };
    });
    setPreferredClientType(client_type_preference_list_db);

    let preferred_training_level_db = coachDetail.client_preference_rank.map((training_level) => {
      return {
        value: `${training_level}`,
        label: `${training_level}`
      };
    });
    setPreferredTrainingLevel(preferred_training_level_db);

    let dow_availability_db = coachDetail.dow_availability.map((dow) => {
      return {
        value: `${dow}`,
        label: `${dow}`
      };
    });
    setDayAvailability(dow_availability_db);

    let tod_availability_db = coachDetail.tod_availability.map((tod) => {
      return {
        value: `${tod}`,
        label: `${tod}`
      };
    });
    setTimeAvailability(tod_availability_db);
  },[])

  const trainingLevelOptions = [{value: "Youth", label: "Youth"},
                                {value: "High School", label: "High School"},
                                {value: "College", label: "College"},
                                {value: "Senior", label: "Senior"}
                               ]

  const preferredClientTypeOptions = [{value: "Athletes", label: "Athletes"},
                                     {value: "Teams", label: "Teams"},
                                     {value: "Both", label: "Both"}]

  const tzList = [{value: "EST", label: "EST"},
                   {value: "CST", label: "CST"},
                   {value: "MST", label: "MST"},
                   {value: "PST", label: "PST"}]

  const weeklySessionOptions = [{value: "1", label: "1"},
                               {value: "2", label: "2"},
                               {value: "3", label: "3"},
                               {value: "4", label: "4"},
                               {value: "5", label: "5"},
                               {value: "More than 5", label: "More than 5"}]

  const timeofDayOptions = [{value: "Morning", label: "Morning"},
                           {value: "Afternoon", label: "Afternoon"},
                           {value: "Evening", label: "Evening"}]

  const weekdayOptions = [{value: "Monday", label: "Monday"},
                         {value: "Tuesday", label: "Tuesday"},
                         {value: "Wednesday", label: "Wednesday"},
                         {value: "Thursday", label: "Thursday"},
                         {value: "Friday", label: "Friday"},
                         {value: "Saturday", label: "Saturday"},
                         {value: "Sunday", label: "Sunday"}]

  const salesPersonStatusOptions = [{value: 1, label: "Yes"},
                                  {value: 0, label: "No"}]

  const isActiveOptions = [{value: 1, label: "Yes"}, {value: 0, label: "No"}]


  const onClick = (e) => {
    const req_obj = {
      name: name,
      coach_id: coachDetail.coach_id,
      email: email,
      phone: phone,
      address: address,
      address_state: addressState.value,
      date_of_birth: date_of_birth,
      full_time_job: fullTimeJob,
      hometown: hometown,
      highest_level_of_competition: highestLevelCompetedAt.value,
      school_competed_for: schoolTeamCompetedFor,
      primary_time_zone: timeZone.value,
      area_of_study: area_of_study,
      athlete_team_preference: preferredClientType.map((data) => data.value),
      is_religious: comfortableWithFaith.value,
      sport_specialty: sport_specialty.map((data) => data.value),
      dow_availability: dayAvailability.map((data) => data.value),
      tod_availability: timeAvailability.map((data) => data.value),
      is_active: isActive.value,
      client_preference_rank: preferredTrainingLevel.map((data) => data.value),
      desired_weekly_sessions: preferredSessionCount,
      eligible_for_sales: salesPersonStatus.value
    };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_COACH_DETAIL, data: req_obj })
    ).then((res) => {
      if (res) {
        toastId.current = toast.success(
          "You’ve successfully updated your profile information",
          { position: "top-center" }
        );
        setClicked(!clicked);
        refresh();
      } else {
        toastId.current = toast.error(
          "There was an error updating your profile information",
          { position: "top-center" }
        );
      }
    });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Active/Inactive":
        setIsActive(e);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "hometown":
        setHometown(e.target.value);
        break;
      case "state":
        setAddressState(e);
        break;
      case "DOB":
        setDateOfBirth(e.target.value);
        break;
      case "Full-time Job":
        setFullTimeJob(e.target.value);
        break;
      case "Area of Study":
        setAreaOfStudy(e.target.value);
        break;
      case "Highest level competed at?":
        setHighestLevelCompetedAt(e);
        break;
      case "What school/team did you compete for?":
        setSchoolTeamCompetedFor(e.target.value);
        break;
      case "Sport Specialty":
        setSportSpecialty(e);
        break;
      case "Primary Time Zone":
        setTimeZone(e);
        break;
      case "Day of Week Availability":
        setDayAvailability(e);
        break;
      case "Time of Day Availability":
        setTimeAvailability(e);
        break;
      case "Desired Number of Weekly Sessions":
        setPreferredSessionCount(e.target.value);
        break;
      case "Preferred Client Type":
        setPreferredClientType(e);
        break;
      case "Comfortable Incorporating Faith?":
        setComfortableWithFaith(e);
        break;
      case "Preferred Training Level":
        setPreferredTrainingLevel(e);
        break;
      case "Eligible for Sales":
        setSalesPersonStatus(e);
        break;
      default:
        break;
    }
  };

  return (
    <div className="mt-2">
      <div className="labels mt-3">Active</div>
      <ReactSelect
        placeholder="Active/Inactive"
        handleChange={handleChange}
        value={isActive}
        options={isActiveOptions}
      />
      <div className="labels mt-3">Coach Since</div>
      <Input
        type="text"
        placeholder={coachDetail.registration_timestamp}
        disabled={true}
        defaultValue={coachDetail.registration_timestamp}
      />
      <div className="labels mt-3">Name</div>
      <Input
        type="text"
        id={"name"}
        onChange={(e) => handleChange("name", e)}
        value={name}
      />
      <div className="labels mt-3">Email</div>
      <Input
        type="text"
        placeholder={coachDetail.email}
        id={"email"}
        disabled={true}
        onChange={(e) => handleChange("email", e)}
        defaultValue={coachDetail.email}
      />
      <div className="labels mt-3">Phone</div>
      <Input
        type="text"
        placeholder={coachDetail.phone}
        id={"phone"}
        onChange={(e) => handleChange("phone", e)}
        value={phone}
      />
      <div className="labels mt-3">Street Address</div>
      <Input
        type="text"
        placeholder={coachDetail.address}
        id={"address"}
        onChange={(e) => handleChange("address", e)}
        value={address}
      />
      <div className="labels mt-3">City/Town</div>
      <Input
        type="text"
        placeholder={coachDetail.hometown}
        id={"hometown"}
        onChange={(e) => handleChange("hometown", e)}
        value={hometown}
      />
      <div className="labels mt-3">State</div>
      <ReactSelect
        options={stateList}
        value={addressState}
        placeholder="state"
        handleChange={handleChange}
      />
      <div className="labels mt-3">Date of Birth</div>
      <Input
        type="text"
        disabled
        placeholder={coachDetail.date_of_birth}
        id={"date_of_birth"}
        onChange={(e) => handleChange("date_of_birth", e)}
        defaultValue={coachDetail.date_of_birth}
      />
      <div className="labels mt-3">Area of Study/Background</div>
      <Input
        type="text"
        placeholder={coachDetail.area_of_study}
        id={"Area of Study"}
        onChange={(e) => handleChange("Area of Study", e)}
        defaultValue={coachDetail.area_of_study}
      />
      <div className="labels mt-3">Full-time Job</div>
      <Input
        type="text"
        placeholder={coachDetail.full_time_job}
        id={"full_time_job"}
        onChange={(e) => handleChange("Full-time Job", e)}
        defaultValue={coachDetail.full_time_job}
      />
      <div className="labels mt-3">Sport Specialty</div>
      <ReactSelect
        options={sportOptions}
        value={sport_specialty}
        isMulti={true}
        placeholder="Sport Specialty"
        handleChange={handleChange}
      />
      <div className="labels mt-3">Highest Level Competed At?</div>
      <ReactSelect
        options={highestLevelCompetedAtOptions}
        value={highestLevelCompetedAt}
        placeholder="Highest level competed at?"
        handleChange={handleChange}
      />
      {(highestLevelCompetedAt.value !== "High School")
        ? <><div className="labels mt-3">What school/team did you compete for?</div> <Input
          label="What school/team did you compete for?"
          type="text"
          value={schoolTeamCompetedFor}
          onChange={(e) => handleChange("What school/team did you compete for?", e)}
      /></> : <div></div>
      }
      <div className="labels mt-3">Time Zone</div>
      <ReactSelect
        options={tzList}
        value={timeZone}
        placeholder="Primary Time Zone"
        handleChange={handleChange}
      />
      <div className="labels mt-3">Day of Week Availability</div>
      <ReactSelect
        options={weekdayOptions}
        placeholder={"Day of Week Availability"}
        handleChange={handleChange}
        isMulti={true}
        value={dayAvailability}
      />
      <div className="labels mt-3">Time of Day Availability</div>
      <ReactSelect
        options={timeofDayOptions}
        placeholder={"Time of Day Availability"}
        isMulti={true}
        handleChange={handleChange}
        value={timeAvailability}
      />
      <div className="labels mt-3">Preferred Number of Weekly Sessions</div>
      <Input
        type="text"
        placeholder={preferredSessionCount}
        id={"Desired Number of Weekly Sessions"}
        onChange={(e) => handleChange("Desired Number of Weekly Sessions", e)}
      />
      <div className="labels mt-3">Client Type Preference</div>
      <ReactSelect
        options={preferredClientTypeOptions}
        value={preferredClientType}
        handleChange={handleChange}
        isMulti={true}
        placeholder="Preferred Client Type"
      />
      <div className="labels mt-3">Are you equipped to incorporate Christian faith into mindset training?</div>
      <ReactSelect
        options={comfortableWithFaithOptions}
        value={comfortableWithFaith}
        placeholder="Comfortable Incorporating Faith?"
        handleChange={handleChange}
      />
      <div className="labels mt-3">Preferred Training Level</div>
      <ReactSelect
        options={trainingLevelOptions}
        value={preferredTrainingLevel}
        isMulti={true}
        placeholder="Preferred Training Level"
        handleChange={handleChange}
      />
      <div className="labels mt-3">Eligible for Sales</div>
      <ReactSelect
        options={salesPersonStatusOptions}
        value={salesPersonStatus}
        placeholder="Eligible for Sales"
        handleChange={handleChange}
      />
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
    </div>
  );
};

export default CoachDetailForm;
