export const total_sum_data = (data) => {
  const result = data.reduce((acc, curr) => {
    const objIndex = acc.findIndex((obj) => obj.sales_rep === curr.sales_rep);
    if (objIndex === -1) {
      acc.push({
        sales_rep: curr.sales_rep,
        revenue: curr.revenue,
        ind_commission: curr.ind_commission,
        team_commission: curr.team_commission,
        profit_before_sessions: curr.profit_before_sessions
      });
    } else {
      acc[objIndex].revenue += curr.revenue;
      acc[objIndex].ind_commission += curr.ind_commission;
      acc[objIndex].team_commission += curr.team_commission;
      acc[objIndex].profit_before_sessions += curr.profit_before_sessions;
    }
    return acc;
  }, []);
  return result;
};
