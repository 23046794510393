const initialState = {
  clientList: [],
  clientDetail: [],
  assignmentDetail: [],
  registrationKey: ''
};

export const clientReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_CLIENT_LIST':
      return {
        ...state,
        clientList: payload
      }
    case 'LOAD_CLIENT_DETAIL':
      return {
        ...state,
        clientDetail: payload
      }
    case 'LOAD_ASSIGNMENT_DETAIL':
      return {
        ...state,
        assignmentDetail: payload
      }
    case 'LOAD_REGISTRATION_KEY':
      return {
        ...state,
        registrationKey: payload
      }
    default:
      return state
  }
};
