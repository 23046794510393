import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Button } from "react-bootstrap";
import "./Payment.scss";
import { coachDashboardAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import Filters from "../../Components/Filters";
import { useMediaQuery } from "beautiful-react-hooks";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { years, months } from "../../Utils/arrayItems";
import ReactSelect from "../../Components/Forms/ReactSelect";

const Payment = ({ state }) => {
  const dispatch = useDispatch();
  const monthlyTotals = useSelector((state) => state.coachDashboardReducer.monthlyTotals);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [applyClicked, setApplyClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);
  const match = useMediaQuery("(min-width: 992px");

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "My Payments" } }));
  }, []);

  useEffect(() => {
    let payments = [
      {
        type: "Video Sessions",
        amount: monthlyTotals.team_video_sessions
      },
      {
        type: "Athlete Sessions",
        amount: monthlyTotals.athlete_sessions
      },
      {
        type: "Blogs",
        amount: monthlyTotals.blogs
      },
      {
        type: "Sales",
        amount: monthlyTotals.sales
      },
      {
        type: "Team In Person Sessions",
        amount: monthlyTotals.team_in_person_sessions
      },
      {
        type: "Corporate Sessions",
        amount: monthlyTotals.corporate_sessions
      },
      {
        type: "Events",
        amount: monthlyTotals.club_runs
      },
      {
        type: "Payment Adjustments",
        amount: monthlyTotals.payment_adjustments
      },
      {
        type: "Sales Hours",
        amount: monthlyTotals.sales_hours
      },
      {
        type: "Salary",
        amount: monthlyTotals.salary
      },
      {
        type: "Reimbursements",
        amount: monthlyTotals.reimbursements
      },
      {
        type: "Total Payment",
        amount: monthlyTotals.total_amount
      }
    ];
    if (monthlyTotals.salary == 0) {
      payments.splice(9, 1);
    }
    setPaymentInfo(payments);
  }, [monthlyTotals]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(coachDashboardAction.loadMonthlyTotalsData({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(coachDashboardAction.loadMonthlyTotalsData({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        page_name: "My Payments"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    dispatch(coachDashboardAction.loadMonthlyTotalsData({ filters: filters }));
  }, [applyClicked]);

  return (
    <>
      <Container className="payments-container">
        <Row className="title-row">
          <Col xs={12} className="filter-col">
            <p className="payment-title">PAYMENTS</p>
            <Button className="filter-button" onClick={() => setFiltersClicked(!filtersClicked)}>
              FILTERS
            </Button>
          </Col>
        </Row>
        {filtersClicked && (
          <Row style={{ width: "100%" }}>
            <Filters
              setFiltersClicked={setFiltersClicked}
              filtersClicked={filtersClicked}
              onClick={handleClick}>
              <DatePicker
                key={100}
                label={"Date Range"}
                startDate={startDate}
                endDate={endDate}
                showToggle={showToggle}
                setShowToggle={setShowToggle}
                type="range">
                <DateRangePicker
                  startDatePlaceholder={startDate}
                  endDatePlaceholder={endDate}
                  ranges={[
                    { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
                  ]}
                  onChange={(e) => handleChange("Date Range", e)}
                  showPreview={false}
                />
              </DatePicker>
            </Filters>
          </Row>
        )}
        <Row className="mt-4" style={{ width: "100%" }}>
          <Col xs={12}>
            <Container className="table-container">
              {paymentInfo.map((payment) => {
                return (
                  <Row key={payment.type}>
                    <Col xs={12} className="payments">
                      <p>{payment.type}</p>
                      <p>${payment.amount ? Number(payment.amount).toLocaleString() : 0}</p>
                    </Col>
                  </Row>
                );
              })}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Payment;
