import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let thisMonthTotals, monthlyTotals, recentSessions, coachMonthlyPayments;

let { resetLoader, setLoader } = loaderAction;

const loadThisMonthTotalsData = (filters) => (dispatch) => {
  dispatch(setLoader('loadThisMonthTotals'))
  if (typeof thisMonthTotals != typeof undefined) {
    thisMonthTotals.cancel('The load operation has been canceled due to a new request')
  }
  thisMonthTotals = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.THIS_MONTH_TOTALS, data: filters, token: thisMonthTotals.token})).then((res) => {
    dispatch(populateThisMonthTotalsData(res['data']['result']));
    dispatch(resetLoader('loadThisMonthTotals'));
  })
  .catch(err => console.log(err))
};

const loadCoachMonthlyPayments = (filters) => (dispatch) => {
  dispatch(setLoader("loadCoachMonthylPayments"));
  if (typeof adminMonthlyPayments != typeof undefined) {
    coachMonthlyPayments.cancel("The load operation has been canceled due to a new request");
  }
  coachMonthlyPayments = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.COACH_MONTHLY_PAYMENTS,
      data: filters,
      token: coachMonthlyPayments.token
    })
  )
    .then((res) => {
      dispatch(populateCoachMonthlyPayments(res["data"]["result"]));
      dispatch(resetLoader("coachMonthlyPayments"));
    })
    .catch((err) => console.log(err));
};

const loadMonthlyTotalsData = (filters) => (dispatch) => {
  dispatch(setLoader('loadMonthlyTotals'))
  if (typeof monthlyTotals != typeof undefined) {
    monthlyTotals.cancel('The load operation has been canceled due to a new request')
  }
  monthlyTotals = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.MONTHLY_TOTALS, data: filters, token: monthlyTotals.token})).then((res) => {
    dispatch(populateMonthlyTotalsData(res['data']['result']));
    dispatch(resetLoader('loadMonthlyTotals'));
  })
  .catch(err => console.log(err))
};

const loadRecentSessionData = (filters) => (dispatch) => {
  dispatch(setLoader('loadRecentSessions'))
  if (typeof recentSessions != typeof undefined) {
    recentSessions.cancel('The load operation has been canceled due to a new request')
  }
  recentSessions = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.RECENT_SESSIONS, data: filters, token: recentSessions.token})).then((res) => {
    dispatch(populateRecentSessionData(res['data']['result']));
    dispatch(resetLoader('loadRecentSessions'));
  })
  .catch(err => console.log(err))
};

const populateCoachMonthlyPayments = (data) => ({
  type: "LOAD_COACH_MONTHLY_PAYMENTS",
  payload: data
});

const populateThisMonthTotalsData = (data) => ({
  type: 'LOAD_THIS_MONTH_TOTALS',
  payload: data
});

const populateMonthlyTotalsData = (data) => ({
  type: 'LOAD_MONTHLY_TOTALS',
  payload: data
});

const populateRecentSessionData = (data) => ({
  type: 'LOAD_RECENT_SESSIONS',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const coachDashboardAction = {
  loadThisMonthTotalsData,
  loadCoachMonthlyPayments,
  loadMonthlyTotalsData,
  loadRecentSessionData,
  resetData
}
