import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";

const BlogDetailForm = ({ blogDetail, clicked, setClicked, refresh }) => {
  const [name, setName] = useState(blogDetail.blog_name);
  const [date, setDate] = useState(blogDetail.blog_date);
  const [sport, setSport] = useState(blogDetail.sport);
  const [type, setType] = useState(blogDetail.topic);
  const sportOptions = [
                        { value: "Academic Mindset", label: "Academic Mindset" },
                        { value: "Baseball", label: "Baseball" },
                        { value: "Baseball/Softball", label: "Baseball/Softball" },
                        { value: "Basketball", label: "Basketball" },
                        { value: "Bowling", label: "Bowling" },
                        { value: "Corporate Sales", label: "Corporate Sales" },
                        { value: "Field Hockey", label: "Field Hockey" },
                        { value: "Football", label: "Football" },
                        { value: "Golf", label: "Golf" },
                        { value: "Gymnastics", label: "Gymnastics" },
                        { value: "Hockey", label: "Hockey" },
                        { value: "Lacrosse", label: "Lacrosse" },
                        { value: "Martial Arts", label: "Martial Arts" },
                        { value: "Other", label: "Other" },
                        { value: "Soccer", label: "Soccer" },
                        { value: "Swimming", label: "Swimming" },
                        { value: "Tennis", label: "Tennis" },
                        { value: "Track", label: "Track" },
                        { value: "Volleyball", label: "Volleyball" },
                        { value: "Water Skiing", label: "Water Skiing" },
                        { value: "Wrestling", label: "Wrestling" }
                      ]
  const blogTypeOptions = [
                            {value: "Blog", label: "Blog"},
                            {value: "Social Media", label: "Social Media"}
                          ];

  const onClick = (e) => {
    const req_obj = {
      sport: sport,
      blog_date: date,
      blog_name: name,
      topic: type
    };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_BLOG_DETAIL, data: req_obj })
    ).then((res) => {
      // console.log(res);
    });
    setClicked(!clicked);
    refresh();
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Blog Sport":
        setSport(e);
        break;
      case "Blog Date":
        setDate(e);
        break;
      case "Blog Name":
        setName(e);
        break;
      case "Blog Type":
        setType(e);
        break;
      default:
        break;
    }
  };

  return (
      <>
      <ReactSelect
        options={sportOptions}
        value={sport}
        placeholder="Sport"
        handleChange={handleChange}
      />
      <Input
        label="Blog Date"
        id="Blog Date"
        type="date"
        disabled={false}
        onChange={(e) => handleChange("Blog Date", e)}
      />
      <TextArea
        label="Blog Name"
        height={'300px'}
        id="Blog Name"
        type="text"
        onChange={(e) => handleChange("Blog Name", e)}
      />
      <ReactSelect
        options={blogTypeOptions}
        value={type}
        placeholder="Blog Type"
        handleChange={handleChange}
      />
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
      </>
  );
};

export default BlogDetailForm;
