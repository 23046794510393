import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from 'react-bootstrap';
import Logo from '../../assets/wm-logo.jpg';
import './Login.scss';
import routingConstants from '../../Navigation/constants';

const Login = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [validation, setValidation] = useState({ username: false, password: false });
  const [validFlag, setValidFlag] = useState(false)
  const userNamePattern = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
  const { pathname } = location;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(username && password)) {
      setValidation({ username: true, password: true });
      return;
    }
    setFormSubmitted(true);
    oktaAuth
      .signInWithCredentials({ username, password })
      .then((res) => {
        const sessionToken = res.sessionToken;
        setSessionToken(sessionToken);
        oktaAuth.signInWithRedirect({ sessionToken });
      })
      .catch((err) => {
        setFormSubmitted(false);
        setAuthError(true);
      });
  };

  const onBlurValid = (e) => {
    setAuthError(false);
    switch (e.target.name) {
      case 'username': if (!userNamePattern.test(username)) {
        setValidation(oldVal => ({ ...oldVal, username: true }));
      }
        break;
      case 'password': if (!password.length > 0) {
        setValidation(oldVal => ({ ...oldVal, password: true }));
      }
        break;
      default: return
    }
  };

  const onFocusValid = (e) => {
    setAuthError(false);
    if (validFlag) {
      setValidation({ username: false, password: false });
      setValidFlag(false)
    }
    switch (e.target.name) {
      case 'username': if (!userNamePattern.test(username)) {
        setValidation(oldVal => ({ ...oldVal, username: false }));
        setAuthError(false);
      }
        break;
      case 'password': if (!password.length > 0) {
        setValidation(oldVal => ({ ...oldVal, password: false }));
        setAuthError(false)
      }
        break;
      default: return
    }
  };

  if (sessionToken) {
    return null
  }

  return authState && authState.isAuthenticated ? (
    <Redirect to='/' />
  ) : (
    <div className="login">
      <Container className="login-container">
        <Row>
          <Col className="mb-4 logo">
            <Image src={Logo} className="wm-logo" />
          </Col>
        </Row>
        <Row>
        <div className="space-for-auth">
          {(validation.username || validation.password || authError) && <div className="authentication-section">
            <p className="login-authentication">Authentication failed, try again</p>
          </div>}
          </div>
        </Row>
        <Row className="form-container">
          <Col>
            <Form className="mt-4 form" onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="email">
                      <FloatingLabel label="Email" className={`floating-label ${(validation.username || authError) && `error_border`}`} controlId='username_input'>
                      <Form.Control
                        type="email"
                        placeholder=""
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onFocus={onFocusValid}
                        onBlur={onBlurValid}
                      />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4">
                    <Form.Group controlId="password">
                      <FloatingLabel label="Password" className={`floating-label ${(validation.password || authError) && `error_border`}`} controlId='password_input'>
                      <Form.Control
                        type="password"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={onFocusValid}
                        onBlur={onBlurValid}
                      />
                      </FloatingLabel>
                    </Form.Group>
                    {validation.password && <p className="error_message">Password invalid</p>}
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4 form-button">
                    <Button type="submit">LOGIN</Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4">
                    <p>
                      New user? Sign up <Link to={routingConstants.SIGNUP}><span>here</span></Link>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4">
                    <p>
                      <Link to={routingConstants.FORGOT_PASSWORD}><span>Forgot Password</span></Link>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
