import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Logo from "../../assets/wm-logo.jpg";
import "./BlogDetail.scss";
import routingConstants from "../../Navigation/constants";
import { blogAction } from "../../Redux/actions";
import BlogDetailForm from "./BlogDetailForm";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

library.add(faRunning);
library.add(faBed);

const BlogDetail = (props) => {
  const dispatch = useDispatch();
  const blogDetail = useSelector((state) => state.blogReducer.blogDetail);
  const [sport, setSport] = useState();
  const [date, setDate] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const toastId = useRef(null);

  const refresh = () => {
    dispatch(blogAction.loadBlogDetailData({ filters: { blog_id: props.location.state.id } }));
  };

  useEffect(() => {
    //dispatch(clientDashboardAction.resetData())
    console.log(props.location.state);
    if (props.location.state) {
      dispatch(blogAction.loadBlogDetailData({ filters: { blog_id: props.location.state.id } }));
    }
  }, [dispatch]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_BLOG, data: {'blog_id': blogDetail.blog_id} }))
      .then((res) => {
        if (res) {
          history.push("/blogs")
        } else {
          toastId.current = toast.error(
            "There was an error deleting the blog " + blogDetail.blog_id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the blog data", {
          position: "top-center"
        });
      });
  };


  const onChange = (e) => {
    switch (e.target.id) {
      case "sport":
        setSport(e.target.value);
        break;
      case "date":
        setDate(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="blog-title">BLOG</div>
      <div className="blog-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Blog Name</div>}
            {/*<Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
              {clicked ? "CANCEL" : "EDIT"}
            </Button>*/}
            <DeleteButton
              deleteClicked={deleteClicked}
              handleCancel={handleCancel}
              handleDelete={handleDelete}
            />
          </div>
          {clicked ? (
            <BlogDetailForm
              blogDetail={blogDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
            />
          ) : (
            <>
              <div className="mt-3">{blogDetail.blog_name}</div>
              <div className="labels mt-3">Blog Date</div>
              <div className="mt-2">{blogDetail.blog_date}</div>
              <div className="labels mt-3">Blog Sport</div>
              <div className="mt-2">{blogDetail.sport}</div>
              <div className="labels mt-3">Blog Type</div>
              <div className="mt-2">{blogDetail.topic}</div>
              <div className="labels mt-3">Written By</div>
              <div className="mt-2">{blogDetail.coach_name}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
