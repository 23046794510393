import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let paymentAdjustmentList, paymentAdjustmentDetail;

let { resetLoader, setLoader } = loaderAction;

const loadPaymentAdjustmentListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadPaymentAdjustmentList'))
  if (typeof paymentAdjustmentList != typeof undefined) {
    paymentAdjustmentList.cancel('The load operation has been canceled due to a new request')
  }
  paymentAdjustmentList = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.PAYMENT_ADJUSTMENT_LIST, data: filters, token: paymentAdjustmentList.token})).then((res) => {
    dispatch(populatePaymentAdjustmentListData(res['data']['result']));
    dispatch(resetLoader('loadPaymentAdjustmentList'));
  })
  .catch(err => console.log(err))
};

const loadPaymentAdjustmentDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadPaymentAdjustmentDetail'))
  if (typeof paymentAdjustmentDetail != typeof undefined) {
    paymentAdjustmentDetail.cancel('The load operation has been canceled due to a new request')
  }
  paymentAdjustmentDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.PAYMENT_ADJUSTMENT_DETAIL, data: filters, token: paymentAdjustmentDetail.token})).then((res) => {
    dispatch(populatePaymentAdjustmentDetailData(res['data']['result']));
    dispatch(resetLoader('loadPaymentAdjustmentDetail'));
  })
  .catch(err => console.log(err))
};

const populatePaymentAdjustmentListData = (data) => ({
  type: 'LOAD_PAYMENT_ADJUSTMENT_LIST',
  payload: data
});

const populatePaymentAdjustmentDetailData = (data) => ({
  type: 'LOAD_PAYMENT_ADJUSTMENT_DETAIL',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const paymentAdjustmentAction = {
  loadPaymentAdjustmentListData,
  loadPaymentAdjustmentDetailData,
  resetData
}
