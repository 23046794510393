import React from "react";
import { Link } from "react-router-dom";

const Item = ({ item, itemLeftLabel, itemRightLabel, itemId, pathname, admin = false, itemCenterLabel = "" }) => {
  return (
    <Link className="item" to={{ pathname: pathname, state: { id: item[itemId], admin: admin } }}>
      <div className="item-container">
        <div className="item-text">{item[itemLeftLabel]}</div>
        <div>{`(${item[itemId]})`}</div>
      </div>
      <div>{item[itemCenterLabel]}</div>
      <div>{item[itemRightLabel]}</div>
    </Link>
  );
};

export default Item;
