import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import NavButton from "../../Components/NavButton";
import SessionsCard from "../../Components/SessionsCard";
import "./CoachDashboard.scss";
import { coachDashboardAction } from "../../Redux/actions";
import { useMediaQuery } from "beautiful-react-hooks";

const Overview = ({ coach }) => {
  const dispatch = useDispatch();
  const thisMonthTotals = useSelector((state) => state.coachDashboardReducer.thisMonthTotals);
  const recentSessions = useSelector((state) => state.coachDashboardReducer.recentSessions);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const match = useMediaQuery("(min-width: 992px");
  useEffect(() => {
    //dispatch(coachDashboardAction.resetData())
    dispatch(coachDashboardAction.loadThisMonthTotalsData({ filters: {} }));
    dispatch(coachDashboardAction.loadRecentSessionData({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    // console.log(thisMonthTotals)
    // console.log(recentSessions)
    let payments = [
      {
        type: "Video Sessions",
        amount: thisMonthTotals.team_video_sessions
      },
      {
        type: "Athlete Sessions",
        amount: thisMonthTotals.athlete_sessions
      },
      {
        type: "Blogs",
        amount: thisMonthTotals.blogs
      },
      {
        type: "Sales",
        amount: thisMonthTotals.sales
      },
      {
        type: "Team In Person Sessions",
        amount: thisMonthTotals.team_in_person_sessions
      },
      {
        type: "Corporate Sessions",
        amount: thisMonthTotals.corporate_sessions
      },
      {
        type: "Events",
        amount: thisMonthTotals.club_runs
      },
      {
        type: "Payment Adjustments",
        amount: thisMonthTotals.payment_adjustments
      },
      {
        type: "Sales Hours",
        amount: thisMonthTotals.sales_hours
      },
      {
        type: "Salary",
        amount: thisMonthTotals.salary
      },
      {
        type: "Reimbursements",
        amount: thisMonthTotals.reimbursements
      },
      {
        type: "Total Payment",
        amount: thisMonthTotals.total_amount
      }
    ];
    if (thisMonthTotals.salary == 0) {
      payments.splice(9, 1);
    }
    setPaymentInfo(payments);
  }, [thisMonthTotals, recentSessions]);

  return (
    <Container className={match ? "coach-container mt-5" : "coach-container"}>
      <Row className="buttons-container">
        <Container>
          <Row>
            <Col xs={6}>
              <NavButton icon={"ChatLeftFill"} title={"Log a session"} pathname="add_session" />
            </Col>
            <Col xs={6}>
              <NavButton icon={"Cash"} title={"My Payments"} pathname="payment" />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={6}>
              <NavButton icon={"Newspaper"} title={"My Sessions"} pathname="sessions" />
            </Col>
            <Col xs={6}>
              <NavButton icon={"Person"} title={"My Clients"} pathname="clients" />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="mt-4" style={{ width: "100%" }}>
        <Col xs={12}>
          <p id="payment_details">
            PAYMENT FOR {thisMonthTotals.start_date} - {thisMonthTotals.end_date} (${thisMonthTotals.total_amount ? Number(thisMonthTotals.total_amount).toLocaleString() : 0})
          </p>
        </Col>
        <Col xs={12}>
          <Container className="coach-table-container">
            {paymentInfo.map((payment) => {
              return (
                <Row key={payment.type}>
                  <Col xs={12} className="payments">
                    <p>{payment.type}</p>
                    <p>${payment.amount ? Number(payment.amount).toLocaleString('en-US', { useGrouping: true }) : 0}</p>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </Col>
      </Row>
      <Row className="mt-3" style={{ width: "100%" }}>
        <Col xs={12}>
          <p>PAST SESSIONS</p>
        </Col>
        <Col xs={12}>
          <div className="sessions-container">
            {recentSessions.length > 0 ? (
              recentSessions &&
              recentSessions.map((session) => {
                return (
                  <SessionsCard
                    key={session.session_id}
                    id={session.session_id}
                    session={session}
                  />
                );
              })
            ) : (
              <div className="mt-4">
                *You have not logged any sessions. Once a session is logged, it will be visible
                here.
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
