import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import React, { useState, useEffect, useRef } from "react";
import {
        desiredCoachExperienceLevelOptions,
        energyLevelPreferenceOptions,
        experienceList, 
        faithBasedOptions,
        lessonsImportanceOptions,
        personalityTypeList, 
        sportOptions,
        stateList, 
        trainingLevelList,
        rpInvolvementOptions,
        salesSourceList,
        genderOptionsList,
        tzList
       } from "../../Utils/selectConstants";

const AthleteForm = ({
                        firstName, 
                        lastName, 
                        nickname,
                        gender, 
                        email, 
                        phone, 
                        address,
                        city,
                        state,
                        trainingLevel,
                        school,
                        club,
                        experience,
                        personalityType,
                        faithBased,
                        goals,
                        aspirations,
                        additionalInfo,
                        lessonsImportance,
                        dateOfBirth,
                        rpName,
                        rpPhone,
                        rpEmail,
                        rpRelation,
                        rpInvolvement,
                        sports,
                        salesSource,
                        salesSourceDetailsLabel,
                        salesSourceDetails,
                        energyLevelPreference,
                        desiredCoachExperienceLevel,
                        timezone,
                        handleChange
                    }) => {
    return (
        <>
            <Input
                label="First Name"
                type="text"
                value={firstName}
                onChange={(e) => handleChange("First Name", e)}
            />
            <Input
                label="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => handleChange("Last Name", e)}
            />
            <Input
                label="Nickname"
                type="text"
                value={nickname}
                onChange={(e) => handleChange("Nickname", e)}
            />
            <ReactSelect
                type="text"
                placeholder="Gender"
                options={genderOptionsList}
                value={gender}
                handleChange={handleChange}
            />
            <Input
                label="Email"
                type="text"
                value={email}
                onChange={(e) => handleChange("Email", e)}
            />
            <Input
                label="Phone"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={phone}
                onChange={(e) => handleChange("Phone", e)}
            />
            <Input
                label="Street Address"
                type="text"
                value={address}
                onChange={(e) => handleChange("Street Address", e)}
            />
            <Input
                label="City/Town"
                type="text"
                value={city}
                onChange={(e) => handleChange("City/Town", e)}
            />
            <ReactSelect
                type="text"
                placeholder="State"
                options={stateList}
                value={state}
                handleChange={handleChange}
            />
            <ReactSelect
                options={tzList}
                value={timezone}
                placeholder="Primary Time Zone"
                handleChange={handleChange}
            />
            <ReactSelect
                options={sportOptions}
                value={sports}
                placeholder="Primary Sport(s)"
                handleChange={handleChange}
                isMulti
            />
            <ReactSelect
                options={trainingLevelList}
                value={trainingLevel}
                placeholder="Training Level"
                handleChange={handleChange}
            />
            <Input
                label="School Name"
                type="text"
                value={school}
                onChange={(e) => handleChange("School Name", e)}
            />
            <Input
                label="Club Name(s)"
                type="text"
                value={club}
                onChange={(e) => handleChange("Club Name(s)", e)}
            />
            <ReactSelect
                options={experienceList}
                value={experience}
                placeholder="Experience"
                handleChange={handleChange}
            />
            <ReactSelect
                options={personalityTypeList}
                value={personalityType}
                placeholder="Personality Type"
                handleChange={handleChange}
            />
            <ReactSelect
                options={faithBasedOptions}
                value={faithBased}
                placeholder="Would you like to incorporate the Christian faith into your training?"
                handleChange={handleChange}
            />
            <Input
                label="Goals"
                type="text"
                value={goals}
                onChange={(e) => handleChange("Goals", e)}
            />
            <Input
                label="Aspirations outside of athletics"
                type="text"
                value={aspirations}
                onChange={(e) => handleChange("Aspirations outside of athletics", e)}
            />
            <ReactSelect
                options={energyLevelPreferenceOptions}
                value={energyLevelPreference}
                placeholder="Do you prefer a Mindset Coach with low or high energy?"
                handleChange={handleChange}
            />
            <ReactSelect
                options={desiredCoachExperienceLevelOptions}
                value={desiredCoachExperienceLevel}
                placeholder="Desired age range of assigned Mindset Coach"
                handleChange={handleChange}
            />
            <Input
                label="Additional info that will help match you with a Mindset Coach"
                type="text"
                value={additionalInfo}
                onChange={(e) => handleChange("Additional info that will help match you with a Mindset Coach", e)}
            />
            <ReactSelect
                options={lessonsImportanceOptions}
                value={lessonsImportance}
                placeholder="Importance of learning life lessons in the program"
                handleChange={handleChange}
            />
            <Input
                label="Date of Birth"
                type="date"
                value={dateOfBirth}
                onChange={(e) => handleChange("Date of Birth", e)}
            />
            <Input
                label="Responsible Person Name"
                type="text"
                value={rpName}
                onChange={(e) => handleChange("Responsible Person Name", e)}
            />
            <Input
                label="Responsible Person Phone"
                type="text"
                value={rpPhone}
                onChange={(e) => handleChange("Responsible Person Phone", e)}
            />
            <Input
                label="Responsible Person Email"
                type="text"
                value={rpEmail}
                onChange={(e) => handleChange("Responsible Person Email", e)}
            />
            <Input
                label="Responsible Person Relationship"
                type="text"
                value={rpRelation}
                onChange={(e) => handleChange("Responsible Person Relationship", e)}
            />
            <ReactSelect
                options={rpInvolvementOptions}
                value={rpInvolvement}
                placeholder="Responsible Person Involvement"
                handleChange={handleChange}
            />
            <ReactSelect
                options={salesSourceList}
                value={salesSource}
                placeholder="How did you hear about Winning Mindset?"
                handleChange={handleChange}
            />
            {(salesSource.value === "Team/Club Presentation" ||
              salesSource.value === "Convention" ||
              salesSource.value == "Referral" ||
              salesSource.value == "Social Media" ||
              salesSource.value == "Tournament" || 
              salesSource.value == "Mindset Coach")
             ? <Input
                label={salesSourceDetailsLabel}
                type="text"
                value={salesSourceDetails}
                onChange={(e) => handleChange(salesSourceDetailsLabel, e)}
            /> : <div></div>
            }
        </>
    )
}

export default AthleteForm;