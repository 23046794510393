import React from "react";
import { ChatLeftFill, Cash, Newspaper, CurrencyDollar, Person } from "react-bootstrap-icons";
import "../Pages/CoachDashboard/CoachDashboard.scss";
import { Link } from "react-router-dom";

const Icons = {
  ChatLeftFill,
  Cash,
  Newspaper,
  Person
};

const NavButton = ({ icon, title, pathname }) => {
  const Icon = Icons[icon];

  return (
    <Link className="nav-button" to={{pathname: pathname}}>
      <Icon className="icon" />
      <div className="title">{title.toUpperCase()}</div>
    </Link>
  );
};

export default NavButton;
