import {combineReducers} from 'redux';

import {authReducer} from './authReducer';
import {adminReducer} from './adminReducer';
import {coachDashboardReducer} from './coachDashboardReducer';
import {coachDetailReducer} from './coachDetailReducer';
import {sessionReducer} from './sessionReducer';
import {clientReducer} from './clientReducer';
import {saleReducer} from './saleReducer';
import {blogReducer} from './blogReducer';
import {paymentAdjustmentReducer} from './paymentAdjustmentReducer';
import {clubRunReducer} from './clubRunReducer';
import {loaderReducer} from './loaderReducer';
import {filterReducer} from './filterReducer';


const appReducer = combineReducers({
  authReducer,
  coachDashboardReducer,
  coachDetailReducer,
  sessionReducer,
  clientReducer,
  saleReducer,
  blogReducer,
  clubRunReducer,
  filterReducer,
  adminReducer,
  loaderReducer
})

export default appReducer;
