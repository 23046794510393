import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let clientList, clientDetail, assignmentDetail, registrationKey;

let { resetLoader, setLoader } = loaderAction;

const loadClientListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadClientList'))
  if (typeof clientList != typeof undefined) {
    clientList.cancel('The load operation has been canceled due to a new request')
  }
  clientList = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.CLIENT_LIST, data: filters, token: clientList.token})).then((res) => {
    dispatch(populateClientListData(res['data']['result']));
    dispatch(resetLoader('loadClientList'));
  })
  .catch(err => console.log(err))
};

const loadClientDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadClientDetail'))
  if (typeof clientDetail != typeof undefined) {
    clientDetail.cancel('The load operation has been canceled due to a new request')
  }
  clientDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.CLIENT_DETAIL, data: filters, token: clientDetail.token})).then((res) => {

    dispatch(populateClientDetailData(res['data']['result']));
    dispatch(resetLoader('loadClientDetail'));
  })
  .catch(err => console.log(err))
};

const loadAssignmentDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadAssignmentDetail'))
  if (typeof assignmentDetail != typeof undefined) {
    assignmentDetail.cancel('The load operation has been canceled due to a new request')
  }
  assignmentDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.ASSIGNMENT_DETAIL, data: filters, token: assignmentDetail.token})).then((res) => {

    dispatch(populateAssignmentDetailData(res['data']['result']));
    dispatch(resetLoader('loadAssignmentDetail'));
  })
  .catch(err => console.log(err))
};

const loadRegistrationKeyData = (filters) => (dispatch) => {
  dispatch(setLoader('loadRegistrationKeyData'))
  if (typeof registrationKey != typeof undefined) {
    registrationKey.cancel('The load operation has been canceled due to a new request')
  }
  registrationKey = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.CURRENT_REGISTRATION_KEY, data: {}, token: registrationKey.token})).then((res) => {

    dispatch(populateRegistrationKeyData(res['data']['status']));
    dispatch(resetLoader('loadRegistrationKeyData'));
  })
  .catch(err => console.log(err))
};

const populateClientListData = (data) => ({
  type: 'LOAD_CLIENT_LIST',
  payload: data
});

const populateClientDetailData = (data) => ({
  type: 'LOAD_CLIENT_DETAIL',
  payload: data
});

const populateAssignmentDetailData = (data) => ({
  type: 'LOAD_ASSIGNMENT_DETAIL',
  payload: data
});

const populateRegistrationKeyData = (data) => ({
  type: 'LOAD_REGISTRATION_KEY',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const clientAction = {
  loadClientListData,
  loadClientDetailData,
  loadRegistrationKeyData,
  loadAssignmentDetailData,
  resetData
}
