import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let coachDetail,
    updatedCoachDetail,
    miscellaneousTime,
    miscellaneousTimeDetail;

let { resetLoader, setLoader } = loaderAction;

const loadMiscellaneousTimeData = (filters) => (dispatch) => {
  dispatch(setLoader('loadMiscellaneousTime'))
  if (typeof miscellaneousTime != typeof undefined) {
    miscellaneousTime.cancel('The load operation has been canceled due to a new request')
  }
  miscellaneousTime = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.MISCELLANEOUS_TIME_LIST, data: filters, token: miscellaneousTime.token})).then((res) => {
    dispatch(populateMiscellaneousTimeData(res['data']['result']));
    dispatch(resetLoader('loadMiscellaneousTime'));
  })
  .catch(err => console.log(err))
};

const loadMiscellaneousTimeDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadMiscellaneousTimeDetail'))
  if (typeof miscellaneousTimeDetail != typeof undefined) {
    miscellaneousTimeDetail.cancel('The load operation has been canceled due to a new request')
  }
  miscellaneousTimeDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.MISCELLANEOUS_TIME_DETAIL, data: filters, token: miscellaneousTimeDetail.token})).then((res) => {
    dispatch(populateMiscellaneousTimeDetailData(res['data']['result']));
    dispatch(resetLoader('loadMiscellaneousTimeDetail'));
  })
  .catch(err => console.log(err))
};

const loadCoachDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadCoachDetail'))
  if (typeof coachDetail != typeof undefined) {
    coachDetail.cancel('The load operation has been canceled due to a new request')
  }
  coachDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.COACH_DETAIL, data: filters, token: coachDetail.token})).then((res) => {
    dispatch(populateCoachDetailData(res['data']['result']));
    dispatch(resetLoader('loadCoachDetail'));
  })
  .catch(err => console.log(err))
};

const updateCoachDetailData = data => dispatch => {
  axios(requestOptions({method:'POST', url:apiConstants.UPDATE_COACH_DETAIL,data}))
  .then(res => {
    dispatch(populateUpdatedCoachData(data['request']))
  })
  .catch(err => {
    console.log(err)
  })
}

const populateUpdatedCoachData = data => ({
  type: 'UPDATE_COACH_DETAIL',
  payload: data
})

const populateCoachDetailData = (data) => ({
  type: 'LOAD_COACH_DETAIL',
  payload: data
});

const populateMiscellaneousTimeData = (data) => ({
  type: 'LOAD_MISCELLANEOUS_TIME',
  payload: data 
});

const populateMiscellaneousTimeDetailData = (data) => ({
  type: 'LOAD_MISCELLANEOUS_TIME_DETAIL',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const coachDetailAction = {
  loadCoachDetailData,
  updateCoachDetailData,
  loadMiscellaneousTimeData,
  loadMiscellaneousTimeDetailData,
  resetData
}
