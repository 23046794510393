import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction, sessionAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import ReactSelect from "../../Components/Forms/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "../../Components/Forms/DatePicker";
import { clientTypeList, sessionTypeList } from "../../Utils/selectConstants";
import { Calendar } from "react-date-range";

const MissedSessionsForm = (props) => {
  const [clientName, setClientName] = useState({ value: "", label: "" });
  const [clientId, setClientId] = useState();
  const [sessionDate, setSessionDate] = useState(new Date().toLocaleDateString());
  const [clientType, setClientType] = useState("");
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const toastId = useRef(null);
  const clientList_db = useSelector((state) => state.clientReducer.clientList);
  const [resetData, setResetData] = useState(false);
  const [showToggle, setShowToggle] = useState(false);


  useEffect(() => {
    dispatch(clientAction.loadClientListData({ filters: { is_active: ["Active"] } }));
  }, [dispatch]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e);
        setClientId(e.value);
        break;
      case "Client Type":
        setClientType(e);
        break;
      case "Notes/Reason for Missing Session":
        setNotes(e.target.value);
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        client_id: clientId,
        client_name: clientName.value,
        session_date: sessionDate,
        client_type: clientType.value,
        notes: notes
      };
      if (
        clientName.value !== "" &&
        sessionDate !== "" &&
        clientType.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setClientName("");
    setSessionDate(new Date().toLocaleDateString());
    setClientType("");
    setNotes("");
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_MISSED_SESSION, data: req_obj }))
      .then((res) => {
        if (res.data.code === "Success") {
          toastId.current = toast.success(
            "You’ve successfully inserted a missed session for " + req_obj["client_name"],
            { position: "top-center" }
          );
          reset_data();
        } else if (res.data.code === "Duplicate") {
          toastId.current = toast.error(
            "You’ve already inserted a missed session for " +
              req_obj["client_name"] +
              " on " +
              req_obj["session_date"],
            { position: "top-center" }
          );
        } else {
          toastId.current = toast.error(
            "There was an error inserting a missed session for " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting missed session data", {
          position: "top-center"
        });
      });
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setSessionDate(selectedDate.toLocaleDateString());
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="LOG A MISSED SESSION" onClick={handleClick}>
        <ReactSelect
          options={clientList}
          value={clientName}
          placeholder="Client Name"
          handleChange={handleChange}
        />
        <DatePicker
          date={sessionDate}
          showToggle={showToggle}
          setShowToggle={setShowToggle}
          label={"Missed Session Date"}>
          <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
        </DatePicker>
        <ReactSelect
          options={clientTypeList}
          value={clientType}
          placeholder="Client Type"
          handleChange={handleChange}
        />
        <TextArea label="Notes/Reason for Missing Session" height={'300px'} onChange={(e) => handleChange("Notes/Reason for Missing Session", e)} value={notes} />
      </FormContainer>
    </>
  );
};

export default MissedSessionsForm;
