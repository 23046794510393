export const months = [
  { value: "All", label: "All"},
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" }
];

export const years = [
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" }
];

export const states = [
                   {value: "All", label: "All"},
                   {value: "AL",  label: "AL"},
                   {value: "AK",  label: "AK"},
                   {value: "AZ",  label: "AZ"},
                   {value: "AR",  label: "AR"},
                   {value: "CA",  label: "CA"},
                   {value: "CO",  label: "CO"},
                   {value: "CT",  label: "CT"},
                   {value: "DE",  label: "DE"},
                   {value: "FL",  label: "FL"},
                   {value: "GA",  label: "GA"},
                   {value: "HI",  label: "HI"},
                   {value: "ID",  label: "ID"},
                   {value: "IL",  label: "IL"},
                   {value: "IN",  label: "IN"},
                   {value: "IA",  label: "IA"},
                   {value: "KS",  label: "KS"},
                   {value: "KY",  label: "KY"},
                   {value: "LA",  label: "LA"},
                   {value: "ME",  label: "ME"},
                   {value: "MD",  label: "MD"},
                   {value: "MA",  label: "MA"},
                   {value: "MI",  label: "MI"},
                   {value: "MN",  label: "MN"},
                   {value: "MS",  label: "MS"},
                   {value: "MO",  label: "MO"},
                   {value: "MT",  label: "MT"},
                   {value: "NE",  label: "NE"},
                   {value: "NV",  label: "NV"},
                   {value: "NH",  label: "NH"},
                   {value: "NJ",  label: "NJ"},
                   {value: "NM",  label: "NM"},
                   {value: "NY",  label: "NY"},
                   {value: "NC",  label: "NC"},
                   {value: "ND",  label: "ND"},
                   {value: "OH",  label: "OH"},
                   {value: "OK",  label: "OK"},
                   {value: "OR",  label: "OR"},
                   {value: "PA",  label: "PA"},
                   {value: "RI",  label: "RI"},
                   {value: "SC",  label: "SC"},
                   {value: "SD",  label: "SD"},
                   {value: "TN",  label: "TN"},
                   {value: "TX",  label: "TX"},
                   {value: "UT",  label: "UT"},
                   {value: "VT",  label: "VT"},
                   {value: "VA",  label: "VA"},
                   {value: "WA",  label: "WA"},
                   {value: "WV",  label: "WV"},
                   {value: "WI",  label: "WI"},
                   {value: "WY",  label: "WY"}]
