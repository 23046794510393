import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Logo from "../../assets/wm-logo.jpg";
import "./PaymentAdjustmentDetail.scss";
import routingConstants from "../../Navigation/constants";
import { adminAction } from "../../Redux/actions";
import PaymentAdjustmentDetailForm from "./PaymentAdjustmentDetailForm";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

library.add(faRunning);
library.add(faBed);

const PaymentAdjustmentDetail = (props) => {
  const dispatch = useDispatch();
  const paymentAdjustmentDetail = useSelector((state) => state.adminReducer.adminPaymentAdjustmentDetail);
  const [paymentAdjustmentDetailFinal, setPaymentAdjustmentDetailFinal] = useState({});
  const [type, setType] = useState();
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const toastId = useRef(null);

  const refresh = () => {
    dispatch(adminAction.loadAdminPaymentAdjustmentDetail({ filters: { payment_adjustment_id: props.location.state.id } }));
  };

  useEffect(() => {
    //dispatch(clientDashboardAction.resetData())
    if (props.location.state) {
      dispatch(adminAction.loadAdminPaymentAdjustmentDetail(({ filters: { payment_adjustment_id: props.location.state.id } })));
    }
  }, [dispatch]);

  useEffect(() => {
    // if (props.location.state && props.location.state.admin == true) {
    //   setPaymentAdjustmentDetailFinal();
    // } else {
    setPaymentAdjustmentDetailFinal(paymentAdjustmentDetail);
    // }
  }, [paymentAdjustmentDetail]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_PAYMENT_ADJUSTMENT, data: {'payment_adjustment_id': paymentAdjustmentDetail.payment_adjustment_id} }))
      .then((res) => {
        if (res) {
          history.push("/admin_payment_adjustments")
        } else {
          toastId.current = toast.error(
            "There was an error deleting the payment adjustment " + paymentAdjustmentDetail.payment_adjustment_id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the payment adjustment data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <div className="payment-adjustment-title">PAYMENT ADJUSTMENT</div>
      <div className="payment-adjustment-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Payment Adjustment</div>}
            {/*<Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
              {clicked ? "CANCEL" : "EDIT"}
            </Button>*/}
            <DeleteButton
              deleteClicked={deleteClicked}
              handleCancel={handleCancel}
              handleDelete={handleDelete}
            />
          </div>
          {clicked ? (
            <PaymentAdjustmentDetailForm
              paymentAdjustmentDetail={paymentAdjustmentDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
            />
          ) : (
            <>
              <div className="labels mt-3">Payment Adjustment Date</div>
              <div className="mt-2">{paymentAdjustmentDetailFinal.adjustment_date}</div>
              <div className="labels mt-3">Coach Name</div>
              <div className="mt-2">{paymentAdjustmentDetailFinal.coach_name}</div>
              <div className="labels mt-3">Adjustment Price</div>
              <div className="mt-2">{paymentAdjustmentDetailFinal.adjustment_price}</div>
              <div className="labels mt-3">Adjustment Notes</div>
              <div className="mt-2">{paymentAdjustmentDetailFinal.adjustment_notes}</div>
              <div className="labels mt-3">Adjustment Type</div>
              <div className="mt-2">{paymentAdjustmentDetailFinal.adjustment_type}</div>
              <div className="labels mt-3">Submitted Timestamp</div>
              <div className="mt-2">{paymentAdjustmentDetailFinal.submitted_timestamp}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentAdjustmentDetail;
