import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import "./SessionDetail.scss";
import SessionDetailForm from "./SessionDetailForm";
import { sessionAction, adminAction } from "../../Redux/actions";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";

const SessionDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionDetail = useSelector((state) => state.sessionReducer.sessionDetail);
  const adminSessionDetail = useSelector((state) => state.adminReducer.adminSessionDetail);
  const [sessionDetailFinal, setSessionDetailFinal] = useState({});
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    if (props.location.state && props.location.state.admin === true) {
      dispatch(
        adminAction.loadAdminSessionDetail({ filters: { session_id: props.location.state.id } })
      );
    } else {
      dispatch(
        sessionAction.loadSessionDetailData({ filters: { session_id: props.location.state.id } })
      );
    }
  }, [detailEdited, dispatch]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  useEffect(() => {
    if (props.location.state && props.location.state.admin == true) {
      setSessionDetailFinal(adminSessionDetail);
    } else {
      setSessionDetailFinal(sessionDetail);
    }
  }, [sessionDetail, adminSessionDetail]);

  const refresh = () => {
    if (props.location.state && props.location.state.admin === true) {
      dispatch(
        adminAction.loadAdminSessionDetail({ filters: { session_id: props.location.state.id } })
      );
    } else {
      dispatch(
        sessionAction.loadSessionDetailData({ filters: { session_id: props.location.state.id } })
      );
    }
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  };

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(
      requestOptions({
        method: "POST",
        url: API_CONSTANTS.DELETE_SESSION,
        data: { session_id: sessionDetailFinal.session_id }
      })
    )
      .then((res) => {
        if (res) {
          history.push("/sessions");
        } else {
          toastId.current = toast.error(
            "There was an error deleting the session " + sessionDetailFinal.session_id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the session data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="session-title">SESSION</div>
      <div className="session-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Client</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <SessionDetailForm
              sessionDetail={sessionDetailFinal}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
          ) : (
            <>
              <div className="mt-3">{sessionDetailFinal.client_name}</div>
              <div className="labels mt-3">Date</div>
              <div className="mt-2">
                {new Date(sessionDetailFinal.session_date).toLocaleDateString('en-US', {timeZone: 'UTC'})}
              </div>
              <div className="labels mt-3">Client Type</div>
              <div className="mt-3">{sessionDetailFinal.client_type}</div>
              <div className="labels mt-3">Session Type</div>
              <div className="mt-3">{sessionDetailFinal.session_type}</div>
              <div className="labels mt-3">Topic</div>
              <div className="mt-3">{sessionDetailFinal?.topic?.map((topic) => topic + ", ")}</div>
              <div className="labels mt-3">Worksheet</div>
              <div className="mt-3">
                {sessionDetailFinal?.worksheet?.map((worksheet) => worksheet + ", ")}
              </div>
              {sessionDetailFinal.workshop_number && (
                <div>
                  <div className="labels mt-3">Workshop Number</div>
                  <div className="mt-3">{sessionDetailFinal.workshop_number}</div>
                </div>
              )}
              <div className="labels mt-3">Notes:</div>
              <div className="notes mt-3">
                <pre>{sessionDetailFinal.notes}</pre>
              </div>
              <div className="labels mt-3">Logged Date</div>
              <div className="mt-3">{sessionDetailFinal.submitted_timestamp}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SessionDetail;
