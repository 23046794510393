const initialState = {}

export const loaderReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'SET_LOADER':
      return {
        ...state,
        [payload]: true
      }
    case 'RESET_LOADER':
      return {
        ...state,
        [payload]: false
      }
    default: return state;
  }
}
