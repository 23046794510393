import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./AdminClientAssignmentDetail.scss";
import AdminClientAssignmentDetailForm from "./AdminClientAssignmentDetailForm";
import { clientAction } from "../../Redux/actions";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

const AdminClientAssignmentDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const assignmentDetail = useSelector((state) => state.clientReducer.assignmentDetail);
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
      dispatch(clientAction.loadAssignmentDetailData({ filters: { assignment_id: props.location.state.id } }));
  }, [detailEdited, dispatch]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  const refresh = () => {
    dispatch(clientAction.loadAssignmentDetailData({ filters: { assignment_id: props.location.state.id } }));
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_ASSIGNMENT, data: {'assignment_id': props.location.state.id} }))
      .then((res) => {
        if (res) {
          history.push("/admin_client_assignments")
        } else {
          toastId.current = toast.error(
            "There was an error deleting the assignment",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the assignment", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <div className="sale-title">CLIENT ASSIGNMENT DETAIL</div>
      <div className="sale-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Client</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              {props.location.state.admin === true &&
                <DeleteButton
                  deleteClicked={deleteClicked}
                  handleCancel={handleCancel}
                  handleDelete={handleDelete}
                />
              }
            </div>
          </div>
          {clicked ? (
            <AdminClientAssignmentDetailForm
              assignmentDetail={assignmentDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
          ) : (
            <>
              <div className="mt-3">{assignmentDetail.client_name}</div>
              <div className="labels mt-3">Client Type</div>
              <div className="mt-2">{assignmentDetail.assignment_type}</div>
              <div className="labels mt-3">Program Name</div>
              <div className="mt-2">{assignmentDetail.assignment_program}</div>
              <div className="labels mt-3">Curriculum</div>
              <div className="mt-2">{assignmentDetail.curriculum}</div>
              <div className="labels mt-3">Coach Name</div>
              <div className="mt-2">{assignmentDetail.coach_name}</div>
              <div className="labels mt-3">Start Date</div>
              <div className="mt-2">{assignmentDetail.assignment_start_date}</div>
              <div className="labels mt-3">End Date</div>
              <div className="mt-2">{assignmentDetail.assignment_end_date}</div>
              <div className="labels mt-3">Length on program (computed)</div>
              <div className="mt-2">{assignmentDetail.length_of_program}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminClientAssignmentDetail;
