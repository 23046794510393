import { Parser } from 'json2csv';
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

// export interface ExportButtonProps {
// 	/** The filename for the file to be downloaded by the user. */
// 	filename?: string;

// 	/** List-like structure of flattened data thats easy to parse to a CSV. */
// 	contents: Array<unknown>;

// 	sizeX?: string;
// 	sizeY?: string;
// 	variant?: string;
// 	children?: string;
// 	no_button?: boolean;
// }

export function ExportButton({ no_button, children, contents = [], filename = 'export.csv' }) {
	if (contents.length === 0) return null;

	const downloadFileContents = () => {
		const parser = new Parser();
		const blob = new Blob([parser.parse(contents)], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.style.visibility = 'hidden';
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
        <>
            <Button onClick={downloadFileContents} className="filters-button-client">
                {children ? children : 'EXPORT'}
            </Button>
        </>
	);
}