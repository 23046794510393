import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button, Row, Col } from "react-bootstrap";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import TextArea from "../../Components/Forms/TextArea";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { coachDetailAction, adminAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";

function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

const MiscellaneousTimeDetailForm = ({miscellaneousTimeDetail, clicked, setClicked, refresh, handleDetailEditedChange}) => {
    const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
    const [totalMinutes, setTotalMinutes] = useState(miscellaneousTimeDetail.total_minutes);
    const [coach, setCoach] = useState(
        {
            value: miscellaneousTimeDetail.coach_name + ' (' + miscellaneousTimeDetail.coach_id + ')',
            label: miscellaneousTimeDetail.coach_name + ' (' + miscellaneousTimeDetail.coach_id + ')'
        }
    );
    const toastId = useRef(null);
    const userInfo = useSelector((state) => state.authReducer.persona);
    const [fullTimeToSubmit, setFullTimeToSubmit] = useState([]);
    const [currentKey, setCurrentKey] = useState(0);
    const dispatch = useDispatch();
    const [coachList, setCoachList] = useState(
        [
            {
                value: miscellaneousTimeDetail.coach_name + ' (' + miscellaneousTimeDetail.coach_id + ')',
                label: miscellaneousTimeDetail.coach_name + ' (' + miscellaneousTimeDetail.coach_id + ')'
            }
        ]
    );
    const [dateRangeRow, setDateRangeRow] = useState(
        [new Date(miscellaneousTimeDetail.datetime_start), new Date(miscellaneousTimeDetail.datetime_end)]
    );
    const [minutesPresent, setMinutesPresent] = useState(false);
    const [totalHours, setTotalHours] = useState(miscellaneousTimeDetail.total_minutes > 0 ? miscellaneousTimeDetail.total_minutes / 60 : 0);
    const [description, setDescription] = useState(miscellaneousTimeDetail.description);

    useEffect(() => {
        dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
      }, [dispatch]);

    useEffect(() => {
        if (coachList_db.length > 0) {
            let sr_list_db = coachList_db.map((coach) => {
                return {
                    value: `${coach.coach_name} (${coach.coach_id})`,
                    label: `${coach.coach_name} (${coach.coach_id})`
                };
            });
            sr_list_db.push({ value: "", label: "" });
            setCoachList(sr_list_db);
        }
    }, [coachList_db]);

    const handleDateRangeChange = (e) => {
        if (calculateMinutes(e) > 0) {
            setMinutesPresent(true);
        }
        setDateRangeRow(e);
    }

    const handleStagingClick = (e) => {
        setCurrentKey(currentKey + 1);
        let minutes = calculateMinutes(dateRangeRow);
        setTotalMinutes(minutes);
        if (minutes > 0) {
            setTotalHours((minutes / 60));
        }
        fullTimeToSubmit.push(
            {
                'key': currentKey,
                'daterange': [dateRangeRow[0].toLocaleString(), dateRangeRow[1].toLocaleString()],
                'description': "- " + description,
                'minutes': minutes
            }
        );
        setDateRangeRow([new Date(), new Date()]);
        setDescription("");
        setMinutesPresent(false);
      }

    const handleChange = (selector, e) => {
        switch (selector) {
            case "Coach":
                setCoach(e);
                break;
            case "Description":
                setDescription("");
                setDescription(e.target.value);
                break;
        }
    };

    const handleClick = (e) => {
        const req_obj = {
            totalMinutes: totalMinutes,
            rollup: fullTimeToSubmit,
            coach_id: coach !== "" ? coach.value : "",
            miscellaneous_time_id: miscellaneousTimeDetail.id
        };
        if (
            totalMinutes > 0 && fullTimeToSubmit.length === 1
        ) {
            update_data(req_obj);
        } else {
            toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
        }
    };

    const calculateMinutes = (dateRange) => {
        let diff = dateRange[1].getTime() - dateRange[0].getTime();
        let minutes = Math.round(diff / 60000);
        return minutes;
      }

    const update_data = (req_obj) => {
        axios(requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_MISCELLANEOUS_TIME_DETAIL, data: req_obj }))
          .then((res) => {
            if (res.status === 200) {
              toastId.current = toast.success(
                "You’ve successfully updated miscellaneous time",
                { position: "top-center" }
              );
            } else {
              toastId.current = toast.error(
                "There was an error updating miscellaneous time",
                { position: "top-center" }
              );
            }
          })
          .catch((err) => {
            toastId.current = toast.error("There was an error updating miscellaneous time data", {
              position: "top-center"
            });
          });
      };

    return (
        <div>
            {(userInfo && userInfo.is_admin === "1") && <ReactSelect
                options={coachList}
                value={coach}
                label="Coach"
                placeholder={"Coach"}
                id={"Coach"}
                handleChange={handleChange}
            />}
            <div className="header">Date and Time Range of Miscellaneous Time:</div>
            <DateTimeRangePicker 
                onChange={handleDateRangeChange}
                value={dateRangeRow}
                disableClock={true}
                className="daterangepicker"
                clearIcon={null}
                calendarClassName="daterangepickercalendar"
                rangeDivider={" to "}
            />
            <TextArea label="Description" height={'300px'} onChange={(e) => handleChange("Description", e)} value={description} />
            <Button 
                className='mt-3 staging-button'
                onClick={handleStagingClick}
                disabled={!minutesPresent}
            >
                ADD TO PENDING TIME
            </Button>
            <div className="header">Pending Time to Submit:</div>
            <div className="previewarea">
                {fullTimeToSubmit.length > 0 ?
                    (
                        fullTimeToSubmit.map((item) => {
                            return (
                                <ul key={item.key}>
                                    <li>
                                        {item.daterange[0] + " to " + item.daterange[1] + " (" + item.minutes + " minutes)"}
                                    </li>
                                    <ul>{item.notes}</ul>
                                </ul>
                            )
                        })
                    ) :
                    (<div>No Data</div>)
                }
            </div>
            <div className="header">Total Time to Submit:</div>
            <div className="previewarea">
                <div>{round(totalHours, 3)} hour(s) or {totalMinutes} minute(s)</div>
            </div>
            <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
                SAVE
            </Button>
        </div>
    )
}

export default MiscellaneousTimeDetailForm;