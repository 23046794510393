import API_CONSTANTS from "../Redux/constants";
import { requestOptions } from "./requestOptions";
import axios from "axios";

export const insert_data = (req_obj) => {
  axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_FILTERS, data: req_obj }))
    .then((res) => {
      if (res) {
        console.log("Successfully inserted session data");
      } else {
        console.log("No response");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
