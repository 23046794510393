import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const Input = ({ type, placeholder, label, id, disabled, onChange, value }) => {
  return (
    <Form.Group className="mt-3">
      <FloatingLabel label={label} className="floating-label">
        <Form.Control
          type={type}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          value={value}
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default Input;
