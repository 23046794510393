import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button, Row, Col } from "react-bootstrap";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import TextArea from "../../Components/Forms/TextArea";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { saleAction, adminAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";

function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

const SalesHoursDetailForm = ({salesHoursDetail, clicked, setClicked, refresh, handleDetailEditedChange}) => {
    const salesRepList_db = useSelector((state) => state.adminReducer.allSalesReps);
    const [totalMinutes, setTotalMinutes] = useState(salesHoursDetail.total_minutes);
    const [salesRep, setSalesRep] = useState({value: salesHoursDetail.coach_name + ' (' + salesHoursDetail.coach_id + ')', label: salesHoursDetail.coach_name + ' (' + salesHoursDetail.coach_id + ')'});
    const toastId = useRef(null);
    const userInfo = useSelector((state) => state.authReducer.persona);
    const [fullTimeToSubmit, setFullTimeToSubmit] = useState([]);
    const [currentKey, setCurrentKey] = useState(0);
    const dispatch = useDispatch();
    const [salesRepList, setSalesRepList] = useState([{value: salesHoursDetail.coach_name + ' (' + salesHoursDetail.coach_id + ')', label: salesHoursDetail.coach_name + ' (' + salesHoursDetail.coach_id + ')'}]);
    const [dateRangeRow, setDateRangeRow] = useState([new Date(salesHoursDetail.datetime_start), new Date(salesHoursDetail.datetime_end)]);
    const [minutesPresent, setMinutesPresent] = useState(false);
    const [totalHours, setTotalHours] = useState(salesHoursDetail.total_minutes > 0 ? salesHoursDetail.total_minutes / 60 : 0);
    const [notes, setNotes] = useState(salesHoursDetail.notes);

    useEffect(() => {
        dispatch(adminAction.loadAllSalesReps({ filters: {} }));
      }, [dispatch]);

    useEffect(() => {
        if (salesRepList_db.length > 0) {
            let sr_list_db = salesRepList_db.map((coach) => {
                return {
                    value: `${coach.coach_name} (${coach.coach_id})`,
                    label: `${coach.coach_name} (${coach.coach_id})`
                };
            });
            sr_list_db.push({ value: "", label: "" });
            setSalesRepList(sr_list_db);
        }
    }, [salesRepList_db]);

    const handleDateRangeChange = (e) => {
        if (calculateMinutes(e) > 0) {
            setMinutesPresent(true);
        }
        setDateRangeRow(e);
    }

    const handleStagingClick = (e) => {
        setCurrentKey(currentKey + 1);
        let minutes = calculateMinutes(dateRangeRow);
        setTotalMinutes(minutes);
        if (minutes > 0) {
            setTotalHours((minutes / 60));
        }
        fullTimeToSubmit.push({'key': currentKey, 'daterange': [dateRangeRow[0].toLocaleString(), dateRangeRow[1].toLocaleString()], 'notes': "- " + notes, 'minutes': minutes});
        setDateRangeRow([new Date(), new Date()]);
        setNotes("");
        setMinutesPresent(false);
      }

    const handleChange = (selector, e) => {
        switch (selector) {
            case "Sales Person":
                setSalesRep(e);
                break;
            case "Notes":
                setNotes("");
                setNotes(e.target.value);
                break;
        }
    };

    const handleClick = (e) => {
        const req_obj = {
            totalMinutes: totalMinutes,
            rollup: fullTimeToSubmit,
            coach_id: salesRep !== "" ? salesRep.value : "",
            sale_hours_id: salesHoursDetail.id
        };
        if (
            totalMinutes > 0 && fullTimeToSubmit.length === 1
        ) {
            update_data(req_obj);
        } else {
            toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
        }
    };

    const calculateMinutes = (dateRange) => {
        let diff = dateRange[1].getTime() - dateRange[0].getTime();
        let minutes = Math.round(diff / 60000);
        return minutes;
      }

    const update_data = (req_obj) => {
        axios(requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_SALES_HOURS, data: req_obj }))
          .then((res) => {
            if (res.status === 200) {
              toastId.current = toast.success(
                "You’ve successfully updated sales hours",
                { position: "top-center" }
              );
            } else {
              toastId.current = toast.error(
                "There was an error updating sales hours",
                { position: "top-center" }
              );
            }
          })
          .catch((err) => {
            toastId.current = toast.error("There was an error updating sales hours data", {
              position: "top-center"
            });
          });
      };

    return (
        <div>
            {(userInfo && userInfo.is_admin === "1") && <ReactSelect
                options={salesRepList}
                value={salesRep}
                label="Sales Person"
                placeholder={"Sales Person"}
                id={"Sales Person"}
                handleChange={handleChange}
            />}
            <div className="header">Date and Time Range of Sales Work:</div>
            <DateTimeRangePicker 
                onChange={handleDateRangeChange}
                value={dateRangeRow}
                disableClock={true}
                className="daterangepicker"
                clearIcon={null}
                calendarClassName="daterangepickercalendar"
                rangeDivider={" to "}
            />
            <TextArea label="Notes" height={'300px'} onChange={(e) => handleChange("Notes", e)} value={notes} />
            <Button 
                className='mt-3 staging-button'
                onClick={handleStagingClick}
                disabled={!minutesPresent}
            >
                ADD TO PENDING TIME
            </Button>
            <div className="header">Pending Time to Submit:</div>
            <div className="previewarea">
                {fullTimeToSubmit.length > 0 ?
                    (
                        fullTimeToSubmit.map((item) => {
                            return (
                                <ul key={item.key}>
                                    <li>
                                        {item.daterange[0] + " to " + item.daterange[1] + " (" + item.minutes + " minutes)"}
                                    </li>
                                    <ul>{item.notes}</ul>
                                </ul>
                            )
                        })
                    ) :
                    (<div>No Data</div>)
                }
            </div>
            <div className="header">Total Time to Submit:</div>
            <div className="previewarea">
                <div>{round(totalHours, 3)} hour(s) or {totalMinutes} minute(s)</div>
            </div>
            <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
                SAVE
            </Button>
        </div>
    )
}

export default SalesHoursDetailForm;