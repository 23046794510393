const initialState = {
  paymentAdjustmentList: [],
  paymentAdjustmentDetail: []
};

export const paymentAdjustmentReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_PAYMENT_ADJUSTMENT_LIST':
      return {
        ...state,
        paymentAdjustmentList: payload
      }
    case 'LOAD_PAYMENT_ADJUSTMENT_DETAIL':
      return {
        ...state,
        paymentAdjustmentDetail: payload
      }
    default:
      return state
  }
};
