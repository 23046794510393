import React, { useState, useRef  } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Container, Row } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import API_CONSTANTS from "../../Redux/constants";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";

const BlogForm = (props) => {
  const [sport, setSport] = useState({label: "", value: ""});
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState({label: "", value: ""});
  const toastId = useRef(null);
  const [resetData, setResetData] = useState(false);
  const sportOptions = [
                        { value: "Academic Mindset", label: "Academic Mindset" },
                        { value: "Baseball", label: "Baseball" },
                        { value: "Baseball/Softball", label: "Baseball/Softball" },
                        { value: "Basketball", label: "Basketball" },
                        { value: "Bowling", label: "Bowling" },
                        { value: "Corporate Sales", label: "Corporate Sales" },
                        { value: "Field Hockey", label: "Field Hockey" },
                        { value: "Football", label: "Football" },
                        { value: "Golf", label: "Golf" },
                        { value: "Gymnastics", label: "Gymnastics" },
                        { value: "Hockey", label: "Hockey" },
                        { value: "Lacrosse", label: "Lacrosse" },
                        { value: "Martial Arts", label: "Martial Arts" },
                        { value: "Other", label: "Other" },
                        { value: "Soccer", label: "Soccer" },
                        { value: "Swimming", label: "Swimming" },
                        { value: "Tennis", label: "Tennis" },
                        { value: "Track", label: "Track" },
                        { value: "Volleyball", label: "Volleyball" },
                        { value: "Water Skiing", label: "Water Skiing" },
                        { value: "Wrestling", label: "Wrestling" }
                      ]
  const blogTypeOptions = [
                            {value: "Blog", label: "Blog"},
                            {value: "Social Media", label: "Social Media"}
                          ];

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        sport: sport.value,
        blog_date: date,
        blog_name: name,
        topic: type.value
      };
      if (
        sport.value !== "" &&
        date !== "" &&
        name !== "" &&
        type.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setName("");
    setDate("");
    setType({label: "", value: ""});
    setSport({label: "", value: ""});
    toastId.current = toast.info(
      "The blog form has been reset",
      { position: "top-center" }
    );
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_BLOG, data: req_obj }))
      .then((res) => {
        if (res) {
          // console.log("Successfully inserted session data");
          toastId.current = toast.success(
            "You’ve successfully inserted a new blog",
            { position: "top-center" }
          );
          reset_data();
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new blog",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting blog data", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Blog Sport":
        setSport(e);
        break;
      case "Blog Date":
        setDate(e.target.value);
        break;
      case "Blog Name":
        setName(e.target.value);
        break;
      case "Blog Type":
        setType(e);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer onClick={handleClick} title="LOG A BLOG">
        <ReactSelect
          options={sportOptions}
          value={sport}
          placeholder="Blog Sport"
          handleChange={handleChange}
        />
        <Input
          label="Blog Date"
          id="Blog Date"
          type="date"
          value={date}
          disabled={false}
          onChange={(e) => handleChange("Blog Date", e)}
        />
        <TextArea
          label="Blog Name"
          height={'300px'}
          id="Blog Name"
          type="text"
          onChange={(e) => handleChange("Blog Name", e)}
        />
        <ReactSelect
          options={blogTypeOptions}
          value={type}
          placeholder="Blog Type"
          handleChange={handleChange}
        />
      </FormContainer>
    </>
  );
};

export default BlogForm;
