import React, { useEffect, useState } from "react";
import { Table, Button, Row, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../Components/Filters";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { coachDashboardAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const paymentReceivedOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

const CoachMonthlyPayments = (props) => {
  const dispatch = useDispatch();

  // needed for data
  // const coachList_db = useSelector((state) => state.adminReducer.allCoaches);

  // change these out
  //   let expense = useSelector((state) => state.adminReducer.expenseReport);
  let payments = useSelector((state) => state.coachDashboardReducer.coachMonthlyPayments);
  const [paymentData, setPaymentData] = useState();
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);

  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showToggle, setShowToggle] = useState(false);
  const [paymentReceivedSelected, setPaymentReceivedSelected] = useState([
    { value: "No", label: "No" }
  ]);

  // not sure if this is needed
  const [sortingDirection, setSortingDirection] = useState({
    coach_name: "ASC",
    client_name: "",
    parent_name: "",
    payment_date: "",
    video: "",
    inPerson: "",
    clubRuns: "",
    corporateSessions: "",
    blogs: "",
    payment_adjustments: ""
  });

  // or if this is needed
  const sortColumn = (column) => {
    const direction = sortingDirection[column];
    if (direction === "ASC" || direction === "") {
      if (column === "payment_date") {
        const sortedData = [...paymentData].sort(function (a, b) {
          a = a[column].split("/").reverse().join("");
          b = b[column].split("/").reverse().join("");
          return a < b ? 1 : a > b ? -1 : 0;
          // return a.localeCompare(b);         // <-- alternative
        });
        setPaymentData(sortedData);
        setSortingDirection({ ...sortingDirection, [column]: "DESC" });
      } else {
        const sortedData = [...paymentData].sort((a, b) => (a[column] < b[column] ? 1 : -1));
        setPaymentData(sortedData);
        setSortingDirection({ ...sortingDirection, [column]: "DESC" });
      }
    } else if (direction === "DESC") {
      if (column === "payment_date") {
        const sortedData = [...paymentData].sort(function (a, b) {
          a = a[column].split("/").reverse().join("");
          b = b[column].split("/").reverse().join("");
          return a > b ? 1 : a < b ? -1 : 0;
          // return a.localeCompare(b);         // <-- alternative
        });
        setPaymentData(sortedData);
        setSortingDirection({ ...sortingDirection, [column]: "ASC" });
      } else {
        const sortedData = [...paymentData].sort((a, b) => (a[column] > b[column] ? 1 : -1));
        setPaymentData(sortedData);
        setSortingDirection({ ...sortingDirection, [column]: "ASC" });
      }
    }
  };

  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Coach Monthly Payments" } })
    );
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        page_name: "Coach Monthly Payments"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      case "Payment Received":
        if (e.length == 0) {
          setPaymentReceivedSelected([{ value: "No", label: "No" }]);
        } else {
          setPaymentReceivedSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setPaymentData(payments);
  }, [payments]);

  // have to figure out this for date picker
  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(coachDashboardAction.loadCoachMonthlyPayments({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "payment_received") {
          setPaymentReceivedSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(coachDashboardAction.loadCoachMonthlyPayments({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(coachDashboardAction.loadCoachMonthlyPayments({ filters: filters }));
  }, [applyClicked]);

  return (
    <div className="expenses-container">
      <Row className="mt-4">
        <div className="title-row">
          <div className="expenses-title">MONTHLY PAYMENTS</div>
          <Button
            className="filters-button-client"
            onClick={() => setFiltersClicked(!filtersClicked)}>
            FILTERS
          </Button>
        </div>
      </Row>
      {filtersClicked && (
        <Filters
          setFiltersClicked={setFiltersClicked}
          filtersClicked={filtersClicked}
          onClick={handleClick}>
          <DatePicker
            label={"Date Range"}
            startDate={startDate}
            endDate={endDate}
            showToggle={showToggle}
            setShowToggle={setShowToggle}
            type="range">
            <DateRangePicker
              startDatePlaceholder={startDate}
              endDatePlaceholder={endDate}
              ranges={[
                { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
              ]}
              onChange={(e) => handleChange("Date Range", e)}
              showPreview={false}
            />
          </DatePicker>
          <ReactSelect
            options={paymentReceivedOptions}
            value={paymentReceivedSelected}
            isMulti={true}
            placeholder="Payment Received"
            handleChange={handleChange}
          />
        </Filters>
      )}
      <Row className="mt-4">
        <Table striped variant="dark" className="expenses-table" responsive>
          <thead>
            <tr>
              <th onClick={() => sortColumn("coach_name")}>Facilitator</th>
              <th onClick={() => sortColumn("client_name")}>Client Name</th>
              <th onClick={() => sortColumn("parent_name")}>Responsible Person</th>
              <th onClick={() => sortColumn("payment_date")}>Payment Date</th>
              <th onClick={() => sortColumn("month")}>Month</th>
              <th onClick={() => sortColumn("year")}>Year</th>
              <th onClick={() => sortColumn("video")}>Program</th>
              <th onClick={() => sortColumn("inPerson")}>Ind. Commission</th>
              <th onClick={() => sortColumn("clubRuns")}>Team Commission</th>
              <th onClick={() => sortColumn("coporateSessions")}>Payment Received?</th>
              <th onClick={() => sortColumn("blogs")}>Comments</th>
            </tr>
          </thead>
          <tbody>
            {paymentData &&
              paymentData.map((payment) => {
                return (
                  <tr key={`{${payment.sale_id}${payment.month_numeric}${payment.year}`}>
                    <td>{payment.sales_rep}</td>
                    <td>{payment.client_name}</td>
                    <td>{payment.parent_name}</td>
                    <td>{payment.payment_date}</td>
                    <td>{payment.month_name}</td>
                    <td>{payment.year}</td>
                    <td>{payment.program}</td>
                    <td>${payment.ind_commission}</td>
                    <td>${payment.team_commission}</td>
                    <td>{payment.payment_received}</td>
                    <td>{payment.payment_comments}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {paymentData && paymentData.length === 0 && (
          <div className="data-title">No Data Available</div>
        )}
      </Row>
    </div>
  );
};

export default CoachMonthlyPayments;
