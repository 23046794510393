import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import OutsideAlerter from "../OutsideAlerter";

const DatePicker = ({ startDate, endDate, showToggle, setShowToggle, children, type, date, title='DATE' }) => {
  return (
    <Form.Group
      className="mt-3 react-select"
      style={{ backgroundColor: "#363636", height: "3rem", borderRadius: "5px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
        }}>
        {type == "range" ? (
          <p style={{ color: "white", marginBottom: 0, marginLeft: "1rem" }}>
            {startDate} - {endDate}
          </p>
        ) : (
          <p style={{ color: "white", marginBottom: 0, marginLeft: "1rem" }}>{date}</p>
        )}

        <Button onClick={() => setShowToggle(!showToggle)} style={{ marginRight: "1rem" }}>
          {type == "range" ? "EDIT DATES" : `INPUT ${title}`}
        </Button>
      </div>
      <div
        style={{
          display: showToggle ? "block" : "none",
          zIndex: 1000,
          position: "relative",
          width: "350px"
        }}>
        <OutsideAlerter setShowToggle={setShowToggle} showToggle={showToggle}>
          {children}
        </OutsideAlerter>
      </div>
    </Form.Group>
  );
};

export default DatePicker;
