import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion, Image } from "react-bootstrap";
import Logo from "../assets/wm-logo.jpg";
import { X } from "react-bootstrap-icons";
import AdminSidebar from "./AdminSidebar";
import { authAction } from "../Redux/actions";

const Sidebar = ({ open, click, setOpen }) => {
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const [adminClick, setAdminClick] = useState(false);
  const [admin, setAdmin] = useState(false);
  const userInfo = useSelector((state) => state.authReducer.persona);

  const logout = async () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login`
    });
  };

  useEffect(() => {
    //dispatch(coachDashboardAction.resetData())
    if (authState && authState.isAuthenticated) {
      dispatch(authAction.loadPersonaData({ filters: {} }));
    }
  }, [dispatch, authState]);

  useEffect(() => {
    if (userInfo) {
      setAdmin(userInfo.is_admin);
    }
  }, [userInfo]);

  const handleAdminClick = () => {
    setAdminClick(!adminClick);
  };
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="sidebar" style={{ display: open ? "" : "none" }}>
      <X className="close" size={40} onClick={click} />
      <Image src={Logo} className="wm-logo" />
      <p>CLIENT HUB</p>
      <ul className="nav-container">
        <Link to="/" className="link" onClick={handleOpen}>
          <li>DASHBOARD</li>
        </Link>
        <Link to="/clients" className="link" onClick={handleOpen}>
          <li>CLIENTS</li>
        </Link>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={1}>
            <Accordion.Header>SESSIONS</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/sessions" className="link" onClick={handleOpen}>
                <li>MY SESSIONS</li>
              </Link>
              <hr/>
              <Link to="/sessions_by_client" className="link" onClick={handleOpen}>
                <li>SESSIONS BY CLIENT</li>
              </Link>
              <hr/>
              <Link to="/events" className="link" onClick={handleOpen}>
                <li>EVENTS/LOG AN EVENT</li>
              </Link>
              <hr/>
              <Link to="/reimbursements" className="link" onClick={handleOpen}>
                <li>REIMBURSEMENTS</li>
              </Link>
              <hr/>
              <Link to="/missed_sessions" className="link" onClick={handleOpen}>
                <li>MISSED SESSIONS</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={2}>
            <Accordion.Header>PAYMENTS</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/sales" className="link" onClick={handleOpen}>
                <li>MY SALES</li>
              </Link>
              <hr/>
              <Link to="/sales_hours_list" className="link" onClick={handleOpen}>
                <li>SALES HOURS LOGGED</li>
              </Link>
              <hr/>
              <Link to="/payment" className="link" onClick={handleOpen}>
                <li>MY PAYMENTS</li>
              </Link>
              <hr/>
              <Link to="/monthly_payments" className="link" onClick={handleOpen}>
                <li>MONTHLY PAYMENT BREAKDOWN</li>
              </Link>
              <hr/>
              <Link to="/my_expiring_subscriptions" className="link" onClick={handleOpen}>
                <li>EXPIRING PAID IN FULLS</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={3}>
            <Accordion.Header>COACH</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link
                to={{
                  pathname: "/coach_detail",
                  state: { coach_id: userInfo.coach_id, admin: false }
                }}
                className="link"
                onClick={handleOpen}>
                <li>MY PROFILE</li>
              </Link>
              <hr/>
              <Link to="/blogs" className="link" onClick={handleOpen}>
                <li>MY BLOGS</li>
              </Link>
              <hr/>
              <Link to="/miscellaneous_time_list" className="link" onClick={handleOpen}>
                <li>MY MISCELLANEOUS TIME</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Link to="/resources" className="link" onClick={handleOpen}>
          <li>RESOURCES</li>
        </Link>
        {admin === "1" && (
          <div className="sidebar-accordion" onClick={handleAdminClick}>
            <li>ADMIN</li>
          </div>
        )}
        {admin && (
          <AdminSidebar
            adminClick={adminClick}
            parentClick={click}
            handleClick={handleAdminClick}
            click={click}
            setOpen={setOpen}
            open={open}
            setAdminClick={setAdminClick}
          />
        )}
        <div className="sidebar-accordion">
          <li onClick={logout}>LOGOUT</li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
