const initialState = {
  clubRunList: [],
  clubRunDetail: []
};

export const clubRunReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_CLUB_RUN_LIST':
      return {
        ...state,
        clubRunList: payload
      }
    case 'LOAD_CLUB_RUN_DETAIL':
      return {
        ...state,
        clubRunDetail: payload
      }
    default:
      return state
  }
};
