const initialState = {
  allClients: [],
  allClientAssignments: [],
  allClientAssignmentsGrouped: [],
  allSales: {},
  allSessions: {},
  allCoaches: [],
  filteredCoaches: [],
  allSalesReps: {},
  allRpPhoneNumbers: {},
  allRpEmails: {},
  allRpNames: {},
  calculatedCommission: 0,
  adminClientDetail: [],
  adminSessionsByCoach: [],
  adminSalesbyCoach: [],
  adminExpiringSubscriptions: [],
  adminSessionsByClient: [],
  adminClubRunsByCoach: [],
  adminClubRunDetail: [],
  adminSessionDetail: [],
  adminSaleDetail: [],
  expenseReport: [],
  adminSalesByClient: [],
  adminClientsByCoach: [],
  adminClientsByState: [],
  adminCoachDetail: [],
  adminPaymentAdjustmentList: [],
  adminPaymentAdjustmentDetail: [],
  adminSessionInactivity: [],
  adminPayrollBreakdown: [],
  adminMonthlyPayments: [],
  adminSalesHours: [],
  adminReimbursements: [],
  adminUnloggedSessions: [],
  adminMissedSessionsByCoach: [],
  adminAssignmentInsights: [],
  adminAssignmentsByLength: [],
  adminMiscellaneousTime: [],
  adminAllCoaches: [],
  federalMileageRates: []
};

export const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOAD_ALL_CLIENTS":
      return {
        ...state,
        allClients: payload
      };
    case "LOAD_ADMIN_PAYROLL_BREAKDOWN":
      return {
        ...state,
        adminPayrollBreakdown: payload
      };
    case "LOAD_ALL_COACHES":
      return {
        ...state,
        allCoaches: payload
      };
    case "LOAD_ADMIN_ALL_COACHES":
      return {
        ...state,
        adminAllCoaches: payload
      };
    case "LOAD_FILTERED_COACHES":
      return {
        ...state,
        filteredCoaches: payload
      };
    case "LOAD_ALL_SALES_REPS":
      return {
        ...state,
        allSalesReps: payload
      };
    case "LOAD_ALL_RP_PHONE_NUMBERS":
      return {
        ...state,
        allRpPhoneNumbers: payload
      };
    case "LOAD_ALL_RP_EMAILS":
      return {
        ...state,
        allRpEmails: payload
      };
    case "LOAD_ALL_RP_NAMES":
      return {
        ...state,
        allRpNames: payload
      };
    case "LOAD_CALCULATED_COMMISSION":
      return {
        ...state,
        calculatedCommission: payload
      };
    case "LOAD_ALL_CLIENT_ASSIGNMENTS":
      return {
        ...state,
        allClientAssignments: payload
      };
    case "LOAD_ALL_CLIENT_ASSIGNMENTS_GROUPED":
      return {
        ...state,
        allClientAssignmentsGrouped: payload
      };
    case "LOAD_ADMIN_CLIENT_DETAIL":
      return {
        ...state,
        adminClientDetail: payload
      };
    case "LOAD_ADMIN_SESSIONS_BY_COACH":
      return {
        ...state,
        adminSessionsByCoach: payload
      };
    case "LOAD_ADMIN_SESSIONS_BY_CLIENT":
      return {
        ...state,
        adminSessionsByClient: payload
      };
    case "LOAD_ADMIN_CLIENTS_BY_COACH":
      return {
        ...state,
        adminClientsByCoach: payload
      };
    case "LOAD_ADMIN_CLIENTS_BY_STATE":
      return {
        ...state,
        adminClientsByState: payload
      };
    case "LOAD_ADMIN_SALES_BY_COACH":
      return {
        ...state,
        adminSalesByCoach: payload
      };
    case "LOAD_ADMIN_EXPIRING_SUBSCRIPTIONS":
      return {
        ...state,
        adminExpiringSubscriptions: payload
      };
    case "LOAD_ADMIN_CLUB_RUNS_BY_COACH":
      return {
        ...state,
        adminClubRunsByCoach: payload
      };
    case "LOAD_ADMIN_SALES_BY_CLIENT":
      return {
        ...state,
        adminSalesByClient: payload
      };
    case "LOAD_ADMIN_CLUB_RUN_DETAIL":
      return {
        ...state,
        adminClubRunDetail: payload
      };
    case "LOAD_ADMIN_SESSION_DETAIL":
      return {
        ...state,
        adminSessionDetail: payload
      };
    case "LOAD_ADMIN_COACH_DETAIL":
      return {
        ...state,
        adminCoachDetail: payload
      };
    case "LOAD_ADMIN_SALE_DETAIL":
      return {
        ...state,
        adminSaleDetail: payload
      };
    case "LOAD_EXPENSE_REPORT":
      return {
        ...state,
        expenseReport: payload
      };
    case "LOAD_ADMIN_PAYMENT_ADJUSTMENT_LIST":
      return {
        ...state,
        adminPaymentAdjustmentList: payload
      };
    case "LOAD_ADMIN_PAYMENT_ADJUSTMENT_DETAIL":
      return {
        ...state,
        adminPaymentAdjustmentDetail: payload
      };
    case "LOAD_ADMIN_SESSION_INACTIVITY":
      return {
        ...state,
        adminSessionInactivity: payload
      };
    case "LOAD_ADMIN_MONTHLY_PAYMENTS":
      return {
        ...state,
        adminMonthlyPayments: payload
      };
    case "LOAD_ADMIN_SALES_HOURS_LIST":
      return {
        ...state,
        adminSalesHours: payload
      }
    case "LOAD_ADMIN_MISCELLANEOUS_TIME_LIST":
      return {
        ...state,
        adminMiscellaneousTime: payload
      }
    case "LOAD_ADMIN_REIMBURSEMENTS":
      return {
        ...state,
        adminReimbursements: payload
      }
    case "LOAD_ADMIN_UNLOGGED_SESSIONS":
      return {
        ...state,
        adminUnloggedSessions: payload
      }
    case "LOAD_ADMIN_MISSED_SESSIONS_BY_COACH":
      return {
        ...state,
        adminMissedSessionsByCoach: payload
      }
    case "LOAD_ADMIN_ASSIGNMENT_INSIGHTS":
      return {
        ...state,
        adminAssignmentInsights: payload
      }
    case "LOAD_ADMIN_ASSIGNMENTS_BY_LENGTH":
      return {
        ...state,
        adminAssignmentsByLength: payload
      }
    case "LOAD_FEDERAL_MILEAGE_RATES":
      return {
        ...state,
        federalMileageRates: payload
      }
    default:
      return state;
  }
};
