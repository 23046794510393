const initialState = {
  persona: {}
}

export const authReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_PERSONA':
      return {
        ...state,
        persona: payload
      }
      default:
        return state
  }
}
