import React, { useState, useRef  } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Container, Row } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import API_CONSTANTS from "../../Redux/constants";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import { sportOptions, eventTypeList } from "../../Utils/selectConstants";

const EventForm = (props) => {
  const [sport, setSport] = useState({label: "", value: ""});
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [notes, setNotes] = useState("");
  const [eventType, setEventType] = useState({value: "", label: ""});
  const [tournament, setTournament] = useState("");
  const [hoursWorked, setHoursWorked] = useState(0);
  const [signups, setSignups] = useState();
  const toastId = useRef(null);
  const [resetData, setResetData] = useState(false);

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        sport: sport.value,
        club_run_date: date,
        club_name: name,
        topic: topic,
        notes: notes,
        sign_ups: signups,
        event_type: eventType.value,
        hours_worked: hoursWorked,
        tournament: tournament
      };
      if (
        sport.value !== "" &&
        date !== "" &&
        name !== "" &&
        notes !== "" &&
        signups !== "" && 
        eventType.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setName("");
    setDate("");
    setTopic("");
    setNotes("");
    setSport({label: "", value: ""});
    setEventType({label: "", value: ""});
    setSignups("");
    setHoursWorked(0);
    setTournament("");
    toastId.current = toast.info(
      "The club run form has been reset",
      { position: "top-center" }
    );
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_CLUB_RUN, data: req_obj }))
      .then((res) => {
        if (res) {
          // console.log("Successfully inserted session data");
          toastId.current = toast.success(
            "You’ve successfully inserted a new event",
            { position: "top-center" }
          );
          reset_data();
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new event",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting event data", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Sport":
        setSport(e);
        break;
      case "Event Type":
        setEventType(e);
        break;
      case "Event Date":
        setDate(e.target.value);
        break;
      case "Event Name":
        setName(e.target.value);
        break;
      case "Topic":
        setTopic(e.target.value);
        break;
      case "Notes":
        setNotes(e.target.value);
        break;
      case "Number of Signups":
        setSignups(e.target.value);
        break;
      case "Tournament":
        setTournament(e.target.value);
        break;
      case "Hours worked":
        setHoursWorked(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer onClick={handleClick} title="LOG AN EVENT">
        <ReactSelect
          options={eventTypeList}
          value={eventType}
          placeholder="Event Type"
          handleChange={handleChange}
        />
        <Input
          label="Event Name"
          id="Event Name"
          type="text"
          value={name}
          onChange={(e) => handleChange("Event Name", e)}
        />
        {eventType.value === 'Tournament' &&
        <>
          <Input
            label="Tournament"
            id="Tournament"
            type="text"
            value={tournament}
            disabled={false}
            onChange={(e) => handleChange("Tournament", e)}
          />
          <Input
            label="Hours worked"
            id="Hours worked"
            type="number"
            value={hoursWorked}
            onChange={(e) => handleChange("Hours worked", e)}
          />
        </>
        }
        <ReactSelect
          options={sportOptions}
          value={sport}
          placeholder="Sport"
          handleChange={handleChange}
        />
        <Input
          label="Event Date"
          id="Event Date"
          type="date"
          value={date}
          disabled={false}
          onChange={(e) => handleChange("Event Date", e)}
        />
        {eventType.value !== 'Tournament' && <Input
          label="Topic"
          id="Topic"
          type="text"
          value={topic}
          onChange={(e) => handleChange("Topic", e)}
        />
        }
        <TextArea
          height={'300px'}
          label="Notes"
          id="Notes"
          type="text"
          value={notes}
          onChange={(e) => handleChange("Notes", e)}
        />
        <Input
          label="Number of Signups"
          id="Number of Signups"
          type="number"
          value={signups}
          onChange={(e) => handleChange("Number of Signups", e)}
        />
      </FormContainer>
    </>
  );
};

export default EventForm;
