import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk";

import appReducer from "./reducers";

const middlewares = [thunk];

const devTools =
  process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_
    ?  window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

export const store = createStore (
  appReducer,
  compose(applyMiddleware(...middlewares), devTools)
)
