import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { Button } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction } from "../../Redux/actions";
import { saleAction, adminAction, filterAction } from "../../Redux/actions";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { 
  clientTypeList,
  commissionOptions,
  intitialOrRenewalOptions,
  originsOfSale,
  paymentSourceList,
  sportOptions,
  takeOrPassOptions,
} from "../../Utils/selectConstants"

const AdminClientAssignmentDetailForm = ({assignmentDetail, clicked, setClicked, refresh, handleDetailEditedChange}) => {

  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [clientName, setClientName] = useState(
    {
        value: assignmentDetail.client_name + ' (' + assignmentDetail.client_id + ')',
        label: assignmentDetail.client_name + ' (' + assignmentDetail.client_id + ')'
    }
  );

  const [coachName, setCoachName] = useState(
    {
        value: assignmentDetail.coach_name + ' (' + assignmentDetail.coach_id + ')',
        label: assignmentDetail.coach_name + ' (' + assignmentDetail.coach_id + ')'
    }
  );

  const [clientType, setClientType] = useState(
    {
        value: assignmentDetail.assignment_type,
        label: assignmentDetail.assignment_type
    }
  );
  const [programName, setProgramName] = useState(
    {
        value: assignmentDetail.assignment_program.includes("Custom") ? assignmentDetail.assignment_program : assignmentDetail.assignment_program + ' (' + assignmentDetail.assignment_program_id + ')',
        label: assignmentDetail.assignment_program.includes("Custom") ? assignmentDetail.assignment_program : assignmentDetail.assignment_program + ' (' + assignmentDetail.assignment_program_id + ')'
    }
  );
  const [curriculum, setCurriculum] = useState(assignmentDetail.curriculum);
  const [assignmentStartDate, setAssignmentStartDate] = useState(assignmentDetail.assignment_start_date);
  const [assignmentEndDate, setAssignmentEndDate] = useState(assignmentDetail.assignment_end_date);
  const [lengthOnProgram, setLengthOnProgram] = useState(assignmentDetail.length_on_program);
  const [clientList, setClientList] = useState([]);
  const [coachList, setCoachList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const clientList_db = useSelector((state) => state.adminReducer.allClients);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const programList_db = useSelector((state) => state.filterReducer.programList);
  const [resetData, setResetData] = useState(false);

  useEffect(() => {
    dispatch(adminAction.loadAllClients({ filters: {"is_active": ["Active"], "clientType": [clientType.value]} }));
    dispatch(adminAction.loadAllCoaches({ filters: {} }));
    dispatch(filterAction.loadProgramList({ filters: {} }));
  }, [dispatch, clientType]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    let p_list_db = programList_db.map((program) => {
      return {
        value: `${program.program_name} (${program.program_id})`,
        label: `${program.program_name} (${program.program_id})`
      }
    });
    p_list_db.push({value: "Custom Monthly", label: "Custom Monthly"});
    p_list_db.push({value: "Custom PIF", label: "Custom PIF"});
    p_list_db.push({value: "", label: ""});
    setProgramList(p_list_db);
  }, [programList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e);
        break;
      case "Client Type":
        setClientType(e);
        break;
      case "Coach Name":
        setCoachName(e);
        break;
      case "Program Name":
        setProgramName(e);
        break;
      case "Curriculum":
        setCurriculum(e.target.value);
        break;
      case "Assignment Start Date":
        setAssignmentStartDate(e.target.value);
        break;
      case "Assignment End Date":
        setAssignmentEndDate(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
      const req_obj = {
        assignment_id: assignmentDetail.assignment_id,
        client_name: clientName.value,
        client_type: clientType.value,
        assignment_program: programName.value,
        coach_name: coachName.value,
        assignment_start_date: assignmentStartDate,
        assignment_end_date: assignmentEndDate,
        curriculum: curriculum
      };
      if (
        clientType.value !== "" &&
        clientName.value !== "" &&
        coachName.value !== "" &&
        programName.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all required form fields", { position: "top-center" });
      }
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_ASSIGNMENT_DETAIL, data: req_obj }))
      .then((res) => {
        if (res) {
          toastId.current = toast.success(
            "You’ve successfully updated the assignment for " + req_obj["client_name"],
            { position: "top-center" }
          );
          handleDetailEditedChange()
        } else {
          toastId.current = toast.error(
            "There was an error updating the assignment for " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error updating the assignment", {
          position: "top-center"
        });
      });
  };

  return (
    <>
    <ToastContainer limit={1} theme="dark" />
    <div className="mt-2">
      <div className="labels mt-3">Assignment Detail Form</div>
      <ReactSelect
        options={clientList}
        value={clientName}
        label="Client Name"
        placeholder={"Client Name"}
        id={"Client Name"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientTypeList}
        value={clientType}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={programList}
        value={programName}
        label="Program Name"
        placeholder={"Program Name"}
        id={"Program Name"}
        handleChange={handleChange}
      />
      <Input
        type="text"
        label="Curriculum"
        value={curriculum}
        onChange={(e) => handleChange("Curriculum", e)}
      />
      <ReactSelect
        options={coachList}
        value={coachName}
        label="Coach Name"
        placeholder={"Coach Name"}
        id={"Coach Name"}
        handleChange={handleChange}
      />
      <Input
        type="date"
        label="Assignment Start Date"
        disabled={false}
        value={assignmentStartDate}
        onChange={(e) => handleChange("Assignment Start Date", e)}
      />
      <Input
        type="date"
        label="Assignment End Date"
        disabled={false}
        value={assignmentEndDate}
        onChange={(e) => handleChange("Assignment End Date", e)}
      />
      <Input
        type="number"
        label="Length on program (computed)"
        disabled={true}
        value={lengthOnProgram}
        onChange={(e) => handleChange("Length on program (computed)", e)}
      />
      <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
    </div>
    </>
  );
};

export default AdminClientAssignmentDetailForm;
