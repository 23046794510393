const setLoader = data => ({
  type: 'SET_LOADER',
  payload: data
})

const resetLoader = data => ({
  type: 'RESET_LOADER',
  payload: data
})

export const loaderAction = {
  setLoader,
  resetLoader
}
