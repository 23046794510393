import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button, Row, Col } from "react-bootstrap";
import FormContainer from "../../Components/Forms/FormContainer";
import TextArea from "../../Components/Forms/TextArea";
import { adminAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "../../Components/Forms/DatePicker";
import { reimbursementTypeList } from "../../Utils/selectConstants";
import { Calendar } from "react-date-range";

const ReimbursementDetailForm = ({reimbursementDetail, clicked, setClicked, refresh, handleDetailEditedChange}) => {
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const userInfo = useSelector((state) => state.authReducer.persona);
  const [coachName, setCoachName] = useState(
    {
      value: reimbursementDetail.coach_name + ' (' + reimbursementDetail.coach_id + ')',
      label: reimbursementDetail.coach_name + ' (' + reimbursementDetail.coach_id + ')'
    }
  );
  const [reimbursementDate, setReimbursementDate] = useState(reimbursementDetail.reimbursement_date);
  const [reimbursementType, setReimbursementType] = useState({value: reimbursementDetail.reimbursement_type, label: reimbursementDetail.reimbursement_type});
  const [reimbursementAmount, setReimbursementAmount] = useState(reimbursementDetail.reimbursement_price);
  const [federalMileageRate, setFederalMileageRate] = useState(65.5);
  const [travelMileage, setTravelMileage] = useState(reimbursementDetail.travel_mileage);
  const [travelMileageComputed, setTravelMileageComputed] = useState(true);
  const [eventName, setEventName] = useState(reimbursementDetail.event_name);
  const [coachList, setCoachList] = useState([]);
  const [notes, setNotes] = useState(reimbursementDetail.reimbursement_notes);
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [resetData, setResetData] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let c_list_db = coachList_db?.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      c_list_db.push({ value: "", label: "" });
      setCoachList(c_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Coach Name":
        setCoachName(e);
        break;
      case "Reimbursement Type":
        setReimbursementType(e);
        if (e.value !== 'Travel Mileage') {
          setTravelMileageComputed(false);
        }
        break;
      case "Event Name":
        setEventName(e.target.value);
        break;
      case "Travel Mileage":
        setTravelMileage(e.target.value);
        setTravelMileageComputed(true);
        if (reimbursementType.value === 'Travel Mileage') {
          setReimbursementAmount(travelMileage * federalMileageRate);
        }
        break;
      case "Reimbursement Amount":
        setReimbursementAmount(e.target.value);
        break;
      case "Notes":
        setNotes(e.target.value);
        break;
    }
  };

  const handleClick = (e) => {
      const req_obj = {
        reimbursement_id: reimbursementDetail.id,
        coach_name: coachName !== "" ? coachName.value : "",
        reimbursement_date: reimbursementDate,
        event_name: eventName,
        reimbursement_type: reimbursementType.value,
        travel_mileage: travelMileage,
        reimbursement_notes: notes,
        reimbursement_price: reimbursementAmount,
        notes: notes
      };
      if (
        reimbursementDate !== "" &&
        eventName !== "" &&
        reimbursementAmount !== ""
      ) {
        update_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }

  };

  const reset_data = () => {
    setCoachName("");
    setReimbursementDate(new Date().toLocaleDateString());
    setTravelMileage(0);
    setReimbursementAmount(0);
    setTravelMileageComputed(false);
    setReimbursementType("");
    setNotes("");
  };

  const update_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_REIMBURSEMENT, data: req_obj }))
      .then((res) => {
        if (res.status === 200) {
          toastId.current = toast.success(
            "You’ve successfully updated a reimbursement",
            { position: "top-center" }
          );
        } else {
          toastId.current = toast.error(
            "There was an error updating a reimbursement",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error updating reimbursement data", {
          position: "top-center"
        });
      });
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setReimbursementDate(selectedDate.toLocaleDateString());
  };

  return (
    <div>
        {(userInfo && userInfo.is_admin === "1") && <ReactSelect
            options={coachList}
            value={coachName}
            label="Coach Name"
            placeholder={"Coach Name"}
            id={"Coach Name"}
            handleChange={handleChange}
          />
        }
        <DatePicker
          date={reimbursementDate}
          showToggle={showToggle}
          setShowToggle={setShowToggle}
          label={"Reimbursement Date"}>
          <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
        </DatePicker>
        <Input
          value={eventName}
          label="Event Name"
          id={"Event Name"}
          onChange={(e) => handleChange("Event Name", e)}
        />
        <ReactSelect
          options={reimbursementTypeList}
          value={reimbursementType}
          placeholder="Reimbursement Type"
          handleChange={handleChange}
        />
        {reimbursementType.value === 'Travel Mileage' && 
          <div>
            <Input
              value={travelMileage}
              label="Travel Mileage"
              id={"Travel Mileage"}
              onChange={(e) => handleChange("Travel Mileage", e)}
            />
            <Input
                      type="number"
                      disabled={true}
                      value={federalMileageRate}
                      label="federalMileageRate"
                      id={"federalMileageRate"}
                      onChange={(e) => handleChange("federalMileageRate", e)}
            />
          </div>
        }
        <TextArea label="Notes" height={'300px'} onChange={(e) => handleChange("Notes", e)} value={notes} />
        <Input
          type="number"
          disabled={travelMileageComputed}
          value={reimbursementAmount}
          label="Reimbursement Amount"
          id={"Reimbursement Amount"}
          onChange={(e) => handleChange("Reimbursement Amount", e)}
        />
        <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
          SAVE
        </Button>
    </div>
  );
};

export default ReimbursementDetailForm;
