import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { months, years } from "../../Utils/arrayItems";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";

const AdminSalesByCoach = () => {
  const dispatch = useDispatch();
  const coachSales = useSelector((state) => state.adminReducer.adminSalesByCoach);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [coachesSelected, setCoachesSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [applyClicked, setApplyClicked] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Sales by Coach" } }));
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
  }, []);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Coach":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        coaches: coaches_arr,
        page_name: "Sales by Coach"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminSalesByCoach({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "coaches") {
          setCoachesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminSalesByCoach({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminSalesByCoach({ filters: filters }));
  }, [applyClicked]);

  const exportData = Array.isArray(coachSales) ? coachSales.map((v) => v.breakdown).flat() : [];

  useEffect(() => {
    if (coachSales) {
      let numberOfSales = coachSales.length;
      let totalSum = coachSales.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.commission > 0);
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let monthlySum = coachSales.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.monthly_or_pif === "Monthly");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let pifSum = coachSales.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.monthly_or_pif === "PIF");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let initialSum = coachSales.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter(
          (item) => item.initial_sale_or_renewal === "Initial Sale"
        );
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let renewalSum = coachSales.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter(
          (item) => item.initial_sale_or_renewal === "Renewal"
        );
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      setSummaryData({
        headers: ["Number of Sales", "Total Revenue", "Monthly", "PIF", "Initial", "Renewal"],
        data: [
          numberOfSales,
          Math.round(totalSum),
          Math.round(monthlySum),
          Math.round(pifSum),
          Math.round(initialSum),
          Math.round(renewalSum)
        ]
      });
    }
  }, [coachSales]);

  return (
    <AccordionContainer
      values={coachSales}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="SALES BY COACH"
      type="SALES"
      headers={["client_name", "payment_date", "program_name", "commission"]}
      row_id="sale_id"
      pathName={"sale_detail"}
      admin={true}
      handleClick={handleClick}
      exportButton={true}
      exportData={exportData}
      summaryData={summaryData}>
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Coach"
        handleChange={handleChange}
      />
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
    </AccordionContainer>
  );
};

export default AdminSalesByCoach;
