import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { Button } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction } from "../../Redux/actions";
import { sessionAction } from "../../Redux/actions";
import getTopicOptions from "../../Utils/getTopicOptions";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {clientTypeList, sessionTypeList, workshopNumberList} from "../../Utils/selectConstants";

const MissedSessionDetailForm = ({missedSessionDetail, clicked, setClicked, refresh, handleDetailEditedChange}) => {
  const dispatch = useDispatch();
  const clientList_db = useSelector(state => state.clientReducer.clientList);
  const missedSessionId = useState(missedSessionDetail.missed_session_id)
  const [clientId, setClientId] = useState(missedSessionDetail.client_id)
  const [clientName, setClientName] = useState(
    {
        value: missedSessionDetail.client_name + ' (' + missedSessionDetail.client_id + ')',
        label: missedSessionDetail.client_name + ' (' + missedSessionDetail.client_id + ')'
    }
  );
  const [sessionDate, setSessionDate] = useState(missedSessionDetail.session_date);
  const [clientType, setClientType] = useState({value: missedSessionDetail.client_type, label: missedSessionDetail.client_type});
  const toastId = useRef(null);
  const [notes, setNotes] = useState(missedSessionDetail.notes);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    dispatch(clientAction.loadClientListData({filters: {is_active: ["Active"]}}));
  }, [dispatch]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  const onSelectChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e)
        setClientId(e.value)
        break;
      case "Client Type":
        setClientType(e)
        break;
      case "Date":
        setSessionDate(e.target.value)
        break
      case "Notes/Reason for Missing Session":
        setNotes(e.target.value)
        break
      default:
        break;
    }
  }

  const handleClick = (e) => {
      const req_obj = {
        client_id: clientId,
        client_name: clientName.value,
        session_date: sessionDate,
        client_type: clientType.value,
        notes: notes,
        missed_session_id: missedSessionId
      };
      if (
        clientName.value !== "" &&
        sessionDate !== "" &&
        clientType.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
  };

  const reset_data = () => {
    setClientName("");
    setSessionDate("");
    setClientType("");
    setNotes("");
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_MISSED_SESSION_DETAIL, data: req_obj }))
      .then((res) => {
        if (res) {
          // console.log("Successfully inserted session data");
          toastId.current = toast.success(
            "You’ve successfully updated the missed session with " + req_obj["client_name"],
            { position: "top-center" }
          );
          reset_data();
          handleDetailEditedChange()
        } else {
          toastId.current = toast.error(
            "There was an error updating the missed session with " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error updating the missed session data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
    <ToastContainer limit={1} theme="dark" />
    <div className="mt-2">
      <div className="labels mt-3">Client</div>
      <ReactSelect
        options={clientList}
        handleChange={onSelectChange}
        value={clientName}
        placeholder="Client Name"
      />
      <div className="labels mt-3">Date</div>
      <Input
        type="date"
        label="Date"
        disabled={false}
        value={sessionDate}
        onChange={(e) => onSelectChange("Date", e)}
      />
      <div className="labels mt-3">Client Type</div>
      <ReactSelect
        options={clientTypeList}
        value={clientType}
        placeholder="Client Type"
        handleChange={onSelectChange}
      />
      <div className="labels mt-3">Notes:</div>
      <TextArea
        id={"notes"}
        height={'300px'}
        onChange={(e) => onSelectChange("Notes/Reason for Missing Session", e)}
        value={notes}
      />
      <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
    </div>
    </>
  );
};

export default MissedSessionDetailForm;
