import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { adminAction, sessionAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { insert_data } from "../../Utils/insertData";
import { months, years } from "../../Utils/arrayItems";
import { clientStatuses } from '../../Utils/selectConstants';

const SessionCommissionList = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.persona);
  const sessionCommissionList_db = useSelector(
    (state) => state.sessionReducer.sessionCommissionList
  );
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);
  const [coachesSelected, setCoachesSelected] = useState([]);
  const [statusesSelected, setStatusesSelected] = useState([{ value: "Active", label: "Active" }]);
  const [coachList, setCoachList] = useState({ value: "", label: "" });

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: [statusesSelected[0].value] } }));
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Session Commission Management" } })
    );
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let status_arr = statusesSelected.map((data) => data.value);
      let filtersObj = {
        coaches: coaches_arr,
        is_active: status_arr,
        page_name: "Session Commission Management"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(sessionAction.loadSessionCommissionListData({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(sessionAction.loadSessionCommissionListData({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Coaches":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusesSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: [statusesSelected[0].value] } }));
  }, [statusesSelected]);

  useEffect(() => {
    dispatch(sessionAction.loadSessionCommissionListData({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={sessionCommissionList_db}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="SESSION COMMISSION MANAGEMENT"
      type="SESSION COMMISSIONS"
      headers={["individual", "team_video", "team_in_person", "corporate", "club_run", "session_commission_start_date", "session_commission_end_date"]}
      row_id="session_commission_id"
      linkName="session_commission"
      pathName={"session_commission_detail"}
      buttonTitle="ADD A COMMISSION RATE"
      admin={userInfo.is_admin === "1"}
      handleClick={handleClick}>
      <ReactSelect
        options={clientStatuses}
        value={statusesSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
        id={1}
      />
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Coaches"
        handleChange={handleChange}
        id={2}
      />
    </AccordionContainer>
  );
};

export default SessionCommissionList;
