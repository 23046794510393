const initialState = {filterData: {}, savedUserFilters: {}, programList: []}

export const filterReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_FILTER_DATA':
      return {
        ...state,
        filterData: payload
      }
    case 'LOAD_SAVED_USER_FILTERS':
      return {
        ...state,
        savedUserFilters: payload
      }
    case 'LOAD_PROGRAM_LIST':
      return {
        ...state,
        programList: payload
      }
    default:
      return state
  }
}
