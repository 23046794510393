import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import Input from "../../Components/Forms/Input";
import { clientAction, sessionAction, adminAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import ReactSelect from "../../Components/Forms/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "../../Components/Forms/DatePicker";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import { Calendar } from "react-date-range";

const ProgramForm = (props) => {
  const [programName, setProgramName] = useState("");
  const [clientType, setClientType] = useState("");
  const [programPrice, setProgramPrice] = useState(0);
  const [programStart, setProgramStart] = useState(new Date().toLocaleDateString());
  const [programEnd, setProgramEnd] = useState(new Date().toLocaleDateString());
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [resetData, setResetData] = useState(false);
  const [showToggle1, setShowToggle1] = useState(false);
  const [showToggle2, setShowToggle2] = useState(false);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Program Name":
        setProgramName(e.target.value);
        break;
      case "Program Price":
        setProgramPrice(e.target.value);
        break;
      case "Client Type":
        setClientType(e);
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        program_name: programName,
        program_price: programPrice ?? 0,
        client_type: clientType.value,
      };
      if (
        programName.value !== "" &&
        clientType.value !== "" &&
        programStart !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setProgramName("");
    setProgramPrice(0);
    setProgramStart(new Date().toLocaleDateString());
    setProgramEnd(new Date().toLocaleDateString());
    setClientType("");
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.ADD_PROGRAM, data: req_obj }))
      .then((res) => {
        if (res.data.status === "Success") {
          toastId.current = toast.success(
            "You’ve successfully inserted a new program for " + req_obj["program_name"],
            { position: "top-center" }
          );
          reset_data();
        } else if (res.data.status === "Duplicate") {
          toastId.current = toast.error(
            "You’ve already inserted a session for " + req_obj["program_name"],
            { position: "top-center" }
          );
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new program for " + req_obj["program_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting program data", {
          position: "top-center"
        });
      });
  };

  const handleDateChange1 = (e) => {
    const selectedDate = new Date(e);
    setProgramStart(selectedDate.toLocaleDateString());
  };

  const handleDateChange2 = (e) => {
    const selectedDate = new Date(e);
    setProgramEnd(selectedDate.toLocaleDateString());
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
        <FormContainer title="ADD A PROGRAM" onClick={handleClick} style={{'height': '600px'}}>
            <Input
                value={programName}
                label="Program Name"
                id={"Program Name"}
                onChange={(e) => handleChange("Program Name", e)}
            />
            <ReactSelect
            options={clientTypeList.slice(0, -1)}
            value={clientType}
            placeholder="Client Type"
            handleChange={handleChange}
            />
            <Input
                value={programPrice}
                label="Program Price"
                id={"Program Price"}
                onChange={(e) => handleChange("Program Price", e)}
            />
        </FormContainer>
    </>
  );
};

export default ProgramForm;
