import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const ProgramDetailForm = ({ programDetail, clicked, setClicked, refresh }) => {
    const [programName, setProgramName] = useState(programDetail.program_name);
    const [clientType, setClientType] = useState({value: programDetail.type, label: programDetail.type});
    const [programPrice, setProgramPrice] = useState(programDetail.program_price);
    const [isActive, setIsActive] = useState( programDetail.is_active === "1" ? {value: 1, label: "Active"} : {value: 0, label: "Inactive"} );
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const onClick = (e) => {
    const req_obj = {
        program_id: programDetail.program_id,
        program_name: programName,
        program_price: programPrice ?? 0,
        is_active: isActive.value,
        client_type: clientType.value,
      };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_PROGRAM_DETAIL, data: req_obj })
    ).then((res) => {
      if (res) {
        refresh();
        setClicked(!clicked);
      } else {
        toastId.current = toast.error(
          "There was an error updating the program",
          { position: "top-center" }
        );
      }
    }).catch((err) => {
        toastId.current = toast.error("There was an error updating the program", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Program Name":
            setProgramName(e.target.value);
            break;
        case "Program Price":
            setProgramPrice(e.target.value);
            break;
        case "Client Type":
            setClientType(e);
            break;
        case "Program Status":
            setIsActive(e);
            break;
        default:
            break;
    }
  };

  return (
      <>
        <Input
            value={programName}
            label="Program Name"
            id={"Program Name"}
            onChange={(e) => handleChange("Program Name", e)}
        />
        <ReactSelect
            options={clientTypeList.slice(0, -1)}
            value={clientType}
            placeholder="Client Type"
            handleChange={handleChange}
        />
        <ReactSelect
            options={[ {value: 0, label: "Inactive"}, {value: 1, label: "Active"} ]}
            value={isActive}
            placeholder="Program Status"
            handleChange={handleChange}
        />
        <Input
            value={programPrice}
            label="Program Price"
            id={"Program Price"}
            onChange={(e) => handleChange("Program Price", e)}
        />
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
      </>
  );
};

export default ProgramDetailForm;
