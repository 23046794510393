import React, { useEffect, useState } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../Components/Filters";
import "./AdminAssignmentInsights.scss";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { months, years } from "../../Utils/arrayItems";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { ExportButton } from "../../Components/ExportButton";
import { 
    insightsGroupbyOptions,
    insightTypeOptions,
    metricOptions,
} from "../../Utils/selectConstants";

const AdminAssignmentInsights = (props) => {
  const dispatch = useDispatch();
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  let insights = useSelector((state) => state.adminReducer.adminAssignmentInsights);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [data, setData] = useState(insights);
  const [count, setCount] = useState(0);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [groupBySelected, setGroupBySelected] = useState([
    {value: "Coach", label: "Coach"}
  ]);
  const [insightTypeSelected, setInsightTypeSelected] = useState([
    {value: "Average Months", label: "Average Months"},
  ]);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [sortingDirectionGroupedAssignments, setSortingDirectionGroupedAssignments] = useState({
    client_name: "ASC",
    assignment_start_date: "",
    assignment_end_date: "",
    months_on_program: ""
  });
  const [sortingDirection, setSortingDirection] = useState({
    group_name: "ASC",
    number_of_clients: "",
    average_months_on_program: "",
  });

  const [showToggle, setShowToggle] = useState(false);

  const sortAverageMonthsColumn = (column) => {
    const direction = sortingDirection[column];
    if (direction === "ASC" || direction === "") {
      const sortedData = [...insights].sort((a, b) => (a[column] < b[column] ? 1 : -1));
      setData(sortedData);
      setSortingDirection({ ...sortingDirection, [column]: "DESC" });
    } else if (direction === "DESC") {
      const sortedData = [...insights].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      setData(sortedData);
      setSortingDirection({ ...sortingDirection, [column]: "ASC" });
    }
  };

  const sortGroupedAssignmentsColumn = (column) => {
    const direction = sortingDirectionGroupedAssignments[column];
    if (direction === "ASC" || direction === "") {
      const sortedData = [...insights].sort((a, b) => (a[column] < b[column] ? 1 : -1));
      setData(sortedData);
      setSortingDirectionGroupedAssignments({ ...sortingDirectionGroupedAssignments, [column]: "DESC" });
    } else if (direction === "DESC") {
      const sortedData = [...insights].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      setData(sortedData);
      setSortingDirectionGroupedAssignments({ ...sortingDirectionGroupedAssignments, [column]: "ASC" });
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Assignment Insights" } }));
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let groupby_arr = groupBySelected.map((data) => data.value);
      let insight_type_arr = insightTypeSelected.map((data) => data.value);
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        insights_groupby: groupby_arr,
        insight_type: insight_type_arr,
        page_name: "Assignment Insights"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector === 'Groupby' || selector === 'Insight Type') {
        e = [e];
    }
    if (selector != "Assignment Start within Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Assignment Start within Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      case "Groupby":
        if (e.length == 0) {
            setGroupBySelected([{ value: "Coach", label: "Coach" }]);
        } else {
            setGroupBySelected(newVals);
        }
        break;
      case "Insight Type":
        if (e.length == 0) {
          setInsightTypeSelected([{ value: "Average Months", label: "Average Months" }]);
        } else {
            setInsightTypeSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
      setData(insights);
  }, [insights]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminAssignmentInsights({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "insights_groupby") {
          setGroupBySelected(temp_array);
        } else if (key == "insight_type") {
          setInsightTypeSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminAssignmentInsights({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminAssignmentInsights({ filters: filters }));
  }, [applyClicked]);

  const exportData =
    data
      .map((v) => {
          const exportRows = [];
          const { coach_id, full_name, ...rest } = v;
          exportRows.push({
              ...rest,
          });
          return exportRows;
      })
      .flat() ?? [];

  return (
    <div className="expenses-container">
      <Row className="mt-4">
        <div className="title-row">
          <div className="expenses-title">Assignment Insights</div>
          <div style={{'margin-left': 'auto'}}>
            <ExportButton contents={exportData}/>
          </div>
          <Button
            className="filters-button-client"
            onClick={() => setFiltersClicked(!filtersClicked)}>
            FILTERS
          </Button>
        </div>
      </Row>
      {filtersClicked && (
        <Filters
          setFiltersClicked={setFiltersClicked}
          filtersClicked={filtersClicked}
          onClick={handleClick}>
          <ReactSelect
            options={insightsGroupbyOptions}
            value={groupBySelected}
            placeholder="Groupby"
            handleChange={handleChange}
          />
          <ReactSelect
            options={insightTypeOptions}
            value={insightTypeSelected}
            placeholder="Insight Type"
            handleChange={handleChange}
          />
          <DatePicker
            key={100}
            label={"Assignment Start within Date Range"}
            startDate={startDate}
            endDate={endDate}
            showToggle={showToggle}
            setShowToggle={setShowToggle}
            type="range">
            <DateRangePicker
              startDatePlaceholder={startDate}
              endDatePlaceholder={endDate}
              ranges={[
                { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
              ]}
              onChange={(e) => handleChange("Assignment Start within Date Range", e)}
              showPreview={false}
            />
          </DatePicker>
        </Filters>
      )}
      <Row className="mt-4">
        {
          insightTypeSelected[0].value === 'Average Months' &&
          <Table striped variant="dark" className="expenses-table" responsive>
            <thead>
              <tr>
                <th onClick={() => sortAverageMonthsColumn("group_name")}>{groupBySelected.value} Name</th>
                <th onClick={() => sortAverageMonthsColumn("number_of_clients")}>Number of Clients</th>
                <th onClick={() => sortAverageMonthsColumn("average_months_on_program")}>Average Months on Program</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((groupby) => {
                  return (
                    <tr key={`{${groupby.group_name}${groupby.group_id}`}>
                      <td>{groupby.group_name}</td>
                      <td>{`${groupby.number_of_clients}`}</td>
                      <td>{`${groupby.average_months_on_program}`}</td>
                    </tr>
                  );
                })}
            </tbody>
        </Table>}
        {
          insightTypeSelected[0].value === 'Length Grouped by Assignment' &&     
          <Table striped variant="dark" className="expenses-table" responsive>
            <thead>
              <tr>
                <th onClick={() => sortGroupedAssignmentsColumn("client_name")}>Client Name</th>
                <th onClick={() => sortGroupedAssignmentsColumn("assignment_start_date")}>Assignment Start Date</th>
                <th onClick={() => sortGroupedAssignmentsColumn("assignment_end_date")}>Assignment End Date</th>
                <th onClick={() => sortGroupedAssignmentsColumn("months_on_program")}>Months on Program</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((groupby) => {
                  return (
                    <tr key={`{${groupby.client_name}${groupby.client_id}`}>
                      <td>{groupby.client_name}</td>
                      <td>{`${groupby.assignment_start_date}`}</td>
                      <td>{`${groupby.assignment_end_date}`}</td>
                      <td>{`${groupby.months_on_program}`}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        }
        {insights && insights.length === 0 && <div className="data-title">No Data Available</div>}
      </Row>
    </div>
  );
};

export default AdminAssignmentInsights;
