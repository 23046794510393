import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ExportButton } from "../ExportButton";
import { PlusCircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Item from "../../Components/ListViews/Item";
import Select from "react-select";
import "../Components.scss";
import Filters from "../Filters";
import { styles, theme } from "../../Utils/selectStyles";

const TableContainer = ({
  values,
  buttonTitle,
  title,
  itemLeftLabel,
  itemRightLabel,
  itemId,
  pathname,
  linkName,
  filterButton,
  filtersClicked,
  filters,
  children,
  admin = false,
  registrationButton = false,
  exportButton = false,
  exportData = [],
  registrationKey = "",
  itemCenterLabel = ""
}) => {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
      <Row className="page-container mt-3">
        <Col className="top-row">
          <p className="title">{title}</p>
          {linkName && (
            <Link
              to={`add_${linkName}`}
              style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
              <div className="icon-button">
                <PlusCircleFill className="icon" size={15} />
                <p>{buttonTitle}</p>
              </div>
            </Link>
          )}
          {registrationButton && (
            <Button
              className="filters-button-client"
              onClick={() => {
                navigator.clipboard.writeText(registrationKey);
              }}>
              REGISTRATION KEY: {registrationKey}
            </Button>
          )}
          {exportButton && (
            <ExportButton contents={exportData}/>
          )}
          {filterButton && (
            <Button
              className="filters-button-client"
              onClick={() => filters.setFiltersClicked(!filtersClicked)}>
              FILTERS
            </Button>
          )}
        </Col>
        {filtersClicked && (
          <Filters
            setFiltersClicked={filters.setFiltersClicked}
            filtersClicked={filters.filtersClicked}
            onClick={filters.handleClick}>
            {children}
          </Filters>
        )}
        <Col className="table-container mt-4">
          {values && values.length > 0 ? (
            values.map((item) => {
              return (
                <Item
                  key={item.itemId}
                  item={item}
                  itemLeftLabel={itemLeftLabel}
                  itemCenterLabel={itemCenterLabel}
                  itemRightLabel={itemRightLabel}
                  itemId={itemId}
                  pathname={pathname}
                  admin={admin}
                />
              );
            })
          ) : (
            <div className="item">No data to show</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TableContainer;
