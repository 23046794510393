import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction, sessionAction, adminAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import ReactSelect from "../../Components/Forms/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "../../Components/Forms/DatePicker";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import { Calendar } from "react-date-range";

const SessionForm = (props) => {
  const userInfo = useSelector((state) => state.authReducer.persona);
  const [clientName, setClientName] = useState(
    props.location.state && props.location.state.prefill
      ? {
          value:
            props.location.state.prefill.client_name +
            " (" +
            props.location.state.prefill.client_id +
            ")",
          label:
            props.location.state.prefill.client_name +
            " (" +
            props.location.state.prefill.client_id +
            ")"
        }
      : { value: "", label: "" }
  );
  const [coachName, setCoachName] = useState({ value: "", label: "" });
  const [clientId, setClientId] = useState(
    props.location.state && props.location.state.prefill
      ? props.location.state.prefill.client_name +
          " (" +
          props.location.state.prefill.client_id +
          ")"
      : ""
  );
  const [sessionDate, setSessionDate] = useState(new Date().toLocaleDateString());
  const [clientType, setClientType] = useState(
    props.location.state && props.location.state.prefill
      ? {
          value: props.location.state.prefill.client_type,
          label: props.location.state.prefill.client_type
        }
      : ""
  );
  const [sessionType, setSessionType] = useState("");
  const [topic, setTopic] = useState([]);
  const [workshopNumber, setWorkshopNumber] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [worksheet, setWorksheet] = useState([]);
  const [worksheetList, setWorksheetList] = useState([]);
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [coachList, setCoachList] = useState([]);
  const toastId = useRef(null);
  const clientList_db = useSelector((state) => state.clientReducer.clientList);
  const worksheetList_db = useSelector((state) => state.sessionReducer.sessionWorksheets);
  const topicList_db = useSelector((state) => state.sessionReducer.sessionTopics);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const [showToggle, setShowToggle] = useState(false);

  const [filteredSessionTypeList, setFilteredSessionTypeList] = useState(sessionTypeList);

  useEffect(() => {
    dispatch(clientAction.loadClientListData({ filters: { is_active: ["Active"] } }));
    dispatch(sessionAction.loadSessionWorksheetData({ filters: {} }));
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(sessionAction.loadSessionTopicData({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (userInfo && userInfo.is_admin === "1") {
      dispatch(
        clientAction.loadClientListData({
          filters: { is_active: ["Active"], coach: coachName.value }
        })
      );
    }
  }, [coachName]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  useEffect(() => {
    let ws_list_db = worksheetList_db.map((worksheet) => {
      return {
        value: `${worksheet.worksheet}`,
        label: `${worksheet.worksheet}`
      };
    });
    ws_list_db.push({ value: "", label: "" });
    setWorksheetList(ws_list_db);
  }, [worksheetList_db]);

  useEffect(() => {
    let topic_list_db = topicList_db.map((topic) => {
      return {
        value: `${topic.topic}`,
        label: `${topic.topic}`
      };
    });
    topic_list_db.push({ value: "", label: "" });
    setTopicList(topic_list_db);
  }, [topicList_db]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Coach Name":
        setCoachName(e);
        break;
      case "Client Name":
        setClientName(e);
        setClientId(e.value);
        break;
      case "Client Type":
        setClientType(e);
        setSessionType("");
        setTopic([]);
        setWorksheet([]);
        if (e.value === "Athlete") {
          setFilteredSessionTypeList([sessionTypeList[0], sessionTypeList[1], sessionTypeList[4]]);
          setTopicList(topicList);
        } else if (e.value === "Team") {
          setFilteredSessionTypeList([sessionTypeList[0], sessionTypeList[2], sessionTypeList[3], sessionTypeList[5]]);
          setTopicList(topicList.filter((x) => x.value.match(/Team\sWorkshop/g)));
        } else {
          setTopicList(topicList);
        }

        break;
      case "Session Type":
        setSessionType(e);
        setTopic([]);
        break;
      case "Topic":
        setTopic(e);
        break;
      case "Worksheet":
        setWorksheet(e);
        break;
      case "Workshop #":
        setWorkshopNumber(e);
        break;
      case "Notes":
        setNotes(e.target.value);
        break;
    }
  };

  // if the topic 'Team Workshop' is selected, a workshop number has to be present
  function checkWorkshopNumber(req_obj) {
    if (
      topic.filter((topic) => topic.value === "Team Workshop").length > 0 &&
      workshopNumber === ""
    ) {
      return false;
    }
    return true;
  }

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        client_id: clientId,
        client_name: clientName.value,
        coach_name: coachName.value ?? "",
        session_date: sessionDate,
        client_type: clientType.value,
        session_type: sessionType.value,
        topic: topic.map((data) => data.value),
        worksheet: worksheet.map((data) => data.value),
        workshop_number: workshopNumber.value,
        notes: notes
      };
      if (
        clientName.value !== "" &&
        sessionDate !== "" &&
        clientType.value !== "" &&
        sessionType.value !== "" && 
        sessionType.value !== "Coaches Consultation" &&
        topic.length > 0 &&
        worksheet.length > 0 &&
        checkWorkshopNumber(req_obj)
      ) {
        insert_data(req_obj);
      } else if (        
        clientName.value !== "" &&
        sessionDate !== "" &&
        sessionType.value === "Coaches Consultation") 
      {
        insert_data(req_obj); 
      } else {
        
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setClientName("");
    setCoachName("");
    setSessionDate(new Date().toLocaleDateString());
    setClientType("");
    setSessionType("");
    setTopic([]);
    setWorkshopNumber("");
    setWorksheet([]);
    setNotes("");
    setFilteredSessionTypeList(sessionTypeList);
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_SESSION, data: req_obj }))
      .then((res) => {
        if (res.data.code === "Success") {
          toastId.current = toast.success(
            "You’ve successfully inserted a new session for " + req_obj["client_name"],
            { position: "top-center" }
          );
          reset_data();
        } else if (res.data.code === "Duplicate") {
          toastId.current = toast.error(
            "You’ve already inserted a session for " +
              req_obj["client_name"] +
              " on " +
              req_obj["session_date"],
            { position: "top-center" }
          );
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new session for " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting session data", {
          position: "top-center"
        });
      });
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setSessionDate(selectedDate.toLocaleDateString());
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="LOG A SESSION" onClick={handleClick}>
        {userInfo && userInfo.is_admin === "1" && (
          <ReactSelect
            options={coachList}
            value={coachName}
            label="Coach Name"
            placeholder={"Coach Name"}
            id={"Coach Name"}
            handleChange={handleChange}
          />
        )}
        <ReactSelect
          options={clientList}
          value={clientName}
          placeholder="Client Name"
          handleChange={handleChange}
        />
        <DatePicker
          date={sessionDate}
          showToggle={showToggle}
          setShowToggle={setShowToggle}
          label={"Session Date"}>
          <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
        </DatePicker>
        <ReactSelect
          options={clientTypeList.slice(0, -1)}
          value={clientType}
          placeholder="Client Type"
          handleChange={handleChange}
        />
        <ReactSelect
          options={filteredSessionTypeList}
          value={sessionType}
          placeholder="Session Type"
          handleChange={handleChange}
        />
        {sessionType.value !== "Coaches Consultation" && (
          <ReactSelect
            options={topicList}
            value={topic}
            isMulti
            placeholder="Topic"
            handleChange={handleChange}
          />
        )}
        {topic.filter((topic) => topic.value === "Team Workshop").length > 0 && (
          <ReactSelect
            options={workshopNumberList}
            value={workshopNumber}
            placeholder="Workshop #"
            handleChange={handleChange}
          />
        )}
        {sessionType.value !== "Coaches Consultation" && (
          <ReactSelect
            options={worksheetList}
            value={worksheet}
            isMulti
            placeholder="Worksheet"
            handleChange={handleChange}
          />
        )}
        <TextArea
          label="Notes"
          height={"300px"}
          onChange={(e) => handleChange("Notes", e)}
          value={notes}
        />
      </FormContainer>
    </>
  );
};

export default SessionForm;
