import React, { useState, useRef } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { requestOptions } from "../../Utils";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_CONSTANTS from "../../Redux/constants";

const AdminCoachForm = (props) => {
  const toastId = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState({value: "", label: ""});
  const [coachingExperience, setCoachingExperience] = useState({value: "", label: ""});
  const [sport, setSport] = useState("");
  const [timezone, setTimeZone] = useState({value: "", label: ""});
  const [weekdays, setWeekdays] = useState("");
  const [timeofday, setTimeOfDay] = useState("");
  const [preferredTrainingLevel, setPreferredTrainingLevel] = useState("");
  const [weeklySessions, setWeeklySessions] = useState("");
  const [preferredClientType, setPreferredClientType] = useState("");
  const [comfortableWithFaith, setComfortableWithFaith] = useState({value: "", label: ""});
  const [salesPersonStatus, setSalesPersonStatus] = useState({value: "", label: ""});
  const [area_of_study, setAreaOfStudy] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");

  const sportList = [{value: "Academic Mindset", label: "Academic Mindset"},
                    {value: "Baseball", label: "Baseball"},
                    {value: "Baseball/Softball", label: "Baseball/Softball"},
                    {value: "Basketball", label: "Basketball"},
                    {value: "Bowling", label: "Bowling"},
                    {value: "Corporate Sales", label: "Corporate Sales"},
                    {value: "Field Hockey", label: "Field Hockey"},
                    {value: "Football", label: "Football"},
                    {value: "Golf", label: "Golf"},
                    {value: "Gymnastics", label: "Gymnastics"},
                    {value: "Hockey", label: "Hockey"},
                    {value: "Lacrosse", label: "Lacrosse"},
                    {value: "Martial Arts", label: "Martial Arts"},
                    {value: "Other", label: "Other"},
                    {value: "Soccer", label: "Soccer"},
                    {value: "Swimming", label: "Swimming"},
                    {value: "Tennis", label: "Tennis"},
                    {value: "Track", label: "Track"},
                    {value: "Volleyball", label: "Volleyball"},
                    {value: "Wrestling", label: "Wrestling"}];

 const salesPersonStatusOptions = [{value: "Yes", label: "Yes"},
                                  {value: "No", label: "No"}]

 const weeklySessionOptions = [{value: "1", label: "1"},
                               {value: "2", label: "2"},
                               {value: "3", label: "3"},
                               {value: "4", label: "4"},
                               {value: "5", label: "5"},
                               {value: "More than 5", label: "More than 5"}]

 const weekdayOptions = [{value: "Monday", label: "Monday"},
                         {value: "Tuesday", label: "Tuesday"},
                         {value: "Wednesday", label: "Wednesday"},
                         {value: "Thursday", label: "Thursday"},
                         {value: "Friday", label: "Friday"},
                         {value: "Saturday", label: "Saturday"},
                         {value: "Sunday", label: "Sunday"}
                        ]

const trainingLevelOptions = [{value: "Youth", label: "Youth"},
                              {value: "High School", label: "High School"},
                              {value: "College", label: "College"},
                              {value: "Senior", label: "Senior"}
                             ]

const timeofDayOptions = [{value: "Morning", label: "Morning"},
                          {value: "Afternoon", label: "Afternoon"},
                          {value: "Evening", label: "Evening"}
                         ]

 const preferredClientTypeOptions = [{value: "Athletes", label: "Athletes"},
                                     {value: "Teams", label: "Teams"},
                                     {value: "Both", label: "Both"}]

  const comfortableWithFaithOptions = [{value: 1, label: 'Yes'},
                                       {value: 0, label: 'No'}  ]

  const tzList = [{value: "EST", label: "EST"},
                  {value: "CST", label: "CST"},
                  {value: "MST", label: "MST"},
                  {value: "PST", label: "PST"}]

  const stateList = [{value: "AL",  label: "AL"},
                     {value: "AK",  label: "AK"},
                     {value: "AZ",  label: "AZ"},
                     {value: "AR",  label: "AR"},
                     {value: "CA",  label: "CA"},
                     {value: "CO",  label: "CO"},
                     {value: "CT",  label: "CT"},
                     {value: "DE",  label: "DE"},
                     {value: "FL",  label: "FL"},
                     {value: "GA",  label: "GA"},
                     {value: "HI",  label: "HI"},
                     {value: "ID",  label: "ID"},
                     {value: "IL",  label: "IL"},
                     {value: "IN",  label: "IN"},
                     {value: "IA",  label: "IA"},
                     {value: "KS",  label: "KS"},
                     {value: "KY",  label: "KY"},
                     {value: "LA",  label: "LA"},
                     {value: "ME",  label: "ME"},
                     {value: "MD",  label: "MD"},
                     {value: "MA",  label: "MA"},
                     {value: "MI",  label: "MI"},
                     {value: "MN",  label: "MN"},
                     {value: "MS",  label: "MS"},
                     {value: "MO",  label: "MO"},
                     {value: "MT",  label: "MT"},
                     {value: "NE",  label: "NE"},
                     {value: "NV",  label: "NV"},
                     {value: "NH",  label: "NH"},
                     {value: "NJ",  label: "NJ"},
                     {value: "NM",  label: "NM"},
                     {value: "NY",  label: "NY"},
                     {value: "NC",  label: "NC"},
                     {value: "ND",  label: "ND"},
                     {value: "OH",  label: "OH"},
                     {value: "OK",  label: "OK"},
                     {value: "OR",  label: "OR"},
                     {value: "PA",  label: "PA"},
                     {value: "RI",  label: "RI"},
                     {value: "SC",  label: "SC"},
                     {value: "SD",  label: "SD"},
                     {value: "TN",  label: "TN"},
                     {value: "TX",  label: "TX"},
                     {value: "UT",  label: "UT"},
                     {value: "VT",  label: "VT"},
                     {value: "VA",  label: "VA"},
                     {value: "WA",  label: "WA"},
                     {value: "WV",  label: "WV"},
                     {value: "WI",  label: "WI"},
                     {value: "WY",  label: "WY"}]

  const salesSourceList = [{value: 'Brickwork', label: 'Brickwork'},
                              {value: 'Club Running', label: 'Club Running'},
                              {value: 'Cold Call', label: 'Cold Call'},
                              {value: 'Convention', label: 'Convention'},
                              {value: 'Other', label: 'Other'},
                              {value: 'Referral', label: 'Referral'},
                              {value: 'Renewal', label: 'Renewal'},
                              {value: 'Social Media', label: 'Social Media'},
                              {value: 'Tournament', label: 'Tournament'},
                              {value: 'Mindset Coach', label: 'Mindset Coach'},
                              {value: 'Mindset website', label: 'Mindset website'},
                              {value: 'Wufoo', label: 'Wufoo'}
                              ]

  const handleClick = (e) => {
    if (e.target.id == "add") {
      if (
        (firstName !== "" && lastName !== "" && email !== "" && weekdays !== "" && timeofday !== "" && salesPersonStatus.value !== "")
      ) {
        const req_obj = {
          is_active: 1,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state.value,
          date_of_birth: date_of_birth,
          sport_specialty: sport.map((data) => data.value),
          primary_time_zone: timezone.value,
          dow_availability: weekdays.map((data) => data.value),
          tod_availability: timeofday.map((data) => data.value),
          desired_weekly_sessions: weeklySessions,
          athlete_team_preference: preferredClientType.map((data) => data.value),
          is_religious: comfortableWithFaith.value,
          client_preference_rank: preferredTrainingLevel.map((data) => data.value),
          eligible_for_sales: salesPersonStatus.value,
          area_of_study: area_of_study
        };
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    } else {
      reset_data();
    }
  };

  const reset_data = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setState({value: "", label: ""});
    setDateOfBirth("");
    setSport("");
    setTimeZone({value: "", label: ""});
    setTimeOfDay("");
    setWeekdays("");
    setWeeklySessions("");
    setPreferredClientType("");
    setComfortableWithFaith({value: "", label: ""});
    setPreferredTrainingLevel("");
    setSalesPersonStatus({value: "", label: ""});
    setAreaOfStudy("");
    toastId.current = toast.info(
      "The coach form has been reset",
      { position: "top-center" }
    );
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_COACH, data: req_obj }))
      .then((res) => {
        if (res) {
          if (res.data.message === 'Success') {
            toastId.current = toast.success(
              "You’ve successfully inserted a new coach",
              { position: "top-center" }
            );
            reset_data();
          }

          else if (res.data.message === 'Duplicates') {
            toastId.current = toast.warning(
              "You’ve entered info that matches a coach that already exists in the database. Please view the All Coaches page to see the existing coach",
              { position: "top-center" }
            );
          }

        } else {
          toastId.current = toast.error(
            "There was an error inserting a new coach",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting coach data", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "First Name":
        setFirstName(e.target.value);
        break;
      case "Last Name":
        setLastName(e.target.value);
        break;
      case "Email":
        setEmail(e.target.value);
        break;
      case "Phone":
        setPhone(e.target.value);
        break;
      case "Address":
        setAddress(e.target.value);
        break;
      case "State":
        setState(e);
        break;
      case "DOB":
        setDateOfBirth(e.target.value);
        break;
      case "Area of Study/Background":
        setAreaOfStudy(e.target.value);
        break;
      case "Sport Specialty":
        setSport(e);
        break;
      case "Primary Time Zone":
        setTimeZone(e);
        break;
      case "Available Days":
        setWeekdays(e);
        break;
      case "Available Time of Day":
        setTimeOfDay(e);
        break;
      case "Desired Number of Weekly Sessions":
        setWeeklySessions(e.target.value);
        break;
      case "Preferred Client Type":
        setPreferredClientType(e);
        break;
      case "Comfortable Incorporating Faith?":
        setComfortableWithFaith(e);
        break;
      case "Preferred Training Level":
        setPreferredTrainingLevel(e);
        break;
      case "City":
        setCity(e.target.value);
        break;
      case "Eligible for Sales":
        setSalesPersonStatus(e);
        break;
      default:
        break;
    }
  };

  return (
    <>
    <ToastContainer limit={1} theme="dark" />
    <FormContainer onClick={handleClick} className="active" title="ADD A COACH">
      <Input
        label="First Name"
        type="text"
        value={firstName}
        onChange={(e) => handleChange("First Name", e)}
      />
      <Input
        label="Last Name"
        type="text"
        value={lastName}
        onChange={(e) => handleChange("Last Name", e)}
      />
      <Input
        label="Email"
        type="text"
        value={email}
        onChange={(e) => handleChange("Email", e)}
      />
      <Input
        label="Phone"
        type="text"
        value={phone}
        onChange={(e) => handleChange("Phone", e)}
      />
      <Input
        label="Address"
        type="text"
        value={address}
        onChange={(e) => handleChange("Address", e)}
      />
      <Input
        label="City"
        type="text"
        value={city}
        onChange={(e) => handleChange("City", e)}
      />
      <ReactSelect
        type="text"
        placeholder="State"
        options={stateList}
        value={state}
        handleChange={handleChange}
      />
      <Input
          label="DOB"
          type="date"
          value={date_of_birth}
          onChange={(e) => handleChange("DOB", e)}
      />
      <Input
          label="Area of Study/Background"
          type="text"
          value={area_of_study}
          onChange={(e) => handleChange("Area of Study/Background", e)}
      />
      <ReactSelect
        options={sportList}
        value={sport}
        isMulti
        placeholder="Sport Specialty"
        handleChange={handleChange}
      />
      <ReactSelect
        options={tzList}
        value={timezone}
        placeholder="Primary Time Zone"
        handleChange={handleChange}
      />
      <ReactSelect
        options={weekdayOptions}
        value={weekdays}
        isMulti
        placeholder="Available Days"
        handleChange={handleChange}
      />
      <ReactSelect
        options={timeofDayOptions}
        value={timeofday}
        isMulti
        placeholder="Available Time of Day"
        handleChange={handleChange}
      />
      <Input
        label="Desired Number of Weekly Sessions"
        type="text"
        value={weeklySessions}
        onChange={(e) => handleChange("Desired Number of Weekly Sessions", e)}
      />
      <ReactSelect
        options={preferredClientTypeOptions}
        value={preferredClientType}
        isMulti
        placeholder="Preferred Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={comfortableWithFaithOptions}
        value={comfortableWithFaith}
        placeholder="Comfortable Incorporating Faith?"
        handleChange={handleChange}
      />
      <ReactSelect
        options={trainingLevelOptions}
        value={preferredTrainingLevel}
        isMulti
        placeholder="Preferred Training Level"
        handleChange={handleChange}
      />
      <ReactSelect
        options={salesPersonStatusOptions}
        value={salesPersonStatus}
        placeholder="Eligible for Sales"
        handleChange={handleChange}
      />
    </FormContainer>
    </>
  );
};

export default AdminCoachForm;
