import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Logo from "../../assets/wm-logo.jpg";
import "./CoachDetail.scss";
import routingConstants from "../../Navigation/constants";
import { coachDetailAction, adminAction } from "../../Redux/actions";
import CoachDetailForm from "./CoachDetailForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

library.add(faRunning);

const CoachDetail = (props) => {
  const dispatch = useDispatch();
  const coachDetail = useSelector((state) => state.coachDetailReducer.coachDetail);
  const adminCoachDetail = useSelector((state) => state.adminReducer.adminCoachDetail);
  const [coachDetailFinal, setCoachDetailFinal] = useState({});
  const [initials, setInitials] = useState("");
  const [isActive, setIsActive] = useState("");
  const [fullName, setFullName] = useState("");
  const [sessionsLogged, setSessionsLogged] = useState("");
  const [activeClients, setActiveClients] = useState("");
  const [registrationTimestamp, setRegistrationTimestamp] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [sport, setSport] = useState("");
  const [address, setAddress] = useState("");
  const [area_of_study, setAreaOfStudy] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [dayAvailability, setDayAvailability] = useState("");
  const [timeAvailability, setTimeAvailability] = useState("");
  const [preferredSessions, setPreferredSessions] = useState("");
  const [preferredClientType, setPreferredClientType] = useState("");
  const [comfortableWithFaith, setComfortableWithFaith] = useState("");
  const [preferredTrainingLevel, setPreferredTrainingLevel] = useState("");
  const [salesPersonStatus, setSalesPersonStatus] = useState("");
  const [state, setState] = useState("");
  const [fullTimeJob, setFullTimeJob] = useState("");
  const [highestLevelOfCompetition, setHighestLevelOfCompetition] = useState("");
  const [schoolCompetedFor, setSchoolCompetedFor] = useState("");
  const [clicked, setClicked] = useState(false);
  const toastId = useRef(null);
  const [detailEdited, setDetailEdited] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const refresh = () => {
    if (props.location.state && props.location.state.admin === true) {
      // console.log("this is running")
      dispatch(
        adminAction.loadAdminCoachDetail({ filters: { coach_id: props.location.state.id } })
      );
    } else {
      dispatch(coachDetailAction.loadCoachDetailData({ filters: {} }));
    }
  };

  function convertFromListType(field, obj) {
    if (field in obj) {
      let field_list = ""
      for (var i in obj[field]) {
        field_list += obj[field][i] + ", "
      }
      return field_list.slice(0, -2)
    }
    return ""
  }

  function setDetail(obj) {
    setInitials(obj.initials);
    setIsActive(obj.is_active);
    setFullName(obj.full_name);
    setSessionsLogged(obj.sessions_logged);
    setActiveClients(obj.active_clients);
    setRegistrationTimestamp(obj.registration_timestamp);
    setEmail(obj.email);
    setPhone(obj.phone);
    setAddress(obj.address);
    setCity(obj.hometown);
    setTimeZone(obj.primary_time_zone);
    setAreaOfStudy(obj.area_of_study);
    setPreferredClientType(convertFromListType("athlete_team_preference", obj));
    setComfortableWithFaith(obj.is_religious);
    setPreferredTrainingLevel(convertFromListType("client_preference_rank", obj));
    setSport(convertFromListType("sport_specialty", obj));
    setDayAvailability(convertFromListType("dow_availability", obj));
    setTimeAvailability(convertFromListType("tod_availability", obj));
    setPreferredSessions(obj.desired_weekly_sessions);
    setSalesPersonStatus(obj.eligible_for_sales);
    setDateOfBirth(obj.date_of_birth);
    setState(obj.address_state);
    setFullTimeJob(obj.full_time_job);
    setHighestLevelOfCompetition(obj.highest_level_of_competition);
    setSchoolCompetedFor(obj.school_competed_for);
  }

  useEffect(() => {
    if (props.location.state && props.location.state.admin == true) {
      setCoachDetailFinal(adminCoachDetail);
      setDetail(adminCoachDetail)
    } else {
      setCoachDetailFinal(coachDetail);
      setDetail(coachDetail)
    }
  }, [coachDetail, adminCoachDetail]);

  useEffect(() => {
    if (props.location.state && props.location.state.admin === true) {
      dispatch(
        adminAction.loadAdminCoachDetail({ filters: { coach_id: props.location.state.id } })
      );
    } else {
      dispatch(coachDetailAction.loadCoachDetailData({ filters: {} }));
    }
  }, [detailEdited, dispatch]);

  const onSelectChange = (e) => {
    switch (e.target.id) {
      case "address":
        setAddress(e.target.value);
        break;
      case "sport":
        setSport(e.target.value);
        break;
      case "timeZone":
        setTimeZone(e.target.value);
        break;
      case "dayAvailability":
        setDayAvailability(e.target.value);
        break;
      case "timeAvailability":
        setTimeAvailability(e.target.value);
        break;
      case "preferredSessions":
        setPreferredSessions(e.target.value);
        break;
      case "preferredClientType":
        setPreferredClientType(e.target.value);
        break;
      case "comfortableWithFaith":
        setComfortableWithFaith(e.target.value);
        break;
      case "preferredTrainingLevel":
        setPreferredTrainingLevel(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <Container className="coaches-detail-container">
        <Row className="initials-container mt-4">
          <Container>
            <div className="initials">{coachDetailFinal && initials}</div>
          </Container>
        </Row>
        <Row className="mt-2" style={{ width: "100%" }}>
          <Col xs={12}>
            <div className="status">
              <p>
                {coachDetailFinal && isActive == 1 ? "Active" : "Inactive"}{" "}
                <FontAwesomeIcon icon="running" className="yellow" />
              </p>
            </div>
          </Col>
          <Col xs={12}>
            <div className="coaches-name mb-2">
              <p>{coachDetailFinal && fullName}</p>
            </div>
          </Col>
          <Row className="kpi-card-container custom-row">
            <Col xs={6}>
              <div className="kpi-card">
                <p>SESSIONS LOGGED: {coachDetailFinal && sessionsLogged}</p>
              </div>
            </Col>
            <Col xs={6}>
              <div className="kpi-card">
                <p>ACTIVE CLIENTS: {coachDetailFinal && activeClients}</p>
              </div>
            </Col>
          </Row>
          <Col xs={12}>
            <div className="detail-container-form">
              <div className="items">
                <div className="top-container">
                  {!clicked && <div className="labels">ABOUT</div>}
                  <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                    {clicked ? "CANCEL" : "EDIT"}
                  </Button>
                </div>
                {clicked ? (
                  <CoachDetailForm
                    coachDetail={coachDetailFinal}
                    clicked={clicked}
                    setClicked={setClicked}
                    refresh={refresh}
                    toastId={toastId}
                  />
                ) : (
                  <>
                    <div className="labels mt-3">
                      Coach Since
                      <div className="coach-detail-info">
                        {coachDetailFinal && registrationTimestamp}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Email
                      <div className="coach-detail-info">
                        {coachDetailFinal && email}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Phone
                      <div className="coach-detail-info">
                        {coachDetailFinal && phone}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Street Address
                      <div className="coach-detail-info">
                        {coachDetailFinal && address}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      City/Town
                      <div className="coach-detail-info">
                        {coachDetailFinal && city}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      State
                      <div className="coach-detail-info">
                        {coachDetailFinal && state}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Date of Birth
                      <div className="coach-detail-info">
                        {coachDetailFinal && dateOfBirth}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Area of Study/Background
                      <div className="coach-detail-info">
                        {coachDetailFinal && area_of_study}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Full-time Job
                      <div className="coach-detail-info">
                        {coachDetailFinal && fullTimeJob}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Sport Specialty
                      <div className="coach-detail-info">
                        {coachDetailFinal && sport}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Highest Level Competed At?
                      <div className="coach-detail-info">
                        {coachDetailFinal && highestLevelOfCompetition}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      What school/team did you compete for?
                      <div className="coach-detail-info">
                        {coachDetailFinal && schoolCompetedFor}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Time Zone
                      <div className="coach-detail-info">
                        {coachDetailFinal && timeZone}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Day of Week Availability
                      <div className="coach-detail-info">
                        {coachDetailFinal && dayAvailability}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Time of Day Availability
                      <div className="coach-detail-info">
                        {coachDetailFinal && timeAvailability}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Preferred Number of Weekly Sessions
                      <div className="coach-detail-info">
                        {coachDetailFinal && preferredSessions}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Preferred Client Type
                      <div className="coach-detail-info">
                        {coachDetailFinal && preferredClientType}
                      </div>
                    </div>
                    <div className="labels mt-3">
                    Are you equipped to incorporate the Christian faith into mindset training?
                      <div className="coach-detail-info">
                        {coachDetailFinal && comfortableWithFaith == 1 ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Preferred Training Level
                      <div className="coach-detail-info">
                        {coachDetailFinal && preferredTrainingLevel}
                      </div>
                    </div>
                    <div className="labels mt-3">
                      Eligible for Sales
                      <div className="coach-detail-info">
                        {coachDetailFinal && salesPersonStatus}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CoachDetail;
