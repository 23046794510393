import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Image,
  FloatingLabel,
  Modal,
  ButtonGroup
} from "react-bootstrap";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { AthleteDetail, TeamDetail, CorporateDetail } from "../AdminClientDetail/AdminClientDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle, faCheckCircle, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./ClientDetail.scss";
import { clientAction, adminAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { requestOptions } from "../../Utils";

library.add(faRunning);
library.add(faBed);
library.add(faInfoCircle);
library.add(faCheckCircle);

const ClickedText = ({ infoClicked }) => {
  if (infoClicked) {
    return (
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  }
  return null;
};

const ClientDetail = (props) => {
  const dispatch = useDispatch();
  const clientDetail = useSelector((state) => state.clientReducer.clientDetail);
  const clientSessions = useSelector((state) => state.adminReducer.adminSessionsByClient);
  const [clicked, setClicked] = useState(false);
  const [infoClicked, setInfoClicked] = useState(false);
  const toastId = useRef(null);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleInfoClicked = () => {
    setInfoClicked(!infoClicked);
    axios(
      requestOptions({
        method: "POST",
        url: API_CONSTANTS.CONTACT_WITH_RP,
        data: { client_id: clientDetail.client_id }
      })
    )
      .then((res) => {
        if (res) {
          refresh();
        } else {
          toastId.current = toast.error(
            "There was an error marking contact with responsible person",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error(
          "There was an error marking contact with the responsible person",
          {
            position: "top-center"
          }
        );
      });
  };

  const refresh = () => {
    dispatch(
      clientAction.loadClientDetailData({ filters: { client_id: props.location.state.id } })
    );
  };

  useEffect(() => {
    if (props.location.state) {
      dispatch(
        clientAction.loadClientDetailData({ filters: { client_id: props.location.state.id } })
      );
      dispatch(
        adminAction.loadAdminSessionsByClient({ filters: { client_id: props.location.state.id } })
      );
    }
  }, [dispatch]);

  return (
    <Container className="client-detail-container">
      <Row className="initials-container mt-4">
        <Container>
          <div className="initials">{clientDetail && clientDetail.initials}</div>
        </Container>
      </Row>
      <Row className="mt-2" style={{ width: "100%" }}>
        <Col xs={12}>
          <div className="status">
            {clientDetail.is_active === 1 ? (
              <p>
                Active <FontAwesomeIcon icon="running" className="yellow" />
              </p>
            ) : (
              <p>
                Inactive <FontAwesomeIcon icon="bed" className="yellow" />
              </p>
            )}
          </div>
        </Col>
        <Col xs={12}>
          <div className="client-name">
            <p>{clientDetail && clientDetail.client_name}</p>
          </div>
          <div className="program-name mb-2">
            <p>{clientDetail && clientDetail.program_name_shortened}</p>
          </div>
        </Col>
        <Col xs={12}>
          <AccordionContainer
            values={clientSessions}
            title="SESSION ARCHIVE"
            type="CLIENTS"
            headers={["topic", "worksheet", "session_date", "workshop_number", "coach_name"]}
            linkName={"session"}
            buttonTitle={"LOG A SESSION"}
            row_id={"session_id"}
            pathName={"session_detail"}
            handleClick={handleClick}
            source="AdminClientDetail"
            admin={true}></AccordionContainer>
        </Col>
        <Col xs={12}>
          <div className="detail-container">
            <div className="items">
              <div className="top-container">
                <div className="labels">ABOUT</div>
                <div className="mr-2 ml-3" onClick={handleInfoClicked}>
                  {clientDetail && clientDetail.rp_contact_made == 1 && (
                    <div style={{ display: "inline-flex" }}>
                      <div className="labels" style={{ marginRight: "0.25rem" }}>
                        CONTACTED
                      </div>
                      <FontAwesomeIcon
                        style={{ marginBottom: "0.20rem" }}
                        icon="check-circle"
                        className="green"
                      />
                    </div>
                  )}
                  {clientDetail && clientDetail.rp_contact_made == 0 && (
                    <div style={{ display: "inline-flex" }}>
                      <Button className="labels" style={{ marginRight: "0.25rem" }}>
                        CLICK HERE IF CONTACTED
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {clientDetail && clientDetail.client_type == "Athlete" && (
                <AthleteDetail clientDetail={clientDetail} />
              )}
              {clientDetail && clientDetail.client_type == "Team" && (
                <TeamDetail clientDetail={clientDetail} />
              )}
              {clientDetail && clientDetail.client_type == "Corporate" && (
                <CorporateDetail clientDetail={clientDetail} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientDetail;
