import React, { useState } from "react";
import ReactSelect from "../../Components/Forms/ReactSelect";
import Input from "../../Components/Forms/Input";
import DatePicker from "../../Components/Forms/DatePicker";
import { Calendar } from "react-date-range";

const MindsetMondayForm = ({ handleChange, handleDateChange, insert_data, formVals }) => {
  const [showToggle, setShowToggle] = useState(false);
  return (
    <>
      <Input
        value={formVals.eventName}
        label="Sale Notes"
        id={"Event Name"}
        onChange={(e) => handleChange("Event Name", e)}
      />
      <DatePicker date={formVals.paymentDate} showToggle={showToggle} setShowToggle={setShowToggle}>
        <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
      </DatePicker>
      <ReactSelect
        options={formVals.originsOfSale}
        value={formVals.saleOrigin}
        placeholder={"Origin of Sale"}
        id={"Origin of Sale"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={formVals.paymentSourceList}
        value={formVals.paymentSource}
        label="paymentSource"
        placeholder={"Payment Source"}
        id={"Payment Source"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={formVals.programList}
        value={formVals.programName}
        label="Program Name"
        placeholder={"Program Name"}
        id={"Program Name"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={formVals.salesRepList}
        value={formVals.salesRep}
        label="Sales Person"
        pr
        placeholder={"Sales Person"}
        id={"Sales Person"}
        handleChange={handleChange}
      />
      {formVals.salesRep.value == "Marketing (957)" ? (
        ""
      ) : (
        <>
          <Input
            value={formVals.commission}
            label="Commission"
            id={"Commission"}
            onChange={handleChange}
            disabled={true}
          />
          <ReactSelect
            options={formVals.commissionOptions}
            value={formVals.percentage}
            placeholder={"Commission Percentage"}
            label="Commission Percentage"
            id={"Commission Percentage"}
            handleChange={handleChange}
          />
        </>
      )}
    </>
  );
};

export default MindsetMondayForm;
