const initialState = {
  thisMonthTotals: [],
  monthlyTotals: [],
  recentSessions: [],
  coachMonthlyPayments: []
};

export const coachDashboardReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_THIS_MONTH_TOTALS':
      return {
        ...state,
        thisMonthTotals: payload
      }
    case 'LOAD_MONTHLY_TOTALS':
      return {
        ...state,
        monthlyTotals: payload
      }
    case 'LOAD_COACH_MONTHLY_PAYMENTS':
      return {
        ...state,
        coachMonthlyPayments: payload
      }
    case 'LOAD_RECENT_SESSIONS':
      return {
        ...state,
        recentSessions: payload
      }
    default:
      return state
  }
};
