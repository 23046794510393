import React, { useState, useEffect } from "react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { useDispatch, useSelector } from "react-redux";
import { months, years } from "../../Utils/arrayItems";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { sessionAction, clientAction } from "../../Redux/actions";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import { clientTypeList, clientStatuses } from "../../Utils/selectConstants";

const SessionsByClient = () => {
  const dispatch = useDispatch();
  const clientSessions = useSelector((state) => state.sessionReducer.sessionsByClient);
  const clientList = useSelector((state) => state.clientReducer.clientList);
  const [clientOptions, setClientOptions] = useState({ value: "", label: "" });
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showToggle, setShowToggle] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [clientTypesSelected, setClientTypesSelected] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    dispatch(clientAction.loadClientListData({ filters: filters }));
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Sessions by Client" } }));
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      let sr_list_db = clientList.map((client) => {
        return {
          value: `${client.full_name} (${client.client_id})`,
          label: `${client.full_name} (${client.client_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setClientOptions(sr_list_db);
    }
  }, [clientList]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "Active", label: "Active" }]);
        } else {
          setStatusSelected(e);
        }
        break;
      case "Clients":
        if (e.length == 0) {
          setClientsSelected([{ value: "All", label: "All" }]);
        } else {
          setClientsSelected(newVals);
        }
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let client_arr = clientsSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        clients: client_arr,
        start_date: [startDate],
        end_date: [endDate],
        page_name: "Sessions by Client"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(sessionAction.loadSessionsByClient({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "clients") {
          setClientsSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(sessionAction.loadSessionsByClient({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(sessionAction.loadSessionsByClient({ filters: filters }));
  }, [applyClicked]);

  return (
    <>
      <AccordionContainer
        values={clientSessions}
        filtersClicked={filtersClicked}
        setFiltersClicked={setFiltersClicked}
        title="SESSIONS BY CLIENT"
        type="SESSIONS BY CLIENT"
        headers={["topic", "worksheet", "session_date", "coach_name"]}
        row_id={"session_id"}
        pathName={"session_detail"}
        handleClick={handleClick}>
        <ReactSelect
          options={clientOptions}
          value={clientsSelected}
          isMulti={true}
          placeholder="Clients"
          handleChange={handleChange}
        />
        <DatePicker
          key={100}
          label={"Date Range"}
          startDate={startDate}
          endDate={endDate}
          showToggle={showToggle}
          setShowToggle={setShowToggle}
          type="range">
          <DateRangePicker
            startDatePlaceholder={startDate}
            endDatePlaceholder={endDate}
            ranges={[
              { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
            ]}
            onChange={(e) => handleChange("Date Range", e)}
            showPreview={false}
          />
        </DatePicker>
        <ReactSelect
          options={clientTypeList}
          value={clientTypesSelected}
          isMulti={true}
          placeholder="Client Type"
          handleChange={handleChange}
        />
        <ReactSelect
          options={clientStatuses}
          value={statusSelected}
          isMulti={true}
          placeholder="Status"
          handleChange={handleChange}
        />
      </AccordionContainer>
    </>
  );
};

export default SessionsByClient;
