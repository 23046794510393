const initialState = {
  blogList: [],
  blogDetail: []
};

export const blogReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_BLOG_LIST':
      return {
        ...state,
        blogList: payload
      }
    case 'LOAD_BLOG_DETAIL':
      return {
        ...state,
        blogDetail: payload
      }
    default:
      return state
  }
};
