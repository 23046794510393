import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction, adminAction, saleAction, filterAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "../../Components/Forms/DatePicker";
import { clientTypeList, sessionTypeList } from "../../Utils/selectConstants";
import { Calendar } from "react-date-range";

const AdminClientAssignmentForm = (props) => {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [clientName, setClientName] = useState(    
    props.location.state && props.location.state.prefill ?
    {
      value: props.location.state.prefill.client_name + ' (' + props.location.state.prefill.client_id +')',
      label: props.location.state.prefill.client_name + ' (' + props.location.state.prefill.client_id +')'
    }
    : { value: "", label: "" }
  );
  const [coachName, setCoachName] = useState({value: "", label: "" });
  const [programName, setProgramName] = useState({value: "", label: ""});
  const [curriculum, setCurriculum] = useState("");
  const [assignmentStartDate, setAssignmentStartDate] = useState(new Date().toLocaleDateString());
  const [assignmentEndDate, setAssignmentEndDate] = useState(new Date().toLocaleDateString());
  const [clientType, setClientType] = useState(
    props.location.state && props.location.state.prefill ?
    {
      value: props.location.state.prefill.client_type,
      label: props.location.state.prefill.client_type
    } : ""
  );
  const [clientList, setClientList] = useState([]);
  const [coachList, setCoachList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const clientList_db = useSelector((state) => state.adminReducer.allClients);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const programList_db = useSelector((state) => state.filterReducer.programList);
  const [resetData, setResetData] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(clientAction.loadClientListData({ filters: { is_active: ["Active"] } }));
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(filterAction.loadProgramList({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    let p_list_db = programList_db.map((program) => {
      return {
        value: `${program.program_name} (${program.program_id})`,
        label: `${program.program_name} (${program.program_id})`
      }
    });
    p_list_db.push({value: "Custom Monthly", label: "Custom Monthly"});
    p_list_db.push({value: "Custom PIF", label: "Custom PIF"});
    p_list_db.push({value: "", label: ""});
    setProgramList(p_list_db);
  }, [programList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e);
        break;
      case "Coach Name":
        setCoachName(e);
        break;
      case "Program Name":
        setProgramName(e);
        break;
      case "Curriculum":
        setCurriculum(e.target.value);
        break;
      case "Client Type":
        setClientType(e);
        break;
      case "Assignment Start Date":
        setAssignmentStartDate(e.target.value);
        break;
      case "Assignment End Date":
        setAssignmentEndDate(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    const req_obj = {
        client_name: clientName.value,
        assignment_type: clientType.value,
        assignment_program: programName.value,
        coach_name: coachName.value,
        assignment_start_date: assignmentStartDate,
        assignment_end_date: assignmentEndDate,
        curriculum: curriculum
      };
      if (
        clientType.value !== "" &&
        clientName.value !== "" &&
        coachName.value !== "" &&
        programName.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all required form fields", { position: "top-center" });
      }
  };

  const reset_data = () => {
    setClientName({value: "", label: ""});
    setCoachName({value: "", label: ""});
    setProgramName({value: "", label: ""});
    setCurriculum("");
    setAssignmentStartDate(new Date().toLocaleDateString());
    setAssignmentEndDate(new Date().toLocaleDateString());
    setClientType({value: "", label: ""});
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_ASSIGNMENT, data: req_obj }))
      .then((res) => {
        if (res.data.status === "Success") {
          toastId.current = toast.success(
            "You’ve successfully inserted an assignment for " + req_obj["client_name"],
            { position: "top-center" }
          );
          reset_data();
        } else {
          toastId.current = toast.error(
            "There was an error inserting an assignment for " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting an assignment", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="CREATE AN ASSIGNMENT" onClick={handleClick}>
      <ReactSelect
        options={clientList}
        value={clientName}
        label="Client Name"
        placeholder={"Client Name"}
        id={"Client Name"}
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientTypeList}
        value={clientType}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={programList}
        value={programName}
        label="Program Name"
        placeholder={"Program Name"}
        id={"Program Name"}
        handleChange={handleChange}
      />
      <Input
        type="text"
        label="Curriculum"
        value={curriculum}
        onChange={(e) => handleChange("Curriculum", e)}
      />
      <ReactSelect
        options={coachList}
        value={coachName}
        label="Coach Name"
        placeholder={"Coach Name"}
        id={"Coach Name"}
        handleChange={handleChange}
      />
      <Input
        type="date"
        label="Assignment Start Date"
        disabled={false}
        value={assignmentStartDate}
        onChange={(e) => handleChange("Assignment Start Date", e)}
      />
      <Input
        type="date"
        label="Assignment End Date"
        disabled={false}
        value={assignmentEndDate}
        onChange={(e) => handleChange("Assignment End Date", e)}
      />
      </FormContainer>
    </>
  );
};

export default AdminClientAssignmentForm;
