import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let clubRunList, clubRunDetail;

let { resetLoader, setLoader } = loaderAction;

const loadClubRunListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadClubRunList'))
  if (typeof clubRunList != typeof undefined) {
    clubRunList.cancel('The load operation has been canceled due to a new request')
  }
  clubRunList = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.CLUB_RUN_LIST, data: filters, token: clubRunList.token})).then((res) => {
    dispatch(populateClubRunListData(res['data']['result']));
    dispatch(resetLoader('loadClubRunList'));
  })
  .catch(err => console.log(err))
};

const loadClubRunDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadClubRunDetail'))
  if (typeof clubRunDetail != typeof undefined) {
    clubRunDetail.cancel('The load operation has been canceled due to a new request')
  }
  clubRunDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.CLUB_RUN_DETAIL, data: filters, token: clubRunDetail.token})).then((res) => {
    dispatch(populateClubRunDetailData(res['data']['result']));
    dispatch(resetLoader('loadClubRunDetail'));
  })
  .catch(err => console.log(err))
};

// const updateSessionDetailData = data => dispatch => {
//   axios(requestOptions({method:'POST', url:apiConstants.UPDATE_SESSION_DETAIL,data}))
//   .then(res => {
//     dispatch(populateUpdatedCoachData(data['request']))
//   })
//   .catch(err => {
//     console.log(err)
//   })
// }

// const populateUpdatedCoachData = data => ({
//   type: 'UPDATE_COACH_DETAIL',
//   payload: data
// })

const populateClubRunListData = (data) => ({
  type: 'LOAD_CLUB_RUN_LIST',
  payload: data
});

const populateClubRunDetailData = (data) => ({
  type: 'LOAD_CLUB_RUN_DETAIL',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const clubRunAction = {
  loadClubRunListData,
  loadClubRunDetailData,
  resetData
}
