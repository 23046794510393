import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./MiscellaneousTimeDetail.scss";
import { coachDetailAction } from "../../Redux/actions";
import MiscellaneousTimeDetailForm from "./MiscellaneousTimeDetailForm";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

const MiscellaneousTimeDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const miscellaneousTimeDetail = useSelector((state) => state.coachDetailReducer.miscellaneousTimeDetail);
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(
        coachDetailAction.loadMiscellaneousTimeDetailData({ filters: { miscellaneous_time_id: props.location.state.id } })
    );
  }, [detailEdited, dispatch]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  const refresh = () => {
    dispatch(
        coachDetailAction.loadMiscellaneousTimeDetailData({ filters: { miscellaneous_time_id: props.location.state.id } })
    );
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_MISCELLANEOUS_TIME, data: {'miscellaneous_time_id': miscellaneousTimeDetail.id} }))
      .then((res) => {
        if (res) {
          history.push("/miscellaneous_time_list")
        } else {
          toastId.current = toast.error(
            "There was an error deleting the miscellaneous time",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the miscellaneous time data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="session-title">MISCELLANEOUS TIME DETAIL</div>
      <div className="session-container">
        <div className="items">
          <div className="top-container">
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <MiscellaneousTimeDetailForm
              miscellaneousTimeDetail={miscellaneousTimeDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
          ) : (
            <>
              <div className="labels mt-3">Coach Name</div>
              <div className="mt-2">{miscellaneousTimeDetail.coach_name}</div>
              <div className="labels mt-3">Datetime Start</div>
              <div className="mt-2">{miscellaneousTimeDetail.datetime_start}</div>
              <div className="labels mt-3">Datetime End</div>
              <div className="mt-3">{miscellaneousTimeDetail.datetime_end}</div>
              <div className="labels mt-3">Logged On/At</div>
              <div className="mt-3">{miscellaneousTimeDetail.inserted_at}</div>
              <div className="labels mt-3">Description</div>
              <div className="mt-3">{miscellaneousTimeDetail.description}</div>
              <div className="labels mt-3">Total Minutes</div>
              <div className="mt-3">{miscellaneousTimeDetail.total_minutes}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MiscellaneousTimeDetail;
