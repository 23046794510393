import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import "./Components.scss"

const Filters = ({ children, onClick }) => {

  return (
    <div className="filters-page mt-4">
      <p style={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}>SET FILTERS</p>
      <div className="filters-container">
        {children}
        <Row>
          <Col>
            <Button id={"back"} onClick={onClick}>BACK</Button>
          </Col>
          <Col>
            <Button id={"apply"} onClick={onClick}>APPLY</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Filters;
