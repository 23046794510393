import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";

const AdminClubRunsByCoach = (props) => {
  const dispatch = useDispatch();
  const coachClubRuns = useSelector((state) => state.adminReducer.adminClubRunsByCoach);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [coachesSelected, setCoachesSelected] = useState([{ value: "All", label: "All" }]);
  const [filters, setFilters] = useState({});
  const [applyClicked, setApplyClicked] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Admin Club Runs by Coach" } })
    );
  }, []);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Coach":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        coaches: coaches_arr,
        page_name: "Admin Club Runs by Coach"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminClubRunsByCoach({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "coaches") {
          setCoachesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminClubRunsByCoach({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminClubRunsByCoach({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={coachClubRuns}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      handleClick={handleClick}
      title="EVENTS BY COACH"
      type="EVENTS"
      headers={["event_type", "club_run_date"]}
      row_id="club_run_id"
      pathName={"event_detail"}
      admin={true}>
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Coach"
        handleChange={handleChange}
      />
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        onChange={(e) => handleChange("Date Range", e)}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
    </AccordionContainer>
  );
};

export default AdminClubRunsByCoach;
