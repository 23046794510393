const initialState = {
  saleList: [],
  saleDetail: [],
  programList: [],
  salesHours: [],
  salesHoursDetail: [],
  myExpiringSubscriptions: [],
  programDetail: []
};

export const saleReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_SALE_LIST':
      return {
        ...state,
        saleList: payload
      }
    case 'LOAD_SALE_DETAIL':
      return {
        ...state,
        saleDetail: payload
      }
    case 'LOAD_PROGRAMS':
      return {
        ...state,
        programList: payload
      }
    case 'LOAD_PROGRAM_DETAIL':
      return {
        ...state,
        programDetail: payload
      }
    case 'LOAD_SALES_HOURS_LIST':
      return {
        ...state,
        salesHours: payload
      }
    case 'LOAD_SALES_HOURS_DETAIL':
      return {
        ...state,
        salesHoursDetail: payload
      }
    case 'LOAD_MY_EXPIRING_SUBSCRIPTIONS':
      return {
        ...state,
        myExpiringSubscriptions: payload
      }
    // case 'UPDATE_COACH_DETAIL':
    //   return {
    //     ...state,
    //     updatedCoachDetail: payload
    //   }
    default:
      return state
  }
};
