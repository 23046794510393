import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { eventTypeList, sportOptions } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

const ClubRunDetailForm = ({ clubRunDetail, clicked, setClicked, refresh }) => {
  const [sport, setSport] = useState({label: clubRunDetail.sport, value: clubRunDetail.sport});
  const [date, setDate] = useState(clubRunDetail.club_run_date);
  const [name, setName] = useState(clubRunDetail.club_name);
  const [topic, setTopic] = useState(clubRunDetail.topic);
  const [notes, setNotes] = useState(clubRunDetail.notes);
  const [eventType, setEventType] = useState({value: clubRunDetail.event_type, label: clubRunDetail.event_type});
  const [signups, setSignups] = useState(clubRunDetail.sign_ups);
  const toastId = useRef(null);

  const onClick = (e) => {
    const req_obj = {
      club_run_id: clubRunDetail.club_run_id,
      sport: sport.value,
      club_run_date: date,
      club_name: name,
      topic: topic,
      notes: notes,
      sign_ups: signups,
      event_type: eventType.value
    };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_CLUB_RUN_DETAIL, data: req_obj })
    ).then((res) => {
      if (res) {
        refresh();
        setClicked(!clicked);
      } else {
        toastId.current = toast.error(
          "There was an error updating the event",
          { position: "top-center" }
        );
      }
    }).catch((err) => {
        toastId.current = toast.error("There was an error updating the event", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Sport":
        setSport(e);
        break;
      case "Event Type":
        setEventType(e);
        break;
      case "Event Date":
        setDate(e.target.value);
        break;
      case "Event Name":
        setName(e.target.value);
        break;
      case "Topic":
        setTopic(e.target.value);
        break;
      case "Notes":
        setNotes(e.target.value);
        break;
      case "Number of Signups":
        setSignups(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
      <>
        <ReactSelect
          options={sportOptions}
          value={sport}
          placeholder="Sport"
          handleChange={handleChange}
        />
        <Input
          label="Event Date"
          id="Event Date"
          type="date"
          value={date}
          disabled={false}
          onChange={(e) => handleChange("Event Date", e)}
        />
        <Input
          label="Event Name"
          id="Event Name"
          type="text"
          value={name}
          onChange={(e) => handleChange("Event Name", e)}
        />
        <ReactSelect
          options={eventTypeList}
          value={eventType}
          placeholder="Event Type"
          handleChange={handleChange}
        />
        <Input
          label="Topic"
          id="Topic"
          type="text"
          value={topic}
          onChange={(e) => handleChange("Topic", e)}
        />
        <TextArea
          height={'300px'}
          label="Notes"
          id="Notes"
          type="text"
          value={notes}
          onChange={(e) => handleChange("Notes", e)}
        />
        <Input
          label="Number of Signups"
          id="Number of Signups"
          type="number"
          value={signups}
          onChange={(e) => handleChange("Number of Signups", e)}
        />
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
      </>
  );
};

export default ClubRunDetailForm;
