export const requestOptions = (reqObj) => ({
  method: reqObj.method,
  url: reqObj.url,
  data: reqObj.data,
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem('okta-token-storage')).accessToken
      .accessToken
    }`,
    'Content-Type': 'application/json',
  },
  cancelToken: reqObj.token ? reqObj.token : ''
});
