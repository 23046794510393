import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { saleAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";

const MyExpiringSubscriptions = () => {
  const dispatch = useDispatch();
  const coachSales = useSelector((state) => state.saleReducer.myExpiringSubscriptions);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [coachesSelected, setCoachesSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [applyClicked, setApplyClicked] = useState(false);

  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "My Expiring Subscriptions" } })
    );
  }, []);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Salesperson":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let filtersObj = {
        page_name: "My Expiring Subscriptions"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(saleAction.loadMyExpiringSubscriptions({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(saleAction.loadMyExpiringSubscriptions({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(saleAction.loadMyExpiringSubscriptions({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={coachSales}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="MY EXPIRING PAID IN FULLS"
      type="EXPIRING SUBSCRIPTIONS"
      headers={["client_name", "expiration", "assigned_coach_name"]}
      row_id="sale_id"
      pathName="sale_detail"
      admin={true}
      handleClick={handleClick}></AccordionContainer>
  );
};

export default MyExpiringSubscriptions;
