import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { adminAction, paymentAdjustmentAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import { adjustmentTypeOptions } from "../../Utils/selectConstants";
import DatePicker from "../../Components/Forms/DatePicker";
import { Calendar } from "react-date-range";
import "react-toastify/dist/ReactToastify.css";

const PaymentAdjustmentForm = (props) => {
  const [paymentAdjustmentDate, setPaymentAdjustmentDate] = useState(
    new Date().toLocaleDateString()
  );
  const [coachName, setCoachName] = useState("");
  const [adjustmentPrice, setAdjustmentPrice] = useState(0);
  const [adjustmentNotes, setAdjustmentNotes] = useState("");
  const [adjustmentType, setAdjustmentType] = useState({ value: "", label: "" });
  const dispatch = useDispatch();
  const [coachList, setCoachList] = useState([]);
  const toastId = useRef(null);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const [resetData, setResetData] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    // dispatch(adminAction.resetData())
    dispatch(adminAction.loadAllCoaches({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let c_list_db = coachList_db?.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      c_list_db.push({ value: "", label: "" });
      setCoachList(c_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Coach Name":
        setCoachName(e);
        break;
      case "Adjustment Notes":
        setAdjustmentNotes(e.target.value);
        break;
      case "Adjustment Type":
        setAdjustmentType(e);
        break;
      case "Adjustment Price":
        setAdjustmentPrice(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setPaymentAdjustmentDate(selectedDate.toLocaleDateString());
  };

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        coach_name: coachName.value,
        adjustment_date: paymentAdjustmentDate,
        adjustment_notes: adjustmentNotes,
        adjustment_price: adjustmentPrice,
        adjustment_type: adjustmentType.value
      };
      // console.log(req_obj);
      if (coachName.value !== "" && paymentAdjustmentDate !== "" && adjustmentPrice !== "") {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all required form fields", {
          position: "top-center"
        });
      }
    } else {
      toastId.current = toast.info(
        "You've cleared the form. All fields have been reset to their default values"
      );
      reset_data();
    }
  };

  const reset_data = () => {
    setCoachName({ value: "", label: "" });
    setPaymentAdjustmentDate(new Date().toLocaleDateString());
    setAdjustmentPrice(0);
    setAdjustmentNotes("");
    setAdjustmentType({ value: "", label: "" });
  };

  const insert_data = (req_obj) => {
    axios(
      requestOptions({
        method: "POST",
        url: API_CONSTANTS.INSERT_PAYMENT_ADJUSTMENT,
        data: req_obj
      })
    )
      .then((res) => {
        if (res) {
          console.log(res);
          if (res.data.message === "Success") {
            toastId.current = toast.success(
              "You’ve successfully inserted a new payment adjustment for " + req_obj["coach_name"],
              { position: "top-center" }
            );
            reset_data();
          } else if (res.data.message === "Duplicates") {
            toastId.current = toast.warning(
              "You’ve entered info that matches a payment adjustment that already exists in the database. Please view the Payment Adjustment Archive to see the existing adjustment",
              { position: "top-center" }
            );
          }
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new payment adjustment for " + req_obj["coach_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting adjustment data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="LOG A PAYMENT ADJUSTMENT" onClick={handleClick}>
        <DatePicker
          date={paymentAdjustmentDate}
          showToggle={showToggle}
          setShowToggle={setShowToggle}>
          <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
        </DatePicker>
        <ReactSelect
          options={coachList}
          value={coachName}
          label="Coach Name"
          placeholder={"Coach Name"}
          id={"Coach Name"}
          handleChange={handleChange}
        />
        <Input
          value={adjustmentPrice}
          label="Adjustment Price"
          id={"Adjustment Price"}
          onChange={(e) => handleChange("Adjustment Price", e)}
        />
        <Input
          value={adjustmentNotes}
          label="Adjustment Notes"
          id={"Adjustment Notes"}
          onChange={(e) => handleChange("Adjustment Notes", e)}
        />
        <ReactSelect
          options={adjustmentTypeOptions}
          value={adjustmentType}
          label="Adjustment Type"
          placeholder={"Adjustment Type"}
          id={"Adjustment Type"}
          handleChange={handleChange}
        />
      </FormContainer>
    </>
  );
};

export default PaymentAdjustmentForm;
