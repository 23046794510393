export default function getTopicOptions(sessionType) {
  let topicOptions = [];
  let topicOptionsMap = [];
  switch (sessionType.value) {
    case "Athlete 1:1":
      topicOptionsMap = [
        "",
        "Aggressiveness",
        "Clarity",
        "Confidence",
        "Goal Setting",
        "Injury",
        "Mental Toughness",
        "Motivation",
        "Other",
        "Present Moment",
        "Q&A",
        "Relaxing Under Pressure",
        "Self-Knowledge",
        "Sleep"
      ];
      topicOptions = topicOptionsMap.map((option) => {
        return { value: option, label: option };
      });
      break;
    case "Corporate 1:1":
      topicOptionsMap = [
        "",
        "Confidence",
        "Mental Toughness",
        "Motivation",
        "Other",
        "Present Moment",
        "Relaxing Under Pressure",
        "Self-Knowledge"
      ];
      topicOptions = topicOptionsMap.map((option) => {
        return { value: option, label: option };
      });
      break;
    case "Team In Person":
      topicOptionsMap = [
        "",
        "Clarity",
        "Confidence",
        "Goal Setting",
        "Injury",
        "Mental Toughness",
        "Motivation",
        "Other",
        "Present Moment",
        "Relaxing Under Pressure",
        "Self-Knowledge",
        "Sleep"
      ];
      topicOptions = topicOptionsMap.map((option) => {
        return { value: option, label: option };
      });
      break;
    case "Team in Person":
      topicOptionsMap = [
        "",
        "Clarity",
        "Confidence",
        "Goal Setting",
        "Injury",
        "Mental Toughness",
        "Motivation",
        "Other",
        "Present Moment",
        "Relaxing Under Pressure",
        "Self-Knowledge",
        "Sleep"
      ];
      topicOptions = topicOptionsMap.map((option) => {
        return { value: option, label: option };
      });
      break;
    case "Team Video Conference":
      topicOptionsMap = [
        "",
        "Aggressiveness",
        "Clarity",
        "Confidence",
        "Goal Setting",
        "Injury",
        "Mental Toughness",
        "Motivation",
        "Other",
        "Present Moment",
        "Q&A",
        "Relaxing Under Pressure",
        "Self-Knowledge",
        "Sleep"
      ];
      topicOptions = topicOptionsMap.map((option) => {
        return { value: option, label: option };
      });
      break;
    default:
      break;
  }
  return topicOptions;
}
