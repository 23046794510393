import React, { useState, useEffect } from "react";
import { Button, Col, Container, Image, Table, Row } from "react-bootstrap";
import "./ResourceCenter.scss";
import Logo from '../../assets/wm-logo.jpg';
import { Link } from "react-router-dom";

const ResourceCenter = (props) => {
    return (
        <Container className='resource-center-container'>
          <Row className='header-container mt-4'>
            <Container>
              <div className='header-1'>
                Mindset Coaching Resources
              </div>
            </Container>
          </Row>
          <Row className='header-container mt-4'>
            <Container className='logo'>
              <Image src={Logo} className="wm-logo" />
            </Container>
          </Row>
          <Row className='header-container mt-4'>
            <Container>
              <div className='header-2'>
                Curriculums and Topics
              </div>
              <hr/>
            </Container>
          </Row>
          <Row className='nav-link-container mt-3'>
                <ul className='nav-link-list'>
                  <li>
                    <a href='https://drive.google.com/drive/folders/1AbQnzpyalr8yYHtE9EXmm32Ruy5xA5hg?usp=sharing' target="_blank" rel="noopener noreferrer">
                      Individual Curriculums
                    </a>
                    <ul>
                      <li>
                        <a href='https://drive.google.com/drive/folders/1Xbf4IHUMa2BiOf4d9VGmtVS42NodA8Fn' target="_blank" rel="noopener noreferrer">
                          Wrestling Curriculum
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href='https://drive.google.com/drive/folders/1NX6Mv34XzqJoBEluy3eBOnUM8QPapBCW?usp=sharing' target="_blank" rel="noopener noreferrer">
                      Team Curriculums
                    </a>
                  </li>
                  <li>
                    <a href='https://docs.google.com/document/d/13Rh07rN13H22n5ktBFY87wnPSvuLyBlvd_Pzwm-hvwM/edit' target="_blank" rel="noopener noreferrer">
                      Client Session Breakdown
                    </a>
                  </li>
                  <li>
                    <a href='https://docs.google.com/document/d/1Y_j_OwkCzmpnKi9lPogYCGIZNInp0EollPeA_9V_ue4/edit' target="_blank" rel="noopener noreferrer">
                      Lesson Plans for All Topics
                    </a>
                  </li>
                  <li>
                    <a href='https://drive.google.com/drive/u/0/folders/1lQI988D6NLhBgiyVfM_y8EonzfeqYQAV' target="_blank" rel="noopener noreferrer">
                      Videos Covering All Topics
                    </a>
                  </li>
                  <li>
                    <a href='https://docs.google.com/document/d/1QlJB3gnGBm1Q2o2qKb-y6H1aM12WYX6T4eB-qrhswtg/edit' target="_blank" rel="noopener noreferrer">
                      Team Client Cheat Sheet
                    </a>
                  </li>
                </ul>
          </Row>
          <Row className='header-container mt-4'>
            <Container>
              <div className='header-2'>
                Coach Tips and Procedures
              </div>
              <hr/>
            </Container>
          </Row>
          <Row className='nav-link-container mt-3'>
            <ul className='nav-link-list'>
              <li>
                <a href='https://docs.google.com/document/d/1I4HLiu6zX3p42emhAv14Xjxq9bX2-W-kvVeiLeFSmKQ/edit#' target="_blank" rel="noopener noreferrer">
                  Mindset Coaching Responsibilities
                </a>
              </li>
              <li>
                <a href='https://docs.google.com/document/d/1GG9C68Bdtyn35rMNZcVPsgzj-EF6wV83hb-VWJz4ccU/edit' target="_blank" rel="noopener noreferrer">
                  WM Coaches Handbook
                </a>
              </li>
              <li>
                <a href='https://drive.google.com/drive/folders/15N3MGGpdZZ2pKJVueFFFSLwzc2kyYwAl?usp=sharing' target="_blank" rel="noopener noreferrer">
                  Resources for Ongoing Training
                </a>
              </li>
              <li>
                <a href='https://docs.google.com/document/d/1CXrzQoIdNFfCWBZyyxiWXUN3snoiDPM4kvW9wRYS9FU/edit' target="_blank" rel="noopener noreferrer">
                  Mindset Coaching FAQ
                </a>
              </li>
              <li>
                <a href='https://docs.google.com/document/d/1Hh__RfLl3AZDhR8Gdf9bQSM5hBOYp2nI2Pgv34ZB9Vw/edit' target="_blank" rel="noopener noreferrer">
                  Club Presentations
                </a>
              </li>
              <li>
                <a href='https://docs.google.com/document/d/1Qf7-jbao_7piOWr7YZICBslWdtx3XkiYsblslIiKjPY/edit' target="_blank" rel="noopener noreferrer">
                  Mindset Quotes
                </a>
              </li>
              <li>
                <a href='https://drive.google.com/drive/folders/1sr8MWzFweLOW3PTxhFAPG7tF2AIDVTvK?usp=sharing' target="_blank" rel="noopener noreferrer">
                  Red Flags for All Sports
                </a>
              </li>
              <li>
                Coaching Certification
                <ul>
                  <li>
                    <a href='https://youtu.be/y9a-Og4UIXM' target="_blank" rel="noopener noreferrer">
                      Day 1
                    </a>
                  </li>
                  <li>
                    <a href='https://youtu.be/Y7Kisz2r1ac' target="_blank" rel="noopener noreferrer">
                      Day 2
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://docs.google.com/document/d/1r_dRWrfyjpm1DhblcEpRDrTUSvTe4_ObtlsZWG5PqmU/edit#' target="_blank" rel="noopener noreferrer">
                  Mindset Coaching Cheat Sheet
                </a>
              </li>
              <li>
                Coaches Training Meetings
                <ul>
                  <li>
                    <a href='https://youtu.be/9GU18uMY0rU' target="_blank" rel="noopener noreferrer">
                      Jake Meeting 1
                    </a>
                  </li>
                  <li>
                    <a href='https://youtu.be/fsnIKYaysxU' target="_blank" rel="noopener noreferrer">
                      Jake Meeting 2
                    </a>
                  </li>
                  <li>
                    <a href='https://youtu.be/7LbagSmPEJk' target="_blank" rel="noopener noreferrer">
                      Anthony Meeting 1
                    </a>
                  </li>
                  <li>
                    <a href='https://youtu.be/4Hg-POU1YL8' target="_blank" rel="noopener noreferrer">
                      Anthony Meeting 2
                    </a>
                    </li>
                  <li>
                    <a href='https://www.youtube.com/watch?v=Xsed1-EJGVU&feature=youtu.be' target="_blank" rel="noopener noreferrer">
                      Eric Meeting 1
                    </a>
                  </li>
                  <li>
                    <a href='https://youtu.be/SiKxwBzWDvQ' target="_blank" rel="noopener noreferrer">
                      Eric Meeting 2
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='https://youtube.com/live/1UGXm_6pkzE' target="_blank" rel="noopener noreferrer">
                  7 Tips for Client First Impressions
                </a>
              </li>
            </ul>
          </Row>
          <Row className='header-container mt-4'>
            <Container>
              <div className='header-2'>
                  Sales Training
              </div>
              <hr/>
            </Container>
          </Row>
          <Row className='nav-link-container mt-3'>
            <ul className='nav-link-list'>
              <li>
                <a href='https://youtu.be/RPCAHMFmEuI' target="_blank" rel="noopener noreferrer">
                  Sales Bootcamp
                </a>
              </li>
              <li>
                <a href='https://youtu.be/3Q59zKNsNkY' target="_blank" rel="noopener noreferrer">
                  Sales Training
                </a>
              </li>
              <li>
                <a href='https://docs.google.com/document/d/1txz3wIq4RMhfBPiMzoflQuzdkO41ybVEzRcsnrP_Yvg/edit' target="_blank" rel="noopener noreferrer">
                  Sales Cheat Sheet
                </a>
              </li>
            </ul>
          </Row>
          <Row className='header-container mt-4'>
            <Container>
              <div className='header-2'>
                  Client Reminders
              </div>
              <hr/>
            </Container>
          </Row>
          <Row className='nav-link-container mt-3'>
            <ul className='nav-link-list'>
              <li>
                New clients should be contacted within 24 hours of being displayed under <Link to="/clients">My Clients</Link>.
                However, if it is possible, they should be contacted the same day they are assigned.
              </li>
              <li>
                When onboarding a new client you should set up an initial call with the parents.
              </li>
              <li>
                If you haven&#39;t been able to connect with a client in three weeks, please tell Jake and/or Anthony
              </li>
              <li>
                If a client mentions canceling, communicate this to Jake and/or Anthony.
                DO NOT ask them to email or call the salesperson or someone on the management team.
              </li>
              <li>
                All sessions should be logged on or before midnight on the last day of the month.
              </li>
              <li>
                Regarding a client, two sessions cannot be completed in the same day.
              </li>
            </ul>
          </Row>
          <Row className='header-container mt-4'>
            <Container>
              <div className='header-2'>
                Management Team
              </div>
              <hr/>
            </Container>
          </Row>
          <Row className='nav-link-container mt-3 mb-5'>
            <div className='header-3'>Gene & Jeff Zannetti: Co-Founders and Owners</div>
            <ul className='nav-link-list'>
              <li>
                Gene&#39;s Email: &nbsp;
                <a href='mailto:genezannetti@gmail.com'>
                genezannetti@gmail.com
                </a>
                <ul>
                  <li>
                    Gene hosts monthly conference calls at 8PM and 8:30PM Eastern.
                    If you have questions, please utilize that time to ask them.
                  </li>
                </ul>
              </li>
              <li>
                Jeff&#39;s Email: &nbsp;
                <a href='mailto:jeff.zannetti@gmail.com'>
                  jeff.zannetti@gmail.com
                </a>
              </li>
            </ul>
            <div className='header-3'>Jake Hunter: Chief Operating Officer</div>
            <ul className='nav-link-list'>
              <li>Email:&nbsp;              
                <a href='mailto:jake@wrestlingmindset.com'>
                  jake@wrestlingmindset.com
                </a>
              </li>
              <li>
                Phone: 267-361-7237
              </li>
            </ul>
            <div className='header-3'>Anthony Mastrangelo: Director of Operations</div>
            <ul className='nav-link-list'>
              <li>Email:&nbsp;
                <a href='mailto:anthony@wrestlingmindset.com'>
                  anthony@wrestlingmindset.com
                </a>
              </li>
              <li>Phone: 610-213-6181</li>
            </ul>
          </Row>

        </Container>
      );
};


export default ResourceCenter;