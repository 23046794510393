import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let persona;

let { resetLoader, setLoader } = loaderAction;

const loadPersonaData = (filters) => (dispatch) => {
  dispatch(setLoader('loadPersona'))
  if (typeof persona != typeof undefined) {
    persona.cancel('The load operation has been canceled due to a new request')
  }
  persona = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.LOAD_PERSONA, data: filters, token: persona.token})).then((res) => {
    dispatch(populatePersonaData(res['data']['result']));
    dispatch(resetLoader('loadPersona'));
  })
  .catch(err => console.log(err))
};

const populatePersonaData = (data) => ({
  type: 'LOAD_PERSONA',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const authAction = {
  loadPersonaData,
  resetData
}
