import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const PaymentReceivedForm = ({
  modalShow,
  handleClose,
  activePayment,
  updateData,
  onFormChange
}) => {
  return (
    <Modal show={modalShow} onHide={handleClose}>
      <Form onSubmit={(e) => updateData(e, activePayment.clientAppId)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {activePayment.client_name} - {activePayment.payment_date}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="payment">
            <Form.Label>Payment Received?</Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              name="payment"
              value="yes"
              onChange={onFormChange}
            />
            <Form.Check type="radio" label="No" name="payment" value="no" onChange={onFormChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              id="comments"
              name="comments"
              label="Comments"
              onChange={(e) => onFormChange(e)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PaymentReceivedForm;
