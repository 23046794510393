import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./ReimbursementDetail.scss";
import ReimbursementDetailForm from "./ReimbursementDetailForm";
import { sessionAction, adminAction } from "../../Redux/actions";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

const ReimbursementDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const reimbursementDetail = useSelector((state) => state.sessionReducer.reimbursementDetail);
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(
      sessionAction.loadReimbursementDetailData({ filters: { reimbursement_id: props.location.state.id } })
    );
  }, [detailEdited, dispatch]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  const refresh = () => {
    sessionAction.loadReimbursementDetailData({ filters: { reimbursement_id: props.location.state.id } })
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_REIMBURSEMENT, data: {'reimbursement_id': props.location.state.id} }))
      .then((res) => {
        if (res) {
          if (props.location.state && props.location.state.admin == true) {
            history.push("/admin_reimbursements")
          }
          else {
            history.push("/reimbursements")
          }
        } else {
          toastId.current = toast.error(
            "There was an error deleting the reimbursement " + reimbursementDetail.id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the reimbursement data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="session-title">REIMBURSEMENT DETAIL</div>
      <div className="session-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Coach</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <ReimbursementDetailForm
              reimbursementDetail={reimbursementDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
          ) : (
            <>
              <div className="mt-3">{reimbursementDetail.coach_name}</div>
              <div className="labels mt-3">Date</div>
              <div className="mt-2">{reimbursementDetail.reimbursement_date}</div>
              <div className="labels mt-3">Reimbursement Type</div>
              <div className="mt-3">{reimbursementDetail.reimbursement_type}</div>
              <div className="labels mt-3">Event Name</div>
              <div className="mt-3">{reimbursementDetail.event_name}</div>
              <div className="labels mt-3">Travel Mileage</div>
              <div className="mt-3">
                {reimbursementDetail.travel_mileage !== 0 ? reimbursementDetail.travel_mileage :  'Not Applicable'}
              </div>
              <div className="labels mt-3">Reimbursement Amount ($)</div>
              <div className="mt-3">
                {reimbursementDetail?.reimbursement_price}
              </div>
              <div className="labels mt-3">Notes:</div>
              <div className="mt-3">{reimbursementDetail.reimbursement_notes}</div>
              <div className="labels mt-3">Logged Date</div>
              <div className="mt-3">{reimbursementDetail.inserted_at}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReimbursementDetail;
