import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { saleAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { insert_data } from "../../Utils/insertData";
import { months, years } from "../../Utils/arrayItems";

const SalesList = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.persona);
  const saleList = useSelector((state) => state.saleReducer.saleList);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [applyClicked, setApplyClicked] = useState(false);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "My Sales" } }));
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        page_name: "My Sales"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(saleAction.loadSaleListData({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(saleAction.loadSaleListData({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(saleAction.loadSaleListData({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={saleList}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="SALES BY COACH"
      type="SALES"
      headers={["client_name", "commission"]}
      row_id={"sale_id"}
      linkName={"sale"}
      pathName={"sale_detail"}
      admin={userInfo.is_admin === "1"}
      handleClick={handleClick}>
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
    </AccordionContainer>
  );
};

export default SalesList;
