import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let filterData, savedUserFilters, programList;

let { resetLoader, setLoader } = loaderAction;

const loadFilterData = (filters) => (dispatch) => {
  dispatch(setLoader('loadFilterData'))
  if (typeof filterData != typeof undefined) {
    filterData.cancel('The load operation has been canceled due to a new request')
  }
  filterData = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.LOAD_FILTER_DATA, data: filters, token: filterData.token})).then((res) => {
    dispatch(populateFilterData(res['data']['result']));
    dispatch(resetLoader('loadFilterData'));
  })
  .catch(err => console.log(err))
};

const loadSavedUserFilters = (filters) => (dispatch) => {
  dispatch(setLoader('loadSavedUserFilters'))
  if (typeof savedUserFilters != typeof undefined) {
    savedUserFilters.cancel('The load operation has been canceled due to a new request')
  }
  savedUserFilters = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.LOAD_SAVED_USER_FILTERS, data: filters, token: savedUserFilters.token})).then((res) => {
    dispatch(populateSavedUserFilters(res['data']['result']));
    dispatch(resetLoader('loadSavedUserFilters'));
  })
  .catch(err => console.log(err))
};

const loadProgramList = (filters) => (dispatch) => {
  dispatch(setLoader('loadProgramList'))
  if (typeof programList != typeof undefined) {
    programList.cancel('The load operation has been canceled due to a new request')
  }
  programList = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.PROGRAM_LIST, data: filters, token: programList.token})).then((res) => {
    dispatch(populateProgramList(res['data']['result']));
    dispatch(resetLoader('loadProgramList'));
  })
  .catch(err => console.log(err))
};

const populateFilterData = (data) => ({
  type: 'LOAD_FILTER_DATA',
  payload: data
});

const populateSavedUserFilters = (data) => ({
  type: 'LOAD_SAVED_USER_FILTERS',
  payload: data
});

const populateProgramList = (data) => ({
  type: 'LOAD_PROGRAM_LIST',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const filterAction = {
  loadFilterData,
  loadSavedUserFilters,
  loadProgramList,
  resetData
}
