import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const SessionCommissionDetailForm = ({ sessionCommissionDetail, clicked, setClicked, refresh }) => {
    const [coachName, setCoachName] = useState(
      {
        value: sessionCommissionDetail.coach_name + " (" + sessionCommissionDetail.coach_id + ")",
        label: sessionCommissionDetail.coach_name + " (" + sessionCommissionDetail.coach_id + ")"
      }
    );
    const [individual, setIndividual] = useState(sessionCommissionDetail.individual);
    const [teamInPerson, setTeamInPerson] = useState(sessionCommissionDetail.team_in_person);
    const [teamVideo, setTeamVideo] = useState(sessionCommissionDetail.team_video);
    const [corporate, setCorporate] = useState(sessionCommissionDetail.corporate);
    const [events, setEvents] = useState(sessionCommissionDetail.club_run);
    const [sessionCommissionStart, setSessionCommissionStart] = useState(sessionCommissionDetail.session_commission_start_date);
    const [sessionCommissionEnd, setSessionCommissionEnd] = useState(sessionCommissionDetail.session_commission_end_date);
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const onClick = (e) => {
    const req_obj = {
        coach_name: coachName,
        session_commission_id: sessionCommissionDetail.session_commission_id,
        individual: individual,
        team_in_person: teamInPerson,
        team_video: teamVideo,
        corporate: corporate,
        club_run: events,
        session_commission_start_date: sessionCommissionStart,
        session_commission_end_date: sessionCommissionEnd
      };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_SESSION_COMMISSION, data: req_obj })
    ).then((res) => {
      if (res) {
        refresh();
        setClicked(!clicked);
      } else {
        toastId.current = toast.error(
          "There was an error updating the session commission rate",
          { position: "top-center" }
        );
      }
    }).catch((err) => {
        toastId.current = toast.error("There was an error updating the session commission rate", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Individual":
            setIndividual(e.target.value);
            break;
        case "Team In-Person":
            setTeamInPerson(e.target.value);
            break;
        case "Team Video":
            setTeamVideo(e.target.value);
            break;
        case "Corporate":
            setCorporate(e.target.value);
            break;
        case "Events/Club Runs":
            setEvents(e.target.value);
            break;
        default:
            break;
    }
  };

  const handleStartChange = (e) => {
    const selectedDate = new Date(e);
    setSessionCommissionStart(selectedDate.toLocaleDateString());
  };
  const handleEndChange = (e) => {
    const selectedDate = new Date(e);
    setSessionCommissionEnd(selectedDate.toLocaleDateString());
  };

  return (
      <>
        <Input
            value={coachName.value}
            label="Coach"
            id={"Coach"}
            onChange={(e) => handleChange("Coach", e)}
            disabled={true}
        />
        <Input
            value={individual}
            label="Individual"
            id={"Individual"}
            onChange={(e) => handleChange("Individual", e)}
        />
        <Input
            value={teamInPerson}
            label="Team In-Person"
            id={"Team In-Person"}
            onChange={(e) => handleChange("Team In-Person", e)}
        />
        <Input
            value={teamVideo}
            label="Team Video"
            id={"Team Video"}
            onChange={(e) => handleChange("Team Video", e)}
        />
        <Input
            value={corporate}
            label="Corporate"
            id={"Corporate"}
            onChange={(e) => handleChange("Corporate", e)}
        />
        <Input
            value={events}
            label="Events/Club Runs"
            id={"Events/Club Runs"}
            onChange={(e) => handleChange("Events/Club Runs", e)}
        />
        <DatePicker
          date={sessionCommissionStart}
          showToggle={showToggle1}
          setShowToggle={setShowToggle1}
          label={"Session Commission Start Date"}
          title={"COMMISSION START"}>
          <Calendar showPreview={false} onChange={(e) => handleStartChange(e)} />
        </DatePicker>
        <DatePicker
          date={sessionCommissionEnd}
          showToggle={showToggle2}
          setShowToggle={setShowToggle2}
          label={"Session Commission End Date"}
          title={"COMMISSION END"}>
          <Calendar showPreview={false} onChange={(e) => handleEndChange(e)} />
        </DatePicker>
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
      </>
  );
};

export default SessionCommissionDetailForm;
