import React, { useEffect, useState, useRef } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../Components/Filters";
import "./AdminAssignmentsByLength.scss";
import axios from "axios";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { months, years } from "../../Utils/arrayItems";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { requestOptions } from "../../Utils";
import { DateRangePicker } from "react-date-range";
import API_CONSTANTS from "../../Redux/constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clientStatuses, lengthOnProgramOptions } from "../../Utils/selectConstants";

const AdminAssignmentsByLength = (props) => {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  let insights = useSelector((state) => state.adminReducer.adminAssignmentsByLength);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [bonusExpanded, setBonusExpanded] = useState(false);
  const [statusSelected, setStatusSelected] = useState([{ value: "All", label: "All" }]);
  const [lengthOnProgramSelected, setLengthOnProgramSelected] = useState(
    lengthOnProgramOptions[1]
  );
  const [bonusAppliedSelected, setBonusAppliedSelected] = useState({ value: "All", label: "All" });
  const [applyClicked, setApplyClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [bonusDate, setBonusDate] = useState(new Date().toLocaleDateString());
  const [showToggle, setShowToggle] = useState(false);
  const [bonuses, setBonuses] = useState();
  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Assignments by Length" } })
    );
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let length_on_program = lengthOnProgramSelected.value;
      let filtersObj = {
        is_active: status_arr,
        length_on_program: [length_on_program],
        page_name: "Assignments by Length"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Status":
        if (e.filter((item) => item.value != "All") == 0) {
          setStatusSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusSelected(e.filter((item) => item.value != "All"));
        }
        break;
      case "Length on Program":
        if (e.length == 0) {
          setLengthOnProgramSelected({ value: "All", label: "All" });
        } else {
          setLengthOnProgramSelected(e);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeSingle = (selector, e) => {
    switch (selector) {
      case "Bonus Applied":
        setBonusAppliedSelected(e);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(
        adminAction.loadAdminAssignmentsByLength({
          filters: savedUserFilters,
          bonusApplied: bonusAppliedSelected
        })
      );
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key]
        if (key == "is_active") {
          setStatusSelected(temp_array.map((data) => {
            return {
              value: data,
              label: data
            };
          }));
        } else if (key == "length_on_program") {
          setLengthOnProgramSelected({value: temp_array[0], label: temp_array[0]});
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(
        adminAction.loadAdminAssignmentsByLength({
          filters: filters,
          bonusApplied: bonusAppliedSelected
        })
      );
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(
      adminAction.loadAdminAssignmentsByLength({
        filters: filters,
        bonusApplied: bonusAppliedSelected
      })
    );
  }, [applyClicked]);

  const exportData = Array.isArray(insights) ? insights.map((v) => v.breakdown).flat() : [];

  const handleApplyBonus = (e) => {
    if (e.target.id === "apply") {
      axios(
        requestOptions({
          method: "POST",
          url: API_CONSTANTS.INSERT_ASSIGNMENT_BONUS,
          data: { clients: bonuses, payout_date: bonusDate }
        })
      )
        .then((res) => {
          if (res) {
            window.location.reload();
          } else {
            toastId.current = toast.error("There was an error inserting the bonus", {
              position: "top-center"
            });
          }
        })
        .catch((err) => {
          toastId.current = toast.error("There was an error inserting the bonus data", {
            position: "top-center"
          });
        });
    }
  };

  const handleBonusClick = (e, e2) => {
    setBonuses({
      ...bonuses,
      [e]: e2.target.checked
    });
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setBonusDate(selectedDate.toLocaleDateString());
  };

  return (
    <AccordionContainer
      values={insights}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="ASSIGNMENTS BY LENGTH"
      type="ASSIGNMENTS BY LENGTH"
      buttonTitle="CREATE AN ASSIGNMENT"
      linkName="assignment"
      admin={true}
      headers={["coach_name", "assignment_start_date", "assignment_end_date", "months_on_program"]}
      row_id={"assignment_id"}
      pathName={"assignment_detail"}
      exportButton={true}
      exportData={exportData}
      handleClick={handleClick}
      handleBonusClick={handleBonusClick}
      handleApplyBonus={handleApplyBonus}
      bonusExpanded={bonusExpanded}
      setBonusExpanded={setBonusExpanded}
      bonusDate={bonusDate}
      handleBonusDate={handleDateChange}
      showToggle={showToggle}
      setShowToggle={setShowToggle}>
      <ReactSelect
        options={clientStatuses}
        value={statusSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
        id={1}
      />
      <ReactSelect
        options={lengthOnProgramOptions}
        value={lengthOnProgramSelected}
        placeholder="Length on Program"
        isMulti={false}
        handleChange={handleChange}
        id={2}
      />
      <ReactSelect
        options={[
          { label: "All", value: "All" },
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ]}
        value={bonusAppliedSelected}
        placeholder="Bonus Applied"
        handleChange={handleChangeSingle}
        id={3}
      />
    </AccordionContainer>
  );
};

export default AdminAssignmentsByLength;
