import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { adminAction, paymentAdjustmentAction } from "../../Redux/actions";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";

const PaymentAdjustmentDetailForm = ({ paymentAdjustmentDetail, clicked, setClicked, refresh }) => {
  const [coachName, setCoachName] = useState(paymentAdjustmentDetail.coach_name);
  const [paymentAdjustmentDate, setPaymentAdjustmentDate] = useState(paymentAdjustmentDetail.adjustment_date);
  const [adjustmentPrice, setAdjustmentPrice] = useState(paymentAdjustmentDetail.adjustment_price);
  const [adjustmentNotes, setAdjustmentNotes] = useState(paymentAdjustmentDetail.adjustment_notes);
  const [coachList, setCoachList] = useState([]);
  const dispatch = useDispatch();
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);

  useEffect(() => {
    dispatch(adminAction.resetData())
    dispatch(adminAction.loadAllCoaches({ filters: {"is_active": ["Active"]} }));
  }, [dispatch]);

  useEffect(() => {
    let c_list_db = coachList_db.map((coach) => {
      return {
        value: `${coach.full_name} (${coach.coach_id})`,
        label: `${coach.full_name} (${coach.coach_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setCoachList(c_list_db);
  }, [coachList_db]);

  const onClick = (e) => {
    const req_obj = {
      coach_name: coachName.value,
      adjustment_date: paymentAdjustmentDate,
      adjustment_notes: adjustmentNotes,
      adjustment_price: adjustmentPrice
    };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_PAYMENT_ADJUSTMENT_DETAIL, data: req_obj })
    ).then((res) => {
      // console.log(res);
    });
    setClicked(!clicked);
    refresh();
  };

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Coach Name":
        setCoachName(e);
        break;
      case "Payment Adjustment Date":
        setPaymentAdjustmentDate(e.target.value);
        break;
      case "Adjustment Notes":
        setAdjustmentNotes(e.target.value);
        break;
      case "Adjustment Price":
        setAdjustmentPrice(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
      <>
      <Input
        type="date"
        label="Payment Adjustment Date"
        id={"Payment Adjustment Date"}
        disabled={false}
        value={paymentAdjustmentDate}
        onChange={(e) => handleChange("Payment Adjustment Date", e)}
      />
      <ReactSelect
        options={coachList}
        value={coachName}
        label="Coach Name"
        placeholder={"Coach Name"}
        id={"Coach Name"}
        handleChange={handleChange}
      />
      <Input
        value={adjustmentPrice}
        label="Adjustment Price"
        id={"Adjustment Price"}
        onChange={(e) => handleChange("Adjustment Price", e)}
      />
      <Input
        value={adjustmentPrice}
        label="Adjustment Notes"
        id={"Adjustment Notes"}
        onChange={(e) => handleChange("Adjustment Notes", e)}
      />
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
      </>
  );
};

export default PaymentAdjustmentDetailForm;
