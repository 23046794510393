const initialState = {
  sessionList: [],
  sessionDetail: [],
  sessionWorksheets: [],
  sessionTopics: [],
  sessionsByClient: [],
  reimbursements: [],
  reimbursementDetail: [],
  missedSessionList: [],
  missedSessionDetail: [],
  sessionCommissionList: [],
  sessionCommissionDetail: []
};

export const sessionReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_SESSION_LIST':
      return {
        ...state,
        sessionList: payload
      }
    case 'LOAD_SESSION_DETAIL':
      return {
        ...state,
        sessionDetail: payload
      }
    case 'LOAD_SESSION_WORKSHEET':
      return {
        ...state,
        sessionWorksheets: payload
      }
    case 'LOAD_SESSION_TOPIC':
      return {
        ...state,
        sessionTopics: payload
      }
    case 'LOAD_REIMBURSEMENTS':
      return {
        ...state,
        reimbursements: payload
      }
    case 'LOAD_REIMBURSEMENT_DETAIL':
      return {
        ...state,
        reimbursementDetail: payload
      }
    case 'LOAD_MISSED_SESSION_LIST':
      return {
        ...state,
        missedSessionList: payload
      }
    case 'LOAD_SESSION_COMMISSION_LIST':
      return {
        ...state,
        sessionCommissionList: payload
      }
    case 'LOAD_SESSION_COMMISSION_DETAIL':
      return {
        ...state,
        sessionCommissionDetail: payload
      }
    case 'LOAD_MISSED_SESSION_DETAIL':
      return {
        ...state,
        missedSessionDetail: payload
      }
    case 'LOAD_COACH_SESSIONS_BY_CLIENT':
      return {
        ...state,
        sessionsByClient: payload
      }
    case 'UPDATE_COACH_DETAIL':
      return {
        ...state,
        updatedCoachDetail: payload
      }
    default:
      return state
  }
};
