import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./MissedSessionDetail.scss";
import MissedSessionDetailForm from "./MissedSessionDetailForm";
import { sessionAction, adminAction } from "../../Redux/actions";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

const MissedSessionDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const missedSessionDetail = useSelector((state) => state.sessionReducer.missedSessionDetail);
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(
    sessionAction.loadMissedSessionDetailData({ filters: { missed_session_id: props.location.state.id } })
    );
  }, [detailEdited, dispatch]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  const refresh = () => {
    dispatch(
        sessionAction.loadMissedSessionDetailData({ filters: { missed_session_id: props.location.state.id } })
    );
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_MISSED_SESSION, data: {'missed_session_id': missedSessionDetail.missed_session_id} }))
      .then((res) => {
        if (res) {
          history.push("/missed_sessions")
        } else {
          toastId.current = toast.error(
            "There was an error deleting the session " + missedSessionDetail.missed_session_id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the missed session data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="session-title">MISSED SESSION</div>
      <div className="session-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Client</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <MissedSessionDetailForm
              missedSessionDetail={missedSessionDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
          ) : (
            <>
              <div className="mt-3">{missedSessionDetail.client_name}</div>
              <div className="labels mt-3">Date</div>
              <div className="mt-2">{missedSessionDetail.session_date}</div>
              <div className="labels mt-3">Client Type</div>
              <div className="mt-3">{missedSessionDetail.client_type}</div>
              <div className="labels mt-3">Notes/Reason for missing session:</div>
              <div className="mt-3">{missedSessionDetail.notes}</div>
              <div className="labels mt-3">Logged Date</div>
              <div className="mt-3">{missedSessionDetail.submitted_timestamp}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MissedSessionDetail;
