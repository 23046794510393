import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "react-bootstrap";
import DatePicker from "../../Components/Forms/DatePicker";
import { Calendar } from "react-date-range";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction } from "../../Redux/actions";
import { sessionAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";

const SessionDetailForm = ({
  sessionDetail,
  clicked,
  setClicked,
  refresh,
  handleDetailEditedChange
}) => {
  const dispatch = useDispatch();
  const clientList_db = useSelector((state) => state.clientReducer.clientList);
  const [showToggle, setShowToggle] = useState(false);
  const worksheetList_db = useSelector((state) => state.sessionReducer.sessionWorksheets);
  const topicList_db = useSelector((state) => state.sessionReducer.sessionTopics);
  const sessionId = useState(sessionDetail.session_id);
  const [clientId, setClientId] = useState(sessionDetail.client_id);
  const [clientName, setClientName] = useState({
    value: sessionDetail.client_name + " (" + sessionDetail.client_id + ")",
    label: sessionDetail.client_name + " (" + sessionDetail.client_id + ")"
  });
  const [sessionDate, setSessionDate] = useState(
    new Date(sessionDetail.session_date).toLocaleDateString('en-US', {timeZone: 'UTC'})
  );
  const [clientType, setClientType] = useState({
    value: sessionDetail.client_type,
    label: sessionDetail.client_type
  });
  const [sessionType, setSessionType] = useState({
    value: sessionDetail.session_type,
    label: sessionDetail.session_type
  });
  const [topic, setTopic] = useState(
    sessionDetail.topic.map((topic) => {
      return { value: topic, label: topic };
    })
  );
  const toastId = useRef(null);
  const [workshopNumber, setWorkshopNumber] = useState({
    value: sessionDetail.workshop_number,
    label: sessionDetail.workshop_number
  });
  const [worksheet, setWorksheet] = useState(
    sessionDetail.worksheet.map((worksheet) => {
      return { value: worksheet, label: worksheet };
    })
  );
  const [notes, setNotes] = useState(sessionDetail.notes);
  const [clientList, setClientList] = useState([]);
  const [worksheetList, setWorksheetList] = useState([]);
  const [topicList, setTopicList] = useState([]);

  useEffect(() => {
    dispatch(clientAction.loadClientListData({ filters: { is_active: ["Active"] } }));
    dispatch(sessionAction.loadSessionWorksheetData({ filters: {} }));
    dispatch(sessionAction.loadSessionTopicData({ filters: {} }));
  }, [dispatch]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  useEffect(() => {
    let ws_list_db = worksheetList_db.map((worksheet) => {
      return {
        value: `${worksheet.worksheet}`,
        label: `${worksheet.worksheet}`
      };
    });
    ws_list_db.push({ value: "", label: "" });
    setWorksheetList(ws_list_db);
  }, [worksheetList_db]);

  useEffect(() => {
    let topic_list_db = topicList_db.map((topic) => {
      return {
        value: `${topic.topic}`,
        label: `${topic.topic}`
      };
    });
    topic_list_db.push({ value: "", label: "" });
    setTopicList(topic_list_db);
  }, [topicList_db]);

  const onSelectChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e);
        setClientId(e.value);
        break;
      case "Client Type":
        setClientType(e);
        break;
      case "Date":
        setSessionDate(e.target.value);
        break;
      case "Session Type":
        setSessionType(e);
        break;
      case "Topic":
        setTopic(e);
        break;
      case "Worksheet":
        setWorksheet(e);
        break;
      case "Workshop #":
        setWorkshopNumber(e);
        break;
      case "Notes":
        setNotes(e.target.value);
        break;
      default:
        break;
    }
  };

  // if the topic 'Team Workshop' is selected, a workshop number has to be present
  function checkWorkshopNumber(req_obj) {
    if (
      topic.filter((topic) => topic.value === "Team Workshop").length > 0 &&
      workshopNumber === ""
    ) {
      return false;
    }
    return true;
  }

  const handleClick = (e) => {
    const req_obj = {
      client_id: clientId,
      client_name: clientName.value,
      session_date: sessionDate,
      client_type: clientType.value,
      session_type: sessionType.value,
      topic: topic.map((data) => data.value),
      worksheet: worksheet.map((data) => data.value),
      workshop_number: workshopNumber.value,
      notes: notes,
      session_id: sessionId
    };
    if (
      clientName.value !== "" &&
      sessionDate !== "" &&
      clientType.value !== "" &&
      sessionType.value !== "" &&
      topic.length > 0 &&
      worksheet.length > 0 &&
      checkWorkshopNumber(req_obj)
    ) {
      insert_data(req_obj);
    } else {
      toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
    }
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setSessionDate(selectedDate.toLocaleDateString('en-US', {timeZone: 'UTC'}));
  };

  const reset_data = () => {
    setClientName("");
    setSessionDate("");
    setClientType("");
    setSessionType("");
    setTopic([]);
    setWorksheet([]);
    setWorkshopNumber("");
    setNotes("");
  };

  const insert_data = (req_obj) => {
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_SESSION_DETAIL, data: req_obj })
    )
      .then((res) => {
        if (res) {
          toastId.current = toast.success(
            "You’ve successfully updated the session with " + req_obj["client_name"],
            { position: "top-center" }
          );
          reset_data();
          handleDetailEditedChange();
        } else {
          toastId.current = toast.error(
            "There was an error updating the session with " + req_obj["client_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error updating the session data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="mt-2">
        <div className="labels mt-3">Client</div>
        <ReactSelect
          options={clientList}
          handleChange={onSelectChange}
          value={clientName}
          placeholder="Client Name"
        />
        <div className="labels mt-3">Date</div>
        <DatePicker
          date={sessionDate}
          showToggle={showToggle}
          setShowToggle={setShowToggle}
          label={"Session Date"}>
          <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
        </DatePicker>
        <div className="labels mt-3">Client Type</div>
        <ReactSelect
          options={clientTypeList}
          value={clientType}
          placeholder="Client Type"
          handleChange={onSelectChange}
        />
        <div className="labels mt-3">Session Type</div>
        <ReactSelect
          options={sessionTypeList}
          placeholder="Session Type"
          handleChange={onSelectChange}
          value={sessionType}
        />
        <div className="labels mt-3">Topic</div>
        <ReactSelect
          id={"topic"}
          options={topicList}
          placeholder="Topic"
          handleChange={onSelectChange}
          isMulti
          value={topic}
        />
        {topic.filter((topic) => topic.value === "Team Workshop").length > 0 && (
          <ReactSelect
            options={workshopNumberList}
            value={workshopNumber}
            placeholder="Workshop #"
            handleChange={onSelectChange}
          />
        )}
        <div className="labels mt-3">Worksheet</div>
        <ReactSelect
          id={"worksheet"}
          options={worksheetList}
          placeholder="Worksheet"
          control="typeSelect"
          handleChange={onSelectChange}
          value={worksheet}
          isMulti
        />
        <div className="labels mt-3">Notes:</div>
        <TextArea
          id={"notes"}
          height={'300px'}
          onChange={(e) => onSelectChange("Notes", e)}
          value={notes}
        />
        <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
          SAVE
        </Button>
      </div>
    </>
  );
};

export default SessionDetailForm;
