import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let saleList, saleDetail, programList, programDetail, salesHours, salesHoursDetail, myExpiringSubscriptions;

let { resetLoader, setLoader } = loaderAction;

const loadSaleListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionList'))
  if (typeof saleList != typeof undefined) {
    saleList.cancel('The load operation has been canceled due to a new request')
  }
  saleList = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SALE_LIST, data: filters, token: saleList.token})).then((res) => {
    dispatch(populateSaleListData(res['data']['result']));
    dispatch(resetLoader('loadSaleList'));
  })
  .catch(err => console.log(err))
};

const loadSaleDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSaleDetail'))
  if (typeof saleDetail != typeof undefined) {
    saleDetail.cancel('The load operation has been canceled due to a new request')
  }
  saleDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SALE_DETAIL, data: filters, token: saleDetail.token})).then((res) => {
    dispatch(populateSaleDetailData(res['data']['result']));
    dispatch(resetLoader('loadSaleDetail'));
  })
  .catch(err => console.log(err))
};

const loadProgramData = (filters) => (dispatch) => {
  dispatch(setLoader('loadPrograms'))
  if (typeof programList != typeof undefined) {
    programList.cancel('The load operation has been canceled due to a new request')
  }
  programList = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.PROGRAMS, data: filters, token: programList.token})).then((res) => {
    dispatch(populateProgramData(res['data']));
    dispatch(resetLoader('loadPrograms'));
  })
  .catch(err => console.log(err))
};

const loadProgramDetail = (filters) => (dispatch) => {
  dispatch(setLoader('loadProgramDetail'))
  if (typeof programDetail != typeof undefined) {
    programDetail.cancel('The load operation has been canceled due to a new request')
  }
  programDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.PROGRAM_DETAIL, data: filters, token: programDetail.token})).then((res) => {
    dispatch(populateProgramDetail(res['data']));
    dispatch(resetLoader('loadProgramDetail'));
  })
  .catch(err => console.log(err))
};

const loadSalesHoursData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSalesHours'))
  if (typeof salesHours != typeof undefined) {
    salesHours.cancel('The load operation has been canceled due to a new request')
  }
  salesHours = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SALES_HOURS_LIST, data: filters, token: salesHours.token})).then((res) => {
    dispatch(populateSalesHoursData(res['data']['result']));
    dispatch(resetLoader('loadSalesHours'));
  })
  .catch(err => console.log(err))
};

const loadSalesHoursDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSalesHoursDetail'))
  if (typeof salesHoursDetail != typeof undefined) {
    salesHoursDetail.cancel('The load operation has been canceled due to a new request')
  }
  salesHoursDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SALES_HOURS_DETAIL, data: filters, token: salesHoursDetail.token})).then((res) => {
    dispatch(populateSalesHoursDetailData(res['data']['result']));
    dispatch(resetLoader('loadSalesHoursDetail'));
  })
  .catch(err => console.log(err))
};

const loadMyExpiringSubscriptions = (filters) => (dispatch) => {
  dispatch(setLoader("loadMyExpiringSubscriptions"));
  if (typeof myExpiringSubscriptions != typeof undefined) {
    myExpiringSubscriptions.cancel("The load operation has been canceled due to a new request");
  }
  myExpiringSubscriptions = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.MY_EXPIRING_SUBSCRIPTIONS,
      data: filters,
      token: myExpiringSubscriptions.token
    })
  )
    .then((res) => {
      dispatch(populateMyExpiringSubscriptions(res["data"]["result"]));
      dispatch(resetLoader("loadMyExpiringSubscriptions"));
    })
    .catch((err) => console.log(err));
};

// const updateSessionDetailData = data => dispatch => {
//   axios(requestOptions({method:'POST', url:apiConstants.UPDATE_SESSION_DETAIL,data}))
//   .then(res => {
//     dispatch(populateUpdatedCoachData(data['request']))
//   })
//   .catch(err => {
//     console.log(err)
//   })
// }

// const populateUpdatedCoachData = data => ({
//   type: 'UPDATE_COACH_DETAIL',
//   payload: data
// })

const populateMyExpiringSubscriptions = (data) => ({
  type: 'LOAD_MY_EXPIRING_SUBSCRIPTIONS',
  payload: data
});

const populateSalesHoursData = (data) => ({
  type: 'LOAD_SALES_HOURS_LIST',
  payload: data
});

const populateSalesHoursDetailData = (data) => ({
  type: 'LOAD_SALES_HOURS_DETAIL',
  payload: data
});

const populateSaleListData = (data) => ({
  type: 'LOAD_SALE_LIST',
  payload: data
});

const populateSaleDetailData = (data) => ({
  type: 'LOAD_SALE_DETAIL',
  payload: data
});

const populateProgramData = (data) => ({
  type: 'LOAD_PROGRAMS',
  payload: data
});

const populateProgramDetail = (data) => ({
  type: 'LOAD_PROGRAM_DETAIL',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const saleAction = {
  loadSaleListData,
  loadSaleDetailData,
  loadProgramData,
  loadSalesHoursData,
  loadSalesHoursDetailData,
  loadMyExpiringSubscriptions,
  loadProgramDetail,
  resetData
}
