export const expenseData = [
  {
    coachName: "Jon Jacobs",
    id: 1,
    breakdown: {
      totalSales: 2000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 400
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 2,
    breakdown: {
      totalSales: 3000,
      athlete: 300,
      sales: 100,
      video: 400,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 30
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 3,
    breakdown: {
      totalSales: 4000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 300
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 4,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 5,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 6,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 7,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 8,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 9,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 10,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 11,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 12,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 13,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 14,
    breakdown: {
      totalSales: 5000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 200
    }
  },
  {
    coachName: "Jon Jacobs",
    id: 15,
    breakdown: {
      totalSales: 6000,
      athlete: 100,
      sales: 100,
      video: 100,
      inPerson: 100,
      clubRuns: 100,
      corporateSessions: 400,
      blogs: 100
    }
  }
];
