const config = {
  oidc: {
    clientId: "0oa3m9adwe5zRtlrp5d7",
    issuer: "https://dev-75928766.okta.com/oauth2/default",
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false
  }
}

export default config;
