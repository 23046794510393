import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DeleteButton = ({ deleteClicked, handleCancel, handleDelete }) => {

  return (
    <>
      <button className="delete-button" onClick={handleCancel}>
        <Trash size={15} />
      </button>
      <Modal show={deleteClicked} onHide={handleCancel}>
        <Modal.Body>Are you sure you want to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCancel}>
            CANCEL
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteButton;
