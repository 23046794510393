import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Logo from "../../assets/wm-logo.jpg";
import "./ClubRunDetail.scss";
import routingConstants from "../../Navigation/constants";
import { clubRunAction, adminAction } from "../../Redux/actions";
import ClubRunDetailForm from "./ClubRunDetailForm";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

library.add(faRunning);
library.add(faBed);

const ClubRunDetail = (props) => {
  const dispatch = useDispatch();
  const clubRunDetail = useSelector((state) => state.clubRunReducer.clubRunDetail);
  const adminClubRunDetail = useSelector((state) => state.adminReducer.adminClubRunDetail);
  const [sport, setSport] = useState();
  const [date, setDate] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [coachName, setCoachName] = useState();
  const [coachPhone, setCoachPhone] = useState();
  const [coachEmail, setCoachEmail] = useState();
  const [eventType, setEventType] = useState();
  const [signups, setSignups] = useState();
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [clubRunDetailFinal, setClubRunDetailFinal] = useState({});
  const [detailEdited, setDetailEdited] = useState(false);

  const history = useHistory();
  const toastId = useRef(null);

  const refresh = () => {
    if (props.location.state && props.location.state.admin === true) {
      dispatch(
        adminAction.loadAdminClubRunDetail({ filters: { club_run_id: props.location.state.id } })
      );
    } else {
      dispatch(
        clubRunAction.loadClubRunDetailData({ filters: { club_run_id: props.location.state.id } })
      );
    }
  };

  useEffect(() => {
    //dispatch(clientDashboardAction.resetData())
    if (props.location.state && props.location.state.admin === true) {
      dispatch(
        adminAction.loadAdminClubRunDetail({ filters: { club_run_id: props.location.state.id } })
      );
    } else {
      dispatch(
        clubRunAction.loadClubRunDetailData({ filters: { club_run_id: props.location.state.id } })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (props.location.state && props.location.state.admin == true) {
      setClubRunDetailFinal(adminClubRunDetail);
    } else {
      setClubRunDetailFinal(clubRunDetail);
    }
  }, [clubRunDetail, adminClubRunDetail]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_CLUB_RUN, data: {'club_run_id': props.location.state.id} }))
      .then((res) => {
        if (res) {
          if (props.location.state && props.location.state.admin == true) {
            history.push("/admin_club_runs_by_coach")
          }
          else {
            history.push("/club_runs")
          }
        } else {
          toastId.current = toast.error(
            "There was an error deleting the event " + clubRunDetail.club_run_id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the event data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <div className="club-run-title">EVENT</div>
      <div className="club-run-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Event Name</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <ClubRunDetailForm
              clubRunDetail={clubRunDetailFinal}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
            />
          ) : (
            <>
              <div className="mt-3">{clubRunDetailFinal.club_name}</div>
              <div className="labels mt-3">Event Date</div>
              <div className="mt-2">{clubRunDetailFinal.club_run_date}</div>
              <div className="labels mt-3">Event Type</div>
              <div className="mt-2">{clubRunDetailFinal.event_type}</div>
              <div className="labels mt-3">Event Sport</div>
              <div className="mt-2">{clubRunDetailFinal.sport}</div>
              {clubRunDetailFinal.event_type === 'Tournament' &&
                <>
                  <div className="labels mt-3">Tournament</div>
                  <div className="mt-2">
                    {clubRunDetailFinal.tournament}
                  </div>
                  <div className="labels mt-3">Hours worked</div>
                  <div className="mt-2">
                    {clubRunDetailFinal.hours_worked}
                  </div>
                </>
              }
              {
                clubRunDetailFinal.event_type !== 'Tournament' &&
                <>
                  <div className="labels mt-3">Topic</div>
                  <div className="mt-2">
                    {clubRunDetailFinal.topic}
                  </div>
                </>
              }
              <div className="labels mt-3">Facilitated By</div>
              <div className="mt-2">{clubRunDetailFinal.coach_name}</div>
              <div className="labels mt-3">Number of Sign-ups</div>
              <div className="mt-2">
                {clubRunDetailFinal.sign_ups ? clubRunDetailFinal.sign_ups : "Not Available"}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ClubRunDetail;
