export const keys = {
  sales_rep: "ASC",
  origin: "",
  parent_name: "",
  revenue: "",
  profit_before_sessions: "",
  team_commission: "",
  ind_commission: "",
  program: "",
  client_name: "",
  month: "",
  sales_rep_sum: "",
  sum_of_revenue: "",
  sum_of_ind_commission: "",
  sum_of_team_commission: "",
  sum_of_profit_before_sessions: ""
};
