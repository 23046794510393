export const styles = {
  control: (base) => ({
    ...base,
    marginTop: "15px",
    backgroundColor: "#363636",
    border: "none",
    color: "white"
  }),
  valueContainer: (provided, state) => ({
    ...provided
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: 0,
    fontSize: ".8rem",
    color: "white",
    opacity: ".65"
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
    marginTop: "18px"
  }),
  multiValue: (base) => ({
    ...base,
    color: "white",
    backgroundColor: "#1d1d1d",
    marginTop: "15px"
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "white",
    fontSize: ".7rem"
  }),
  input: (base) => ({
    ...base,
    color: "white",
    marginTop: "15px"
  }),
  option: (base) => ({
    ...base,
    color: "black"
  })
};

export const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "lightgrey",
    primary25: "lightgrey"
  }
});
